import React from 'react'
import PropTypes from 'prop-types'

const CompanyHeader = ({ companyName }) => (
  <div className="page-header pull-left">
    <h3 className="secret_text">{companyName}</h3>
  </div>
)

CompanyHeader.propTypes = {
  companyName: PropTypes.string
}

CompanyHeader.defaultProps = {
  companyName: ''
}

export default CompanyHeader
