import React from 'react'
import PropTypes from 'prop-types'

const compareCompanyName = (a, b) => {
  if (a.companyName < b.companyName) {
    return -1
  }
  if (a.companyName > b.companyName) {
    return 1
  }
  // a must be equal to b
  return 0
}

const listStaffs = (staffs, onClick) => (staffs.sort(compareCompanyName).map(staff => (
  <a
    type="button"
    href="/"
    onClick={event => onClick(event, staff)}
    className="list-group-item"
    key={staff.externalStaffReference}
    id={staff.externalStaffReference}
  ><b>{staff.companyName}</b> <br /> <small>{staff.userName}</small>
  </a>
)))

const StaffList = ({ staffs, onSelect, onCancel }) => (
  <div>
    <h4 className="text-center">Select account</h4>
    <div className="list-group" style={{ overflow: 'auto', maxHeight: '300px' }}>{listStaffs(staffs, onSelect)}</div>
    <button className="btn btn-default btn-sm" onClick={onCancel}>Cancel</button>
  </div>
)

StaffList.propTypes = {
  staffs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

export default StaffList
