// TODO: Remove this eslint-disable
/* eslint-disable no-console */
import 'url-search-params-polyfill'
import keycloak from '../keycloak'
import tokenValidator from '../jwt/tokenValidator'
import { checkStatus, parseJSON, uuidv4 } from '../util/apiUtil'

const ACCESS_TOKEN_KEY = 'access_token'
const REFRESH_TOKEN_KEY = 'refresh_token'
const TOKEN_TYPE = 'token_type'

const FMP_AUTH_HOST = ENV_VAR_FMP_AUTH_HOST

const AUTH_HOST = ENV_VAR_AUTH_HOST
const BFF_HOST = ENV_VAR_BFF_HOST

const CLIENT_ID = 'CWPApp'
const KEYCLOAK_EVENTS = {
  onReady: 'onReady',
  onAuthSuccess: 'onAuthSuccess',
  onAuthError: 'onAuthError',
  onAuthRefreshSuccess: 'onAuthRefreshSuccess',
  onAuthRefreshError: 'onAuthRefreshError',
  onAuthLogout: 'onAuthLogout',
  onTokenExpired: 'onTokenExpired'
}

const unauthorizedError = () => new Error('Unauthorized')

const storeTokens = (response) => {
  sessionStorage.setItem(ACCESS_TOKEN_KEY, response.access_token)
  window.dispatchEvent(new Event('storage'))
  if (response.refresh_token) {
    sessionStorage.setItem(REFRESH_TOKEN_KEY, response.refresh_token)
  } else {
    // Remove refresh token if not used
    localStorage.removeItem(REFRESH_TOKEN_KEY)
  }

  return response
}

const getFmpToken = (kcToken) => {
  console.log('[auth] getting FMP token')
  window.dispatchEvent(new CustomEvent('kcTokenSuccessfullyFetched', { detail: kcToken }))
  const getFMPTokenPromise = fetch(`${FMP_AUTH_HOST}/token`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${kcToken}`,
      'X-Correlation-ID': uuidv4(),
      'X-Client': ENV_VAR_X_CLIENT
    }
  }).then(checkStatus)
    .then(parseJSON)
    .then(storeTokens)

  return getFMPTokenPromise
}

let refreshPromise = null
let isRefreshing = false
let updateTokenInterval = null

const setNotRefreshing = (response) => {
  isRefreshing = false
  return response
}
const setNotRefreshingAndRethrow = (error) => {
  isRefreshing = false
  return Promise.reject(error)
}

const refreshTokens = () => {
  console.log('refreshing tokens!')
  keycloak
    .updateToken(-1) // -1: to force refresh the token
    .then((refr) => {
      if (refr) {
        getFmpToken(keycloak.token)
      }
    })
    .catch((err) => {
      console.log('Error refreshing keycloak token: ', err)
    })
}

const refresh = (refreshToken) => {
  if (ENV_VAR_MY_SCANIA) {
    return refreshTokens()
  }

  if (isRefreshing) {
    return refreshPromise
  }

  const form = new URLSearchParams()
  form.append('client_id', CLIENT_ID)
  form.append('grant_type', 'refresh_token')
  form.append('refresh_token', refreshToken)

  refreshPromise = fetch(`${AUTH_HOST}/auth/token`, {
    method: 'POST',
    body: form.toString(),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      'X-Correlation-ID': uuidv4(),
      'X-Client': ENV_VAR_X_CLIENT
    }
  })
    .then(checkStatus)
    .then(parseJSON)
    .then(storeTokens)
    .then(setNotRefreshing)
    .catch(setNotRefreshingAndRethrow)

  isRefreshing = true

  return refreshPromise
}

const scheduleTokenRefresh = () => {
  console.log('[scheduleTokenRefresh] start')
  if (updateTokenInterval) {
    console.log('[scheduleTokenRefresh] clearing the interval')
    clearInterval(updateTokenInterval)
  }
  updateTokenInterval = setInterval(() => refreshTokens(), 240000) // refresh token every 4 minutes
}

export default {

  login: (creds) => {
    const form = new URLSearchParams()
    form.append('client_id', CLIENT_ID)
    form.append('grant_type', 'password')
    form.append('sso', true)
    form.append('username', creds.username)
    form.append('password', creds.password)
    if (creds.externalStaffReference) {
      form.append('externalStaffReference', creds.externalStaffReference)
    }

    return fetch(`${AUTH_HOST}/auth/token`, {
      method: 'POST',
      body: form.toString(),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        'X-Correlation-ID': uuidv4(),
        'X-Client': ENV_VAR_X_CLIENT
      }
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(storeTokens)
  },

  getFmpToken: kcToken => getFmpToken(kcToken),

  logout: () => {
    sessionStorage.clear()
    if (ENV_VAR_MY_SCANIA) {
      localStorage.removeItem('x-switch')
      keycloak.logout()
    }
  },

  getValidAccessToken: (forceRefresh = false) => {
    if (!forceRefresh) {
      const accessToken = sessionStorage.getItem(ACCESS_TOKEN_KEY) || null

      if (tokenValidator.isValid(accessToken)) {
        return Promise.resolve(accessToken)
      }
    }

    const refreshToken = sessionStorage.getItem(REFRESH_TOKEN_KEY) || null

    if (refreshToken === null) {
      return Promise.reject(unauthorizedError())
    }

    return refresh(refreshToken)
      .then(response => response.access_token)
  },

  getAccessToken: () => sessionStorage.getItem(ACCESS_TOKEN_KEY) || null,

  exchangeMagicToken: (magicToken) => {
    const form = new URLSearchParams()
    form.append('token', magicToken)

    return fetch(`${BFF_HOST}/api/v1/token/exchange`, {
      method: 'POST',
      body: form.toString(),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        'X-Correlation-ID': uuidv4(),
        'X-Client': ENV_VAR_X_CLIENT
      }
    })
      .then(checkStatus)
      .then(parseJSON)
  },

  cacheMagicToken: (token) => {
    sessionStorage.setItem(ACCESS_TOKEN_KEY, token)
    sessionStorage.setItem(TOKEN_TYPE, 'magic')

    sessionStorage.removeItem(REFRESH_TOKEN_KEY)
  },

  storeTokens: response => storeTokens(response),

  handleKeycloakEvent: (event, error) => {
    console.log('[handleKeycloakEvent] event', event)
    console.log('[handleKeycloakEvent] error keycloakEventHandler', error)

    if (event === KEYCLOAK_EVENTS.onReady) {
      console.log('[handleKeycloakEvent] Ready')
    }

    if (event === KEYCLOAK_EVENTS.onAuthSuccess) {
      scheduleTokenRefresh()
    }

    if (event === KEYCLOAK_EVENTS.onAuthLogout ||
      event === KEYCLOAK_EVENTS.onAuthError ||
      event === KEYCLOAK_EVENTS.onAuthRefreshError) {
      sessionStorage.clear()
      localStorage.removeItem('x-switch')
    }

    if (event === KEYCLOAK_EVENTS.onTokenExpired) {
      console.log('[handleKeycloakEvent] token expired!')
      refreshTokens()
    }
  }
}
