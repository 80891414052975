import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons/faPlayCircle'
import PropTypes from 'prop-types'
import i18n from '../util/localization'

const TourLink = props => (
  <button type="button" id="tourRestart" className="btn btn-link btn-info" onClick={props.clickFunc}>
    <FontAwesomeIcon icon={faPlayCircle} /> {i18n.t('Snabbguide')}
  </button>
)

TourLink.propTypes = {
  clickFunc: PropTypes.func.isRequired
}

export default TourLink
