export const parseJSON = response => response.json()

export const checkStatus = (response) => {
  if (response.status > 200) {
    try {
      return parseJSON(response)
        .catch(() => response)
        .then((respFormatted) => {
          const error = new Error(response.statusText)
          error.response = respFormatted
          error.status = response.status
          return Promise.reject(error)
        })
    } catch (e) {
      const error = new Error(response.statusText)
      error.response = response
      error.status = response.status
      return Promise.reject(error)
    }
  }
  return Promise.resolve(response)
}

export const uuidv4 = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
  const r = Math.random() * 16 | 0; const
    v = c === 'x' ? r : (r & 0x3 | 0x8)
  return v.toString(16)
})
