import * as types from '../actionTypes'

const initialState = {
  self: {
    isMonitoringReportRecipient: undefined,
    isServicePlanningReportRecipient: undefined,
    loading: false,
    failedLoading: false
  },
  company: {
    unsubscribe: {
      saving: false,
      failedSaving: false
    },
    active: {
      staffs: undefined,
      loading: false,
      failed: false
    }
  }

}

const staff = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ALL_ACTIVE_SUBSCRIBERS_REQUEST: {
      return Object.assign({}, state, {
        company: Object.assign({}, state.company, {
          active: Object.assign({}, state.company.active, {
            loading: true
          })
        })
      })
    }
    case types.FETCH_ALL_ACTIVE_SUBSCRIBERS_FAILURE: {
      return Object.assign({}, state, {
        company: Object.assign({}, state.company, {
          active: Object.assign({}, state.company.active, {
            loading: false,
            failed: true
          })
        })
      })
    }
    case types.FETCH_ALL_ACTIVE_SUBSCRIBERS_SUCCESS: {
      return Object.assign({}, state, {
        company: Object.assign({}, state.company, {
          active: Object.assign({}, state.company.active, {
            staffs: action.subscribers,
            loading: false
          })
        })
      })
    }
    case types.REMOVE_SUBSCRIPTION_REQUEST: {
      return Object.assign({}, state, {
        company: Object.assign({}, state.company, {
          unsubscribe: Object.assign({}, state.company.new, {
            saving: true,
            failedSaving: false
          })
        })
      })
    }
    case types.REMOVE_SUBSCRIPTION_SUCCESS: {
      return Object.assign({}, state, {
        company: Object.assign({}, state.company, {
          unsubscribe: Object.assign({}, state.company.new, {
            saving: false,
            failedSaving: false
          }),
          active: initialState.company.active
        })
      })
    }
    case types.REMOVE_SUBSCRIPTION_FAILURE: {
      return Object.assign({}, state, {
        company: Object.assign({}, state.company, {
          unsubscribe: Object.assign({}, state.company.new, {
            saving: false,
            failedSaving: true
          })
        })
      })
    }
    case types.LOGOUT_SUCCESS:
      return initialState
    case types.LOGIN_SUCCESS:
      return initialState
    default:
      return state
  }
}

export default staff
