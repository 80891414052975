import reportDataApi from '../../api/reportData'
import * as types from '../actionTypes'
import { transformDataForEnvironmentalReport } from '../../util/environmentalReportDataTransformer'

export const sortVehicleDataTable = (orderColumnIndex: number, order: 'asc' | 'desc') => ({
  type: types.SORT_VEHICLE_DATA_TABLE,
  orderColumnIndex,
  order
})

function fetchVehicleDataRequest (periodType, periodStart, periodEnd, propulsionType) {
  return {
    type: types.FETCH_VEHICLE_DATA_REQUEST,
    periodType,
    periodStart,
    periodEnd,
    propulsionType
  }
}

function fetchVehicleDataSuccess (vehicleDataResult, periodType, periodStart, periodEnd, propulsionType) {
  return {
    type: types.FETCH_VEHICLE_DATA_SUCCESS,
    result: vehicleDataResult,
    periodType,
    periodStart,
    periodEnd,
    propulsionType
  }
}

function fetchVehicleDataFailure (error, periodType, periodStart, periodEnd, propulsionType) {
  return {
    type: types.FETCH_VEHICLE_DATA_FAILURE,
    error,
    periodType,
    periodStart,
    periodEnd,
    propulsionType
  }
}

function fetchReports () {
  return {
    type: types.FETCH_REPORTS_REQUEST
  }
}

function fetchReportsSuccess (reportList) {
  return {
    type: types.FETCH_REPORTS_REQUEST_SUCCESS,
    result: reportList
  }
}

function fetchReportsFailure (error) {
  return {
    type: types.FETCH_REPORTS_REQUEST_FAILURE,
    error: error.message
  }
}

function fetchVehicleDataEmpty (error, periodType, periodStart, periodEnd, propulsionType) {
  return {
    type: types.FETCH_VEHICLE_DATA_EMPTY,
    error,
    periodType,
    periodStart,
    periodEnd,
    propulsionType
  }
}

const isEmtpyReport = error => error.status === 404

export const listReports = startOfWeek => (dispatch) => {
  dispatch(fetchReports())
  return reportDataApi
    .list(startOfWeek)
    .then(
      reportListResult => dispatch(fetchReportsSuccess(reportListResult)),
      error => dispatch(fetchReportsFailure(error.message))
    )
}

export const getReport = (periodType, periodStart, periodEnd,
  propulsionType, units, gasUnits, vehicleIdentifier, yntOffersToggle, templateOptions) => (dispatch) => {
  dispatch(fetchVehicleDataRequest(periodType, periodStart, periodEnd, propulsionType))
  return reportDataApi
    .get(periodType, periodStart, periodEnd, propulsionType, units, gasUnits, yntOffersToggle, templateOptions)
    .then((vehicleDataResult) => {
      const applicationReport = transformDataForEnvironmentalReport(vehicleDataResult, propulsionType, vehicleIdentifier)
      dispatch(fetchVehicleDataSuccess(applicationReport, periodType, periodStart, periodEnd, propulsionType))
    })
    .catch((error) => {
      if (isEmtpyReport(error)) {
        dispatch(fetchVehicleDataEmpty(error, periodType, periodStart, periodEnd, propulsionType))
      }
      dispatch(fetchVehicleDataFailure((error.message || 'Failed to load vehicle data'), periodType, periodStart, periodEnd, propulsionType))
    })
}
