/* eslint-disable max-len */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'
import selector from '../state/tour/selectors'
import Tour from '../components/Tour'
import tourActions from '../state/tour/actions'
import i18n from '../util/localization'

const buildSteps = (props) => {
  const localDic = [
    { lang: 'sv-SE', text: 'När du klickar på skiftnyckeln så får du information om utrustningens planerade underhåll. För att få mer information, klicka på länken Serviceplanering.' },
    // { lang: 'nb-NO', text: 'Ved å klikke på ikonet, vil du få informasjon om servicer. For å se mer informasjon, klikk på linken Service Planlegging.' },
    { lang: 'da-DK', text: 'Når du klikker på skruenøglen, får du information om udstyrets planlagte services. For at se mere information – klik på linket til Serviceplanlægning' },
    { lang: 'fi-FI', text: 'Klikkaamalla jakoavain-symbolia, näet tiedot ajoneuvon suunnitelluista huolloista. Tarkastellaksesi tarkempia tietoja, siirry linkin kautta Huoltosuunnitteluun' }
  ]
  const SPText = localDic.filter(a => a.lang === i18n.language)
  const stepsRepo = {
    reportPage: [
      {
        stepKey: 'exportDivMenuKey',
        target: '#exportDivMenu',
        title: i18n.t('AndraRapportformat'),
        content: i18n.t('VisaSamtligaParametrarIPDFEllerExcel_'),
        newFeature: false
      },
      {
        stepKey: 'reportPickerButtonKey',
        target: '#reportPickerButton',
        title: i18n.t('VäljRapport'),
        content: i18n.t('KlickaHärFörAttSeAllaDinaRapporterFörOlikaBränsletyper_'),
        newFeature: false
      },
      {
        stepKey: 'settingsKey',
        target: '#top-menu-list-item-profile',
        title: i18n.t('MinProfil'),
        content: i18n.t('ÄndraDinaInställningSomT_ExEnheter_Km_MilesEtc___'),
        newFeature: false
      },
      {
        stepKey: 'hamburgerKey',
        target: '#hamburger',
        title: i18n.t('MinProfil'),
        content: i18n.t('ÄndraDinaInställningSomT_ExEnheter_Km_MilesEtc___'),
        newFeature: false
      },
      {
        stepKey: 'servicePlaningKey',
        target: 'img[id=servicePlaningIcon]:first-child',
        title: i18n.t('Serviceplanering'),
        content: (SPText && SPText[0]) ? SPText[0].text : '-',
        newFeature: true
      },
      {
        stepKey: 'arrowKey',
        target: 'span[id=arrowSpan]:first-child',
        title: i18n.t('Trender'),
        content:
        i18n.t('TrendpilarVisarOmVärdetÖkatEllerMinskatSedanFöregåendePeriod_TrendpilarnasFärg'),
        newFeature: false
      },
      {
        stepKey: 'tourRestartKey',
        target: '#tourRestart',
        title: i18n.t('Snabbguide'),
        content: i18n.t('KlickaHärFörAttStarta_'),
        newFeature: false
      }
    ]
  }

  const readVisitedSteps = (name) => {
    const keyName = `tour-${name}-visited-steps`
    if (window.localStorage.getItem(keyName)) {
      return JSON.parse(window.localStorage.getItem(keyName))
    }
    return []
  }

  const visitedSteps = props.isTourRestarted ? [] : readVisitedSteps(props.name)
  let activeSteps = props.isTourRestarted ? stepsRepo[props.name] : stepsRepo[props.name].filter(a => a.newFeature)

  if (props.servicePlanningFeature === 0) {
    activeSteps = activeSteps.filter(a => a.stepKey !== 'servicePlaningKey')
  }
  const keyToFilter = isMobile ? 'settingsKey' : 'hamburgerKey'
  const steps = activeSteps
    .filter(a => a.stepKey !== keyToFilter)
    .filter(a => visitedSteps.indexOf(a.stepKey) < 0)

  return { steps, stepsRepo }
}

const TourContainer = ({ tourLoaded, ...props }) => {
  const { steps, stepsRepo } = buildSteps(props)

  useEffect(() => {
    if (i18n.t('AndraRapportformat') !== 'AndraRapportformat') {
      tourLoaded()
    }
  }, [tourLoaded])

  const writeVisitedSteps = (visitedSteps) => {
    const keyName = `tour-${props.name}-visited-steps`
    window.localStorage.setItem(keyName, JSON.stringify(visitedSteps))
  }

  const tourStarted = () => {
    props.tourStarted()
  }

  const tourEndCallback = () => {
    props.tourFinished()
  }

  return (
    (props.isTourLoaded && !props.isTourFinished && steps.length > 0)
      ? <Tour
          name="reportPage"
          tourEndCallback={tourEndCallback}
          steps={steps}
          stepsRepo={stepsRepo}
          writeVisitedSteps={writeVisitedSteps}
          tourStarted={tourStarted}
          ReactGA={props.ReactGA}
        />
      : <div />
  )
}

TourContainer.propTypes = {
  // isTourStarted: PropTypes.bool.isRequired,
  // isTourRestarted: PropTypes.bool.isRequired,
  isTourFinished: PropTypes.bool.isRequired,
  isTourLoaded: PropTypes.bool.isRequired,
  // isTourNeedToUpdate: PropTypes.bool.isRequired,

  tourLoaded: PropTypes.func.isRequired,
  tourFinished: PropTypes.func.isRequired,
  tourStarted: PropTypes.func.isRequired,
  tourNeedToUpdate: PropTypes.func.isRequired,

  name: PropTypes.string.isRequired,
  ReactGA: PropTypes.shape().isRequired
}

const mapStateToProps = state => ({
  isTourStarted: selector.isTourStarted(state),
  isTourRestarted: selector.isTourRestarted(state),
  isTourFinished: selector.isTourFinished(state),
  isTourLoaded: selector.isTourLoaded(state),
  isTourNeedToUpdate: selector.isTourNeedToUpdate(state)
})

const mapDispatchToProps = dispatch => ({
  tourLoaded () {
    dispatch(tourActions.tourLoaded())
  },
  tourFinished () {
    dispatch(tourActions.tourFinished())
  },
  tourStarted () {
    dispatch(tourActions.tourStarted())
  },
  tourNeedToUpdate () {
    dispatch(tourActions.tourNeedToUpdate())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(TourContainer)
