import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog as cog } from '@fortawesome/free-solid-svg-icons/faCog'

import i18n from '../util/localization'

const ChangeUnitsLink = () => (
  <Link to="/settings/profile">
    <button type="button" className="btn btn-link btn-info">
      <FontAwesomeIcon icon={cog} /> {i18n.t('Enheter')}
    </button>
  </Link>
)

export default ChangeUnitsLink
