import React from 'react'
import PropTypes from 'prop-types'
import i18n from '../../util/localization'
import styles from './VehicleDataTableHeaderColumnValue.scss'
import { Order } from './VehicleDataTableSorter'
import { stripBraces } from '../../util/dataManipulation'

const getCssClass = (order) => {
  if (order) {
    return order === Order.ASC
      ? 'glyphicon glyphicon-chevron-up'
      : 'glyphicon glyphicon-chevron-down'
  }
  return ''
}

const VehicleDataTableHeaderColumnValue = ({ name, unit, order }) => {
  const unitToDisplay = stripBraces(i18n.t(unit))

  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerText}>
        <span className={getCssClass(order)} /> <span className={styles.headerName}>{i18n.t(name)}</span>{'\u00a0'}
      </div>
      { unit && (
      <div className={styles.headerUnit}>{`(${unitToDisplay})`}</div>
      )}
    </div>
  )
}

VehicleDataTableHeaderColumnValue.propTypes = {
  name: PropTypes.string.isRequired,
  unit: PropTypes.string,
  order: PropTypes.string
}

VehicleDataTableHeaderColumnValue.defaultProps = {
  unit: null,
  order: undefined
}

export default VehicleDataTableHeaderColumnValue
