import PropTypes from 'prop-types'
import React from 'react'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import TrendArrow from './TrendArrow'
import GAEvent from '../GAEvent'
import Style from './VehicleDataColumn.scss'
import i18n from '../../util/localization'
import EmissionSpecDetails from './EmissionSpecDetails'
import { stripBraces } from '../../util/dataManipulation'

const formatDiff = (value) => {
  let formatted = '-'
  if (value !== null) {
    if (value > 0.0) {
      formatted = `+${value.toLocaleString(i18n.language)}`
    } else {
      formatted = `${value.toLocaleString(i18n.language)}`
    }
  }
  return formatted
}

const popover = (
  referenceValue, deltaValue, isGood,
  previousPeriod, parameter, vehicleName, ReactGA
) => (
  <Popover id="delta-popover" className="scania-popover">
    <GAEvent category="click" action="user clicked table value with popover" ReactGA={ReactGA} />
    <table>
      <tbody>
        <tr>
          <td colSpan="2">
            <small>
              <b className="secret_text">{i18n.t(parameter)} - {vehicleName}</b>
            </small>
          </td>
        </tr>
        <tr>
          <td className={Style.nameColumn}>
            <small>
              {previousPeriod
                ? (
                <a
                  className={Style.previousPeriodLink}
                  href={previousPeriod}
                >
                  <b>{i18n.t('FöregåendePeriod')}</b>
                </a>
                  )
                : (
                <b>{i18n.t('FöregåendePeriod')}</b>
                  )}
              <b>: </b>
            </small>
          </td>
          <td className={Style.valueColumn} align="right">
            <small>
              <b>
                {referenceValue.toLocaleString(i18n.language)}
              </b>
            </small>
          </td>
        </tr>
        <tr>
          <td>
            <small>
              {i18n.t('Skillnad')}:
            </small>
          </td>
          <td className={Style.valueColumn} align="right">
            <small>
              <b className={` ${isGood ? 'green' : 'red'}`}>
                {formatDiff(deltaValue)}
              </b>
            </small>
          </td>
        </tr>
      </tbody>
    </table>
  </Popover>
)

const VehicleDataColumn = ({
  vehicleName, parameter, periodValue, referenceValue,
  delta, isGood, previousPeriod, unit, emissionSpec, ReactGA
}) => {
  if (periodValue === null) {
    return (<div className={Style['non-interactive-column']}>-</div>)
  }

  if (delta === null) {
    return (
      <div className={Style['non-interactive-column']}>
        {periodValue.toLocaleString(i18n.language)} {periodValue !== '-' ? stripBraces(i18n.t(unit)) : ''}
        { ENV_VAR_APPLICATION === 'ENVIRONMENTAL' && emissionSpec && emissionSpec.length &&
          <EmissionSpecDetails details={emissionSpec} ReactGA={ReactGA}></EmissionSpecDetails>
        }
      </div>
    )
  }

  const direction = delta > 0 ? 'up' : 'down'

  return (
    <OverlayTrigger trigger="click" placement="top" overlay={popover(referenceValue, delta, isGood, previousPeriod, parameter, vehicleName, ReactGA)}>
      <div className={Style.column}>
        <TrendArrow
          direction={direction}
          positive={isGood}
        /> {periodValue.toLocaleString(i18n.language)} {periodValue !== '-' ? stripBraces(i18n.t(unit)) : ''}
        { ENV_VAR_APPLICATION === 'ENVIRONMENTAL' && emissionSpec && emissionSpec.length &&
          <EmissionSpecDetails details={emissionSpec} ReactGA={ReactGA}></EmissionSpecDetails>
        }
      </div>
    </OverlayTrigger>
  )
}

VehicleDataColumn.propTypes = {
  vehicleName: PropTypes.string,
  parameter: PropTypes.string,
  referenceValue: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  periodValue: PropTypes.any,
  delta: PropTypes.number,
  isGood: PropTypes.bool,
  unit: PropTypes.string,
  previousPeriod: PropTypes.string,
  emissionSpec: PropTypes.array,
  ReactGA: PropTypes.shape().isRequired
}

VehicleDataColumn.defaultProps = {
  vehicleName: '-',
  parameter: '-',
  periodValue: null,
  referenceValue: null,
  delta: null,
  isGood: null,
  unit: null,
  previousPeriod: '/',
  emissionSpec: null
}

export default VehicleDataColumn
