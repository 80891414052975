// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MainContainer_verticalMargins-RYtel{margin-bottom:65px;margin-top:75px}\n", "",{"version":3,"sources":["webpack://./src/containers/MainContainer.scss"],"names":[],"mappings":"AAAA,qCACI,kBAAmB,CACnB,eAAgB","sourcesContent":[".verticalMargins {\n    margin-bottom: 65px;\n    margin-top: 75px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"verticalMargins": "MainContainer_verticalMargins-RYtel"
};
export default ___CSS_LOADER_EXPORT___;
