// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media all and (max-width: 991px){.VehicleDataTableRow_vehicleName-uqGBQ{white-space:nowrap;max-width:70px;overflow:hidden;text-overflow:ellipsis}.VehicleDataTableRow_vehicleName-uqGBQ.btn.btn-link{color:#4a8ba4;padding:0;border:0}}.VehicleDataTableRow_iconsContainer-VEJcZ{width:auto;display:flex;margin-left:1rem}\n", "",{"version":3,"sources":["webpack://./src/components/VehicleDataTable/VehicleDataTableRow.scss","webpack://./src/style/_colors.scss"],"names":[],"mappings":"AAIA,kCAEI,uCACI,kBAAmB,CACnB,cAAe,CACf,eAAgB,CAChB,sBAAuB,CAJ3B,oDAQY,aCHI,CDIJ,SAAU,CACV,QAAS,CACZ,CAMb,0CACI,UAAW,CACX,YAAa,CACb,gBAAiB","sourcesContent":["@import \"../../style/fonts\";\n@import \"../../style/sizes\";\n@import \"../../style/colors\";\n\n@media all and (max-width: $tablet-width) {\n\n    .vehicleName {\n        white-space: nowrap;\n        max-width: 70px;\n        overflow: hidden;\n        text-overflow: ellipsis;\n\n        &:global(.btn) {\n            &:global(.btn-link) {\n                color: $info-color;\n                padding: 0;\n                border: 0;\n            }\n        }\n    }\n\n}\n\n.iconsContainer {\n    width: auto;\n    display: flex;\n    margin-left: 1rem;\n}","$background-color: #eeeff0;\n$light-text-color: #94999d;\n$light-border-color: #dadada;\n$dark-text-color: #242424;\n$blue-text-color: #041e42;\n$white-color: white;\n$light-blue-color: #f2f7f9;\n$primary-color: #033a6a;\n$success-color: #44a878;\n$warning-color: #f6a98e;\n$danger-color: #cc1040;\n$info-color: #4a8ba4;\n$user-icon: #0F3263;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vehicleName": "VehicleDataTableRow_vehicleName-uqGBQ",
	"iconsContainer": "VehicleDataTableRow_iconsContainer-VEJcZ"
};
export default ___CSS_LOADER_EXPORT___;
