import React from 'react'
import PropTypes from 'prop-types'
import ChangeUnitsLink from './ChangeUnitsLink'
import HelpLink from './HelpLink'
import Style from './Actionbar.scss'
import ParameterInfoModal from './ParameterInfoModal'
import TourLink from './TourLink'

class Actionbar extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      helpModalVisible: {
        box: false,
        table: true,
        setting: false
      }
    }

    this.toggleHelpModal = this.toggleHelpModal.bind(this)
    this.onChangeTab = this.onChangeTab.bind(this)
  }

  onChangeTab (params) {
    this.setState({ helpModalVisible: { ...params } })
  }

  toggleHelpModal () {
    const statecopy = this.state
    statecopy.helpModalVisible.box = !this.state.helpModalVisible.box
    this.setState(statecopy)
  }

  render () {
    return (
      <div className={`panel panel-default ${Style.actionbar}`}>
        <TourLink clickFunc={this.props.restartTour} />
        <ChangeUnitsLink />
        <HelpLink onClick={this.toggleHelpModal} />
        <ParameterInfoModal
          visible={this.state.helpModalVisible}
          onClose={this.toggleHelpModal}
          onChangeTab={this.onChangeTab}
        />
      </div>
    )
  }
}

Actionbar.propTypes = {
  restartTour: PropTypes.func.isRequired
}
export default Actionbar
