import React, { Fragment } from 'react'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle as infoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import i18n from '../util/localization'
import GAEvent from './GAEvent'

const popover = (ReactGA) => (
  <Popover id="info-popover" className="scania-popover">
    <GAEvent category="click" action="user clicked trend info popover" ReactGA={ReactGA} />
    <small>
      * {i18n.t('SUMMARY_Footer1')}<br />
      ** {i18n.t('SUMMARY_Footer2')}
    </small>
  </Popover>
)

const KPIInfoCircleIcon = (props) => (
  <Fragment>
    <div className="col-sm-12 hidden-xs hidden-sm">
      <OverlayTrigger trigger="click" placement="bottom" overlay={popover(props.ReactGA)}>
        <FontAwesomeIcon
          className="fa-lg pull-right mt-3 mr-3"
          style={{ cursor: 'pointer', color: '#4A8BA4' }}
          icon={infoCircle}
        />
      </OverlayTrigger>
    </div>
  </Fragment>
)

KPIInfoCircleIcon.propTypes = {
  ReactGA: PropTypes.shape().isRequired
}

export default KPIInfoCircleIcon
