import { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { getReport } from '../../state/report/actions'
import { isLoadingReport, hasLoadedReport, hasLoadingReportfailure } from '../../state/report/selectors'
import userSelectors from '../../state/user/selectors'
import { getReferenceForCountry } from '../../util/countryMapUtils'
import { useGetEnabledFeaturesForReferencesQuery } from '../../api/featureToggle'

interface QueryReportProps {
  loading: any,
  failure: any,
  alreadyLoaded: boolean,
  periodType: PeriodType,
  periodStart: string,
  periodEnd: string,
  propulsionType: PropulsionType,
  units: PropulsionConsumptionUnit,
  gasUnits: GasConsumptionUnitParameter,
  features: Features,
  getReport: any
}
interface Features {
  VPYNTProposalsAccess?: boolean
}

const QueryReport = ({
  loading,
  failure,
  alreadyLoaded,
  periodType,
  periodStart,
  periodEnd,
  propulsionType,
  units,
  gasUnits,
  features,
  getReport
}: QueryReportProps) => {
  const showUnstableHybridData = true

  const country = useSelector(state => state.user?.profile?.customerCountry)
  const staffRef = useSelector(state => state.user?.externalStaffReference)
  const customerRef = useSelector(state => state.user?.externalCustomerReference)
  const vehicleIdentifier = useSelector(state => state.user?.profile?.vehicleIdentifier)

  const countryRef = getReferenceForCountry(country)
  const skip = !countryRef || !customerRef || !staffRef
  const refs = {
    staffRef: staffRef,
    customerRef: customerRef,
    countryRef: countryRef
  }
  const {
    isSuccess: isFeatureToggleLoadingSuccess,
    data: enabledFeatures = []
  } = useGetEnabledFeaturesForReferencesQuery(refs, { skip })

  useEffect(() => {
    if (!loading &&
          !failure &&
          !alreadyLoaded &&
          periodType &&
          periodStart &&
          propulsionType &&
          units &&
          gasUnits &&
          isFeatureToggleLoadingSuccess) {
      const yntOffersToggle = enabledFeatures.includes('VPYNTProposalsAccess')
      const shouldHideAdBlueData = enabledFeatures.includes('VPHideAdBlueConsumption')

      getReport(
        periodType,
        periodStart,
        periodEnd,
        propulsionType,
        units,
        gasUnits,
        vehicleIdentifier,
        yntOffersToggle,
        { showUnstableHybridData, hideAdBlueData: shouldHideAdBlueData }
      )
    }
  }, [loading, failure, alreadyLoaded, periodType, periodStart, periodEnd, propulsionType, features, units, gasUnits, showUnstableHybridData, getReport])

  return null
}

const mapStateToProps = (state, ownProps) => ({
  loading: isLoadingReport(
    state,
    ownProps.periodType,
    ownProps.periodStart,
    ownProps.propulsionType
  ),
  failure: hasLoadingReportfailure(
    state,
    ownProps.periodType,
    ownProps.periodStart,
    ownProps.propulsionType
  ),
  alreadyLoaded: hasLoadedReport(
    state,
    ownProps.periodType,
    ownProps.periodStart,
    ownProps.propulsionType
  ),
  isSavingProfile: userSelectors.isSavingProfile(state)
})

const mapDispatchToProps = dispatch => ({
  getReport (type: string, startOfPeriod: string, endOfPeriod: string, propulsionType: string, units: any, gasUnits: string, vehicleIdentifier: string, yntOffersToggle: boolean, templateOptions: any) {
    dispatch(getReport(type, startOfPeriod, endOfPeriod, propulsionType, units, gasUnits, vehicleIdentifier, yntOffersToggle, templateOptions))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(QueryReport)
