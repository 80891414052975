export const converttoTime = (n: any) => {
  if (Number.isNaN(n) || n === 0) {
    return '00:00'
  }
  const d = Number(n)
  const h = Math.floor(d / 60)
  const m = Math.floor((d % 60))

  const hDisplay = h > 0 ? h : '00'
  const mDisplay = m > 0 ? (m < 10 ? '0' + m : m) : '00'
  return `${hDisplay}:${mDisplay}`
}

export const stripBraces = (stringToModify: string = '') => {
  return stringToModify?.replace('(', '')?.replace(')', '')
}
