import React from 'react'
import PropTypes from 'prop-types'
import Joyride, { EVENTS, STATUS } from 'react-joyride'
import i18n from '../util/localization'

const sendAnalyticsEvent = (data, tour, stepsRepo, ReactGA) => {
  const { index, status, type } = data

  const label = index >= 0 ? `${tour}-${stepsRepo[tour][index].stepKey}` : tour

  if (type === EVENTS.TOUR_END && status === STATUS.FINISHED) {
    ReactGA.event({
      category: 'tour',
      action: 'ended',
      label
    })
  } else if (type === EVENTS.TOUR_END && status === STATUS.SKIPPED) {
    ReactGA.event({
      category: 'tour',
      action: 'skipped tour',
      label
    })
  } else if (type === EVENTS.TOUR_START) {
    ReactGA.event({
      category: 'tour',
      action: 'started tour'
    })
  }
  if (type === EVENTS.TOOLTIP) {
    ReactGA.event({
      category: 'tour',
      action: 'step shown',
      label
    })
  }
}

const Tour = (props) => {
  const handleCallback = (data) => {
    const { step, type } = data

    if (type === EVENTS.TOUR_END) {
      const allStepKeys = props.stepsRepo[props.name].map(a => a.stepKey)
      const stepKeyIndex = allStepKeys.indexOf(step.stepKey)
      const visitedSteps = allStepKeys.splice(0, stepKeyIndex + 1)
      props.writeVisitedSteps(visitedSteps)
      props.tourEndCallback()
    }
    if (type === EVENTS.TOUR_START) {
      props.tourStarted()
    }
    sendAnalyticsEvent(data, props.name, props.stepsRepo, props.ReactGA)
  }

  return (
    <Joyride
      continuous
      run
      showProgress
      showSkipButton
      scrollToFirstStep
      callback={handleCallback}
      scrollOffset={158}
      locale={{
        back: i18n.t('Tillbaka'),
        close: i18n.t('Stäng'),
        last: i18n.t('Stäng'),
        next: i18n.t('Nästa'),
        skip: i18n.t('HoppaÖver')
      }}
      steps={props.steps}
      styles={{
        options: {
          primaryColor: '#E35205',
          zIndex: 1899
        },
        tooltipContainer: {
          textAlign: 'left'
        },
        tooltipContent: {
          paddingTop: '7px',
          fontSize: 14
        },
        tooltipTitle: {
          padding: '10px',
          fontSize: 16
        },
        tooltipFooter: {
          paddingRight: '0px'
        },
        buttonSkip: {
          fontSize: 12
        },
        buttonNext: {
          fontSize: 12
        },
        buttonBack: {
          borderRadius: 4,
          borderStyle: 'solid',
          borderColor: '#033a6a',
          borderWidth: '1px',
          padding: '7px',
          fontSize: 12
        }
      }}
    />
  )
}

Tour.propTypes = {
  name: PropTypes.string.isRequired,
  tourEndCallback: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  steps: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  stepsRepo: PropTypes.any.isRequired,
  writeVisitedSteps: PropTypes.func.isRequired,
  tourStarted: PropTypes.func.isRequired,
  ReactGA: PropTypes.shape().isRequired
}

export default Tour
