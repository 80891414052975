import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import LaunchModal from '../components/LaunchModal'
import { load, setSeen } from '../state/launchInformation/actions'

class LaunchContainer extends React.Component {
  UNSAFE_componentWillMount () {
    if (!this.props.loaded) {
      this.props.loadPreviousOnboardings()
    }
  }

  render () {
    return (
      <div>
        <LaunchModal
          visible={!this.props.hasSeenLaunchModal}
          onClose={() => this.props.setSeen()}
        />
      </div>
    )
  }
}

LaunchContainer.propTypes = {
  loadPreviousOnboardings: PropTypes.func.isRequired,
  setSeen: PropTypes.func.isRequired,
  hasSeenLaunchModal: PropTypes.bool,
  loaded: PropTypes.bool.isRequired
}

LaunchContainer.defaultProps = {
  hasSeenLaunchModal: true
}

const mapStateToProps = state => ({
  loaded: state.launchInformation.loaded,
  hasSeenLaunchModal: state.launchInformation.seen
})

const mapDispatchToProps = dispatch => ({
  loadPreviousOnboardings () {
    dispatch(load())
  },
  setSeen () {
    dispatch(setSeen())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(LaunchContainer)
