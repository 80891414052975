import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import TimePeriodItem from '../TimePeriodItem'
import i18n from '../../util/localization'
import style from './ReportPicker.scss'

class TimePeriodPicker extends React.Component {
  constructor (props) {
    super(props)
    this.getSelectedPeriodType = this.getSelectedPeriodType.bind(this)
    this.reportHasVehicles = this.reportHasVehicles.bind(this)
  }

  getSelectedPeriodType () {
    let periodType = ''
    if (this.props.week) {
      periodType = 'week'
    } else if (this.props.month) {
      periodType = 'month'
    } else if (this.props.year) {
      periodType = 'year'
    }
    return periodType
  }

  reportHasVehicles (reportCount, propulsionType) {
    return (reportCount[propulsionType] || 0) > 0
  }

  render () {
    const yearReports = Object.keys(this.props.reports.year || {}).filter((t) =>
      this.reportHasVehicles(
        this.props.reports.year[t].reportCount,
        this.props.propulsionType
      )
    )

    const monthReports = Object.keys(this.props.reports.month || {}).filter(
      (t) =>
        this.reportHasVehicles(
          this.props.reports.month[t].reportCount,
          this.props.propulsionType
        )
    )

    const weekReports = Object.keys(this.props.reports.week || {}).filter((t) =>
      this.reportHasVehicles(
        this.props.reports.week[t].reportCount,
        this.props.propulsionType
      )
    )

    const thisYear = moment().startOf('year').format('YYYY-MM-DD')
    const thisMonth = moment().startOf('month').format('YYYY-MM-DD')

    const anyReportsThisYear =
      weekReports.filter((t) => moment(t).isSame(thisYear, 'year')).length > 0

    const anyReportsThisMonth =
      weekReports.filter((t) => moment(t).isSame(thisMonth, 'month')).length >
      0

    const invalidSubmit =
      !this.props.year ||
      (this.props.year === thisYear && !this.props.month) ||
      (this.props.month === thisMonth && !this.props.week)

    const submitPeriodType = this.getSelectedPeriodType()

    let submitPeriod = ''
    switch (submitPeriodType) {
      case 'year':
        submitPeriod = ((this.props.reports.year || {})[this.props.year] || {})
          .periodStart
        break
      case 'month':
        submitPeriod = (
          (this.props.reports.month || {})[this.props.month] || {}
        ).periodStart
        break
      case 'week':
        submitPeriod = ((this.props.reports.week || {})[this.props.week] || {})
          .periodStart
        break
      default:
        submitPeriod = moment(this.props.periodStart.replace('Z', '')).format(
          'YYYY-MM-DD'
        )
    }

    return (
      <>
        <div className={`row ${style['report-picker-row']}`}>
          <div
            className={`col-sm-4 col-xs-3 ${style['report-picker-header-column']}`}
          >
            <h6>{i18n.t('Tidsperiod')}</h6>
          </div>
          <div
            className={`col-sm-8 col-xs-9 ${style['report-picker-header-column']}`}
          >
            <h6 className="pull-right">
              <small>
                <span className="badge">#</span> {i18n.t('AntalFordon')}
              </small>
            </h6>
          </div>
        </div>
        <div className={`row ${style['report-picker-row']}`}>
          <div
            className={`col-xs-4 ${style['report-picker-time-period-column']}`}
          >
            <ul className="list-group">
              {yearReports.map((key) => (
                <TimePeriodItem
                  key={key}
                  active={this.props.year === key}
                  periodStart={this.props.reports.year[key].periodStart}
                  access={this.props.reports.year[key].access}
                  numberOfVehicles={
                    this.props.reports.year[key].reportCount[
                      this.props.propulsionType
                    ]
                  }
                  periodType="year"
                  onClick={() =>
                    this.props.handleDateChange({
                      year: key,
                      month: null,
                      week: null
                    })
                  }
                  ReactGA={this.props.ReactGA}
                />
              ))}
              {anyReportsThisYear && (
                <TimePeriodItem
                  active={this.props.year === thisYear}
                  periodStart={thisYear}
                  periodType="year"
                  access
                  onClick={() =>
                    this.props.handleDateChange({
                      year: thisYear,
                      month: null,
                      week: null
                    })
                  }
                  ReactGA={this.props.ReactGA}
                />
              )}
            </ul>
          </div>
          <div
            className={`col-xs-4 ${style['report-picker-time-period-column']} ${
              this.props.year != null ? '' : 'hidden'
            } `}
          >
            <ul className="list-group">
              {monthReports
                .filter((t) => moment(t).isSame(this.props.year, 'year'))
                .map((key) => (
                  <TimePeriodItem
                    key={key}
                    active={this.props.month === key}
                    periodStart={this.props.reports.month[key].periodStart}
                    access={this.props.reports.month[key].access}
                    periodType="month"
                    numberOfVehicles={
                      this.props.reports.month[key].reportCount[
                        this.props.propulsionType
                      ]
                    }
                    onClick={() => this.props.handleDateChange({ month: key, week: null })}
                    ReactGA={this.props.ReactGA}
                  />
                ))}
              {thisYear === this.props.year && anyReportsThisMonth && (
                <TimePeriodItem
                  active={this.props.month === thisMonth}
                  periodStart={thisMonth}
                  periodType="month"
                  access
                  onClick={() =>
                    this.props.handleDateChange({
                      month: thisMonth,
                      week: null
                    })
                  }
                  ReactGA={this.props.ReactGA}
                />
              )}
            </ul>
          </div>
          <div
            className={`col-xs-4 ${style['report-picker-time-period-column']} ${
              this.props.month != null ? '' : 'hidden'
            }`}
          >
            <ul className="list-group">
              {weekReports
                .filter((t) => moment(t).isSame(this.props.month, 'month'))
                .map((key) => (
                  <TimePeriodItem
                    key={key}
                    active={this.props.week === key}
                    periodStart={this.props.reports.week[key].periodStart}
                    access={this.props.reports.week[key].access}
                    periodType="week"
                    numberOfVehicles={
                      this.props.reports.week[key].reportCount[
                        this.props.propulsionType
                      ]
                    }
                    onClick={() => this.props.handleDateChange({ week: key })}
                    ReactGA={this.props.ReactGA}
                  />
                ))}
            </ul>
          </div>
        </div>
        <div
          className={`row ${style['report-picker-row']} ${style['report-picker-submit-row']}`}
        >
          <div className="col-xs-12">
            <Link
              to={`/${submitPeriodType}/${moment(submitPeriod || 0).format(
                'YYYY-MM-DD'
              )}/${this.props.propulsionType}`}
              onClick={
                invalidSubmit
                  ? (e) => {
                      e.preventDefault()
                    }
                  : this.props.toggleDropdown
              }
            >
              <button
                className={`btn btn-success btn-lg btn-block visible-xs-block visible-sm-block ${
                  invalidSubmit ? 'disabled' : ''
                }`}
              >
                {i18n.t('Visa')}
              </button>
              <button
                className={`btn btn-success pull-right dropdown-toggle hidden-sm hidden-xs ${
                  invalidSubmit ? 'disabled' : ''
                }`}
              >
                {i18n.t('Visa')}
              </button>
            </Link>
          </div>
        </div>
      </>
    )
  }
}

TimePeriodPicker.propTypes = {
  periodStart: PropTypes.string.isRequired,
  periodType: PropTypes.string.isRequired,
  year: PropTypes.string,
  month: PropTypes.string,
  week: PropTypes.string,
  propulsionType: PropTypes.string.isRequired,
  reports: PropTypes.shape({
    week: PropTypes.shape({}),
    month: PropTypes.shape({}),
    year: PropTypes.shape({})
  }).isRequired,
  toggleDropdown: PropTypes.func.isRequired,
  ReactGA: PropTypes.shape().isRequired,
  handleDateChange: PropTypes.func.isRequired
}

export default TimePeriodPicker
