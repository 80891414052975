import { useIsFeatureEnabled } from '../../components/FeatureToggle/hooks'
import ReportsSchema from './ReportsSchema'

const VPHybridEnergyConsumption = 'VPHybridEnergyConsumption'

const useReportColumns = (propulsionType: PropulsionType): any[] => {
  const showUnstableHybridData = useIsFeatureEnabled(VPHybridEnergyConsumption)
  const hideAdBlueData = useIsFeatureEnabled('VPHideAdBlueConsumption')
  return ReportsSchema.columnsOf(propulsionType, { showUnstableHybridData, hideAdBlueData })
}

const useReportModel = (propulsionType: PropulsionType): ReportsSchemaPropulsionType => {
  const showUnstableHybridData = useIsFeatureEnabled(VPHybridEnergyConsumption)
  return ReportsSchema.get(propulsionType, { showUnstableHybridData })
}

const useReportKpi = (propulsionType: PropulsionType): any[] => {
  const report = useReportModel(propulsionType)
  return report.kpi
}

export {
  useReportColumns,
  useReportModel,
  useReportKpi
}
