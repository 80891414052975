import PropTypes from 'prop-types'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp as arrowUp } from '@fortawesome/free-solid-svg-icons/faArrowUp'
import { faArrowDown as arrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown'
import Style from './TrendArrow.scss'

const TrendArrow = ({
  direction = 'down', positive = false
}) => {
  if (direction === 'up') {
    return (
      <span id="arrowSpan">
        <FontAwesomeIcon
          className={`${Style.arrow} ${(positive === true ? Style.green : Style.red)}`}
          icon={arrowUp}
        />
      </span>
    )
  }
  return (
    <span id="arrowSpan">
      <FontAwesomeIcon
        className={`${Style.arrow} ${(positive === true ? Style.green : Style.red)}`}
        icon={arrowDown}
      />
    </span>
  )
}

TrendArrow.propTypes = {
  direction: PropTypes.string.isRequired,
  positive: PropTypes.bool
}

TrendArrow.defaultProps = {
  positive: false
}

export default TrendArrow
