/* eslint-disable max-len */
import React from 'react'
import { Carousel } from 'react-bootstrap'
import PropTypes from 'prop-types'
import style from './KPICards.scss'
import KPIInfoCircleIcon from './KPIInfoCircleIcon'
import KPICardItem from './KPICardItem'
import { useReportKpi } from '../util/ReportsSchema/hooks'

const reportEvent = (ReactGA) => {
  ReactGA.event({
    category: 'click',
    action: 'kpi cards carousel switch'
  })
}

const KPICards = React.memo(function KPICards ({ dataSource, propulsionType, ReactGA }) {
  const reportKpi = useReportKpi(propulsionType)

  return (
    <div className={style['KPI-cards']}>
      <div className="row flex-row">
        <div className="col-xs-12 visible-xs-block visible-sm-block">
          <Carousel indicators interval={null} controls onSelect={reportEvent(ReactGA)}>
            {
              reportKpi.map(item => (
                <Carousel.Item key={item.name}>
                    <div className={style['carousel-item']}>
                      <KPICardItem key={item.name} count={reportKpi.length} data={dataSource} item={item} />
                    </div>
                  </Carousel.Item>
              ))
              }
          </Carousel>
        </div>
        <div className="col-lg-12 col-xs-12 hidden-xs hidden-sm">
          <div className="panel panel-default">
            <div className="row kpi-items-box">
              <KPIInfoCircleIcon ReactGA={ReactGA} />
              {
                reportKpi.map(item => <KPICardItem key={item.name} count={reportKpi.length} data={dataSource} item={item} />)
              }
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-xs-12 hidden-xs hidden-sm" />
      </div>
    </div>
  )
})

KPICards.propTypes = {
  dataSource: PropTypes.shape().isRequired,
  propulsionType: PropTypes.string.isRequired,
  ReactGA: PropTypes.shape().isRequired
}

export default KPICards
