import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import IntroductionInformation from './IntroductionInformation'
import style from './LaunchModal.scss'
import i18n from '../util/localization'

class LaunchModal extends React.Component {
  static closeMobileImg () {
    return ''
  }

  render () {
    return (
      <div>
        <Modal
          style={{ zIndex: 2000 }}
          dialogClassName={style['launch-modal']}
          keyboard
          bsSize="large"
          show={this.props.visible}
          onHide={this.props.onClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>&nbsp;</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <IntroductionInformation />
          </Modal.Body>
          <Modal.Footer>
            <button type="button" onClick={this.props.onClose} className="btn btn-lg btn-success">{i18n.t('Fortsätt')}</button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

LaunchModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default LaunchModal
