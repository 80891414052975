import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import getPlans from '../../state/servicePlaning/actions'
import servicePlanningSelector from '../../state/servicePlaning/selectors'
import { getExtCustRef } from '../../state/authentication/selectors'
import i18n from '../../util/localization'
import { Toast, CloseToast, DelayedToast } from '../../util/toaster'

class QueryPlaning extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      toastObjectID: ''
    }
    this.request = this.request.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.request(this.props)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.request(nextProps)
  }

  request (props) {
    if (props.isServicePlanningLoaded) {
      CloseToast(this.state.toastObjectID)
    }
    if (props.isServicePlanningFailed) {
      if (props.tryNumber < 2) {
        DelayedToast(this.state.toastObjectID)
        this.props.getPlans(props.getExtCustRef)
      } else {
        props.ReactGA.event({
          category: 'error',
          action: 'error in Service Planning endpoint',
          label: i18n.language
        })
        CloseToast(this.state.toastObjectID)
        Toast({ message: i18n.t('EttFelHarUppstått_FörsökIgenSenare_'), panelClass: 'danger', duration: 5000 })
      }
    }
    if (!props.isServicePlanningLoaded &&
        !props.isServicePlanningLoading &&
        !props.isServicePlanningFailed &&
        props.getExtCustRef) {
      this.props.getPlans(props.getExtCustRef)
      const toastObjectID = Toast(
        {
          message: i18n.t('HämtarData_'),
          title: i18n.t('Serviceplanering'),
          isPermanent: true,
          id: 'serviceplanning-loader',
          panelClass: 'info',
          alignment: 'bottom',
          loadingIcon: true
        }
      )
      this.setState({ toastObjectID })
    }
  }

  render () {
    return null
  }
}

QueryPlaning.propTypes = {
  getPlans: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  isServicePlanningLoaded: servicePlanningSelector.isServicePlanningLoaded(state),
  isServicePlanningFailed: servicePlanningSelector.isServicePlanningFailed(state),
  isServicePlanningLoading: servicePlanningSelector.isServicePlanningLoading(state),
  tryNumber: servicePlanningSelector.tryNumber(state),
  getExtCustRef: getExtCustRef(state)
})

const mapDispatchToProps = dispatch => ({
  getPlans (type, externalCustomerReference) {
    dispatch(getPlans(type, externalCustomerReference))
  }

})

export default connect(mapStateToProps, mapDispatchToProps)(QueryPlaning)
