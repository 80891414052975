import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { getReports, hasLoadedReports, getLastViewed } from '../state/report/selectors'
import { lastOrDefault } from '../util/defaultReport'
import userSelectors from '../state/user/selectors'
import { getExtStaffRef } from '../state/authentication/selectors'
import { expiredExistingAuth } from '../state/authentication/actions'
import ListReports from '../components/data/ListReports'
import QueryStaff from '../components/data/QueryStaff'
import Spinner from '../components/Spinner'

const DefaultReportContainer = ({
  reports, loadedReports, staffStartOfWeek, extStaffRef, expExistAuth, lastViewed, match
}) => {
  const { access, redirectLink } = lastOrDefault(
    lastViewed,
    reports,
    match.params.type,
    match.params.start
  )

  useEffect(() => {
    if (loadedReports && !access) {
      expExistAuth()
    }
  })

  return (
    <div>
      <QueryStaff extStaffRef={extStaffRef} />
      <ListReports startOfWeek={staffStartOfWeek} />
      {loadedReports ? <Redirect to={redirectLink} /> : (<Spinner />)}
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  expExistAuth: () => {
    dispatch(expiredExistingAuth())
  }
})

DefaultReportContainer.propTypes = {
  reports: PropTypes.shape({}),
  loadedReports: PropTypes.bool.isRequired,
  staffStartOfWeek: PropTypes.string,
  extStaffRef: PropTypes.string.isRequired,
  expExistAuth: PropTypes.func.isRequired,
  lastViewed: PropTypes.shape({}),
  match: PropTypes.shape({
    params: PropTypes.shape({
      type: PropTypes.string,
      start: PropTypes.string
    })
  })
}

DefaultReportContainer.defaultProps = {
  reports: {},
  staffStartOfWeek: null,
  lastViewed: undefined,
  match: {
    params: {}
  }
}

const mapStateToProps = state => ({
  reports: getReports(state),
  loadedReports: hasLoadedReports(state),
  staffStartOfWeek: userSelectors.getStartOfWeek(state),
  extStaffRef: getExtStaffRef(state),
  lastViewed: getLastViewed(state)
})

export default connect(mapStateToProps, mapDispatchToProps)(DefaultReportContainer)
