import React from 'react'
import i18n from '../util/localization'
import Launchphoto from '../assets/Launchphoto.png'

const IntroductionInformation = () => (
  <div>
    <div className="row">
      <div className="col-xs-12">
        <div className="jumbotron white">
          <div className="container">
            <h2 className="text-center">{i18n.t('Uppföljningsrapport')}</h2>
            <h1 className="text-center">{i18n.t('NyttUtseende_NyaMöjligheter')}</h1>
            <p className="text-center">{i18n.t('NuFårDuTillgångTillUppföljningsrapportenDirektIWebbläsaren_DetÄrSamma')}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="row hidden-sm hidden-xs">
      <div className="col-md-4">
        <h4 className="text-center">
          {i18n.t('AllaRapporterPåEttStälle')}
        </h4>
        <p className="text-center">{i18n.t('NärUppföljningsrapportenFlyttasTillWebbenFårDuÅtkomstTillAllaDinaUppföljningsrapporter')}</p>
      </div>
      <div className="col-md-4">
        <h4 className="text-center">
          {i18n.t('EnkelÅtkomst')}
        </h4>
        <p className="text-center">{i18n.t('DuFårFortfarandeRapportenViaE_PostOchKommerÅtDenMedEtt')}</p>
      </div>
      <div className="col-md-4">
        <h4 className="text-center">
          {i18n.t('InformationOmDinaGasfordon')}
        </h4>
        <p className="text-center">{i18n.t('NärUppföljningsrapportenFlyttarTillWebbenFårDuTillgångTillInformationOmDina')}</p>
      </div>
    </div>
    <div className="row hidden-sm hidden-xs">
      <div className="col-xs-12 col-md-6 col-md-offset-3">
        <img className="img-responsive" src={Launchphoto} alt="" />
      </div>
    </div>
  </div>
)

export default IntroductionInformation
