const Severity = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  DANGER: 'danger'
}

Object.freeze(Severity)

export default Severity
