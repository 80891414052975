import moment from 'moment'

const priority = { year: 1, month: 2, week: 3 }

const notEmpty = obj => obj && !!Object.keys(obj).length

const reportsToArray = reports => Object.keys(reports)
  .sort((a, b) => priority[a] - priority[b])
  .map(type =>
    Object.keys(reports[type])
      .map(startDate => reports[type][startDate]))
  .reduce((acc, x) => acc.concat(x), [])

const reportWithMostVehicles = (report) => {
  const entries = Object.entries(report.reportCount)
  const sorted = entries.sort((a, b) => b[1] - a[1])
  return sorted[0][0]
}

const lastViewedValid = (lastViewed, reports) =>
  notEmpty(lastViewed) &&
  notEmpty(reports) &&
  notEmpty(reports[lastViewed.periodType]) &&
  notEmpty(reports[lastViewed.periodType][lastViewed.periodStart]) &&
  !!reports[lastViewed.periodType][lastViewed.periodStart].reportCount[lastViewed.propulsionType]

const hasAccess = (periodType, periodStart, reports) => {
  if (
    notEmpty(reports) &&
  notEmpty(reports[periodType]) &&
  notEmpty(reports[periodType][periodStart])) {
    return reports[periodType][periodStart].access
  } else {
    return false
  }
}

const getReport = (periodType, periodStart, reports) => {
  if (
    notEmpty(reports) &&
  notEmpty(reports[periodType]) &&
  notEmpty(reports[periodType][periodStart])) {
    return reports[periodType][periodStart]
  } else {
    return false
  }
}

export const reduceToDefault = reports => {
  const reportsArray = reportsToArray(reports)
  return reportsArray.reduce((previous, current) => {
    if (current.access &&
    moment(current.periodEnd).isAfter(previous.periodEnd) && (
      current.reportCount.liquid ||
      current.reportCount.gas ||
      current.reportCount.hybrid ||
      current.reportCount.electric ||
      current.reportCount.engines
    )) {
      return current
    }
    return previous
  }, { access: false, periodEnd: '2000-01-01' })
}

export const tryDaysBeforeAfter = (periodType, periodStart, reports) => {
  const after = moment(periodStart).add(1, 'days').format('YYYY-MM-DD')
  if (hasAccess(periodType, after, reports)) {
    return after
  }
  const before = moment(periodStart).add(-1, 'days').format('YYYY-MM-DD')
  if (hasAccess(periodType, before, reports)) {
    return before
  }
  return false
}

export const lastOrDefault = (lastViewed, reports, periodType, periodStart) => {
  if (hasAccess(periodType, periodStart, reports)) {
    return {
      access: true,
      redirectLink: `/${periodType}/${periodStart}/${reportWithMostVehicles(getReport(periodType, periodStart, reports))}`
    }
  }

  const tryResult = tryDaysBeforeAfter(periodType, periodStart, reports)
  if (tryResult) {
    return {
      access: true,
      redirectLink: `/${periodType}/${tryResult}/${reportWithMostVehicles(getReport(periodType, tryResult, reports))}`
    }
  }

  if (lastViewedValid(lastViewed, reports)) {
    return {
      access: true,
      redirectLink: `/${lastViewed.periodType}/${lastViewed.periodStart}/${lastViewed.propulsionType}`
    }
  }
  const defaultReport = reduceToDefault(reports)
  const { access } = defaultReport
  if (access) {
    return {
      access,
      redirectLink: `/${defaultReport.periodType}/${defaultReport.periodStart}/${reportWithMostVehicles(defaultReport)}`
    }
  }
  return {
    access,
    redirectLink: '/login'
  }
}
