import Severity from './severity'

const Errors = {
  AUTHENTICATION_FAILED: 'AutentiseringenHarMisslyckats',
  BAD_CREDENTIALS: 'OgiltigtAnvändarnamnEllerLösenord_FörsökIgen_',
  AN_ERROR_OCCURED: 'EttFelHarUppstått',
  AN_ERROR_OCCURED_PLEASE_TRY_AGAIN: 'EttTillfälligtFelUppstod_VäntaEnStundOchFörsökIgen_OmProblemet',
  DOWNLOAD_FAILED: 'NedladdningenMisslyckades'
}

Object.freeze(Errors)

export default Errors

export const LOGIN_ERROR = {
  id: 1,
  type: Severity.WARNING,
  title: Errors.AUTHENTICATION_FAILED,
  text: Errors.BAD_CREDENTIALS
}

export const PDF_ERROR = {
  id: 2,
  type: Severity.DANGER,
  title: Errors.DOWNLOAD_FAILED,
  text: Errors.AN_ERROR_OCCURED_PLEASE_TRY_AGAIN
}

export const GENERAL_ERROR = {
  id: 3,
  type: Severity.DANGER,
  title: Errors.AN_ERROR_OCCURED,
  text: Errors.AN_ERROR_OCCURED_PLEASE_TRY_AGAIN
}

export const FAILED_TO_SAVE = {
  id: 4,
  type: Severity.DANGER,
  title: Errors.AN_ERROR_OCCURED,
  text: Errors.AN_ERROR_OCCURED_PLEASE_TRY_AGAIN
}
