import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Style from './KPI.scss'
import i18n from '../util/localization'
import trendFormatter from '../util/trendFormatter'
import { unitToTranslationKey } from '../util/unitTranslationKeys'
import { converttoTime, stripBraces } from '../util/dataManipulation'

const KPICardItem = ({ data, item, count }) => {
  const node = _.get(data, item.name)

  let changeIndicator = ''
  let mainFactChange = ''
  if (item.sub) {
    changeIndicator = '*'
    mainFactChange = trendFormatter(
      node.value,
      stripBraces(i18n.t(unitToTranslationKey(node.unit)))
    )
  } else {
    mainFactChange = `${node.value.toLocaleString(i18n.language)} ${stripBraces(i18n.t(unitToTranslationKey(node.unit)))}`
  }
  if (item.valueFunc === 'converttoTime') {
    mainFactChange = `${converttoTime(node.value)} ${stripBraces(i18n.t(unitToTranslationKey(node.unit)))}`
  }
  let subFactChange = ''
  if (item.sub) {
    const subnode = _.get(data, item.sub.name)
    subFactChange = trendFormatter(
      subnode.value,
      stripBraces(i18n.t(unitToTranslationKey(subnode.unit)))
    )
  }

  return (
    <div className={`col-sm-${Math.round(12 / count)} col-xs-12`}>
      <div className={Style['KPI-area']}>
        <div className={Style['KPI-icon']}>
          <img src={`/assets/${item.icon}.svg`} alt="" />
        </div>
        <div className={Style['KPI-text']}>
          <div className={Style['KPI-header']}>
            {item.header}{changeIndicator}
          </div>
          <div className={Style['KPI-value']}>
            {mainFactChange}
          </div>
          {item.sub && (
          <Fragment>
            <div className={Style['KPI-sub-header']}>
              {i18n.t(item.text)}**
            </div>
            <div className={Style['KPI-secondary-value']}>
              {subFactChange}
            </div>
            <div className={Style['KPI-sub-header']}>
              {i18n.t(item.sub.text)}
            </div>
          </Fragment>
          )
        }
        </div>
      </div>
    </div>
  )
}

KPICardItem.propTypes = {
  data: PropTypes.shape().isRequired,
  item: PropTypes.shape().isRequired,
  count: PropTypes.number.isRequired
}

export default KPICardItem
