const getPlans = (state) => {
  if (state.servicePlans) {
    return state.servicePlans.plans
  }
  return null
}

const isServicePlanningLoaded = state => state.servicePlans.loaded
const isServicePlanningFailed = state => state.servicePlans.failed
const isServicePlanningLoading = state => state.servicePlans.loading
const tryNumber = state => state.servicePlans.tryNumber

export default {
  getPlans,
  isServicePlanningLoaded,
  isServicePlanningFailed,
  isServicePlanningLoading,
  tryNumber
}
