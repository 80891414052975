import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint as print } from '@fortawesome/free-solid-svg-icons/faPrint'
import i18n from '../../util/localization'
import style from './ExportMenu.scss'

const ExportMenu = ({ dataAvailable, onExport, loading }) => (
  <div className={`btn-group ${style.exportButtonGroup}`} id="exportDivMenu">
    <button type="button" className={`btn btn-primary dropdown-toggle ${style.exportButton} ${dataAvailable && !loading ? '' : 'disabled'}`} data-toggle="dropdown">
      {loading ? i18n.t('Laddar___') : (`${i18n.t('PDF')}/${i18n.t('Excel')}`)} <FontAwesomeIcon icon={print} />
    </button>
    {dataAvailable && (
      <ul className="dropdown-menu dropdown-menu-right" role="menu">
        <li>
          <a onClick={(event) => { event.preventDefault(); onExport('PDF') }} href="#">{i18n.t('PDF')}</a>
        </li>
        <li>
          <a onClick={(event) => { event.preventDefault(); onExport('EXCEL') }} href="#">{i18n.t('Excel')}</a>
        </li>
      </ul>
    )}
  </div>
)

ExportMenu.propTypes = {
  dataAvailable: PropTypes.bool,
  onExport: PropTypes.func.isRequired,
  loading: PropTypes.bool
}

ExportMenu.defaultProps = {
  dataAvailable: false,
  loading: false
}

export default ExportMenu
