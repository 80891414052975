import pdfApi from '../../api/pdf'
import * as types from '../actionTypes'
import { blobToBase64 } from '../../util/reportExport/reportExportUtils'

function pdfRequest () {
  return {
    type: types.PDF_REQUEST
  }
}

function pdfSuccess (pdf) {
  return {
    type: types.PDF_SUCCESS,
    pdf
  }
}

function pdfFailure (error) {
  return {
    type: types.PDF_FAILURE,
    error: error.message
  }
}

function pdfDownloaded () {
  return {
    type: types.PDF_DOWNLOADED
  }
}

export const pdfHasBeenDownloaded = () => (dispatch) => {
  dispatch(pdfDownloaded())
}

export const getPdf = report => dispatch => {
  dispatch(pdfRequest())
  return pdfApi
    .get(report)
    .then(blobToBase64)
    .then(pdf => dispatch(pdfSuccess(pdf)))
    .catch(error => dispatch(pdfFailure(error)))
}
