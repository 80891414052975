import PropTypes from 'prop-types'

import i18n from '../util/localization'

const LocalizationToggle = ({ children, localeKey }) => (
  (i18n.isInitialized && i18n.exists(localeKey)) ? (children) : null
)

LocalizationToggle.propTypes = {
  children: PropTypes.any,
  localeKey: PropTypes.string
}

export default LocalizationToggle
