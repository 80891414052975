import Severity from './severity'

const Infos = {
  INVALID_LINK_FOR_LOGIN: 'LänkenÄrGiltigI2Veckor_EfterDetMåsteDuLoggaIn',
  YOU_ARE_NOT_A_RECIPIENT: 'DuÄrInteMottagareAvUppföljningsrapporten_KlickaHärFörAttBliMottagare_'
}

Object.freeze(Infos)

export default Infos

export const EXPIRED_SESSION_INFO = {
  id: 2,
  type: Severity.INFO,
  text: Infos.INVALID_LINK_FOR_LOGIN
}

export const RECIPIENT_INFO = {
  id: 3,
  type: Severity.INFO,
  text: Infos.YOU_ARE_NOT_A_RECIPIENT
}
