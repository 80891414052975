/*eslint-disable */
import style from  './toast.scss';
/* eslint-enable */

export const Toast = (option) => {
  const defaultOptions = {
    duration: 2500,
    title: '',
    panelClass: 'success',
    isPermanent: false,
    id: '',
    loadingIcon: false,
    alignment: 'top'
  }

  const { panelClass, duration, isPermanent, id, title, message, alignment, loadingIcon } =
  { ...defaultOptions, ...option }

  const iconHtml = loadingIcon
    ? `<div class='toast-loader-container'>
    <div class='toast-loader-spinner'></div>
    </div>`
    : ''

  const template = `
  <div id="${id}-panel" class="panel panel-${panelClass || defaultOptions.panelClass} toaster">
    <div class="panel-heading">${title}</div>
    <div class="panel-body">${iconHtml} <span>${message}</span></div>
  </div>`

  let wrapper = document.getElementById('vp-toaster-wrapper')
  if (!wrapper) {
    wrapper = document.createElement('div')
    wrapper.setAttribute('id', 'vp-toaster-wrapper')
    wrapper.setAttribute('class', `vp-toaster-wrapper-${alignment}`)
    const body = document.getElementsByTagName('body')[0]
    body.appendChild(wrapper)
  }

  const element = document.createElement('div')
  element.setAttribute('class', 'vp-toaster')
  element.setAttribute('id', id)
  element.innerHTML = template

  wrapper.insertBefore(element, wrapper.childNodes[0])

  setTimeout(() => {
    element.setAttribute('class', `${element.getAttribute('class')} fadeIn`)
  }, 1)

  if (!isPermanent) {
    const close = (elem) => {
      elem.setAttribute('class', `${elem.getAttribute('class')} fadeOut`)
      setTimeout(() => {
        elem.remove()
      }, 1000)
    }

    element.addEventListener('click', () => {
      close(this)
    })
    setTimeout(() => {
      close(element)
    }, duration || defaultOptions.duration)
  }
  return id
}

export const CloseToast = (id) => {
  const elem = document.getElementById(id)
  if (elem) {
    elem.setAttribute('class', `${elem.getAttribute('class')} fadeOut`)
    setTimeout(() => {
      elem.remove()
    }, 1000)
  }
}

export const DelayedToast = (id) => {
  const elem = document.getElementById(`${id}-panel`)
  if (elem) {
    let classes = elem.getAttribute('class')
    classes = classes.replace('panel-info', 'panel-warning')
    elem.setAttribute('class', classes)
  }
}
