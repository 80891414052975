import * as types from '../actionTypes'

const initialState = {
  pdf: null,
  loading: false,
  failure: false
}

const pdf = (state = initialState, action) => {
  switch (action.type) {
    case types.PDF_REQUEST:
      return Object.assign({}, state, {
        loading: true
      })
    case types.PDF_SUCCESS:
      return Object.assign({}, state, {
        pdf: action.pdf,
        loading: false
      })
    case types.PDF_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        failure: true
      })
    case types.PDF_DOWNLOADED: {
      return Object.assign({}, state, {
        pdf: null
      })
    }
    case types.LOGOUT_SUCCESS:
      return initialState
    case types.LOGIN_SUCCESS:
      return initialState
    default:
      return state
  }
}

export default pdf
