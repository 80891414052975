import authFetch from './authFetch'
import { checkStatus, parseJSON } from '../util/apiUtil'

const getSubscription = externalStaffReference => authFetch(`${ENV_VAR_BFF_HOST}/api/v1/subscriber/${externalStaffReference}`)
  .then(checkStatus)
  .then(parseJSON)

const updateSubscription = (externalStaffReference, subscriptions) => authFetch(`${ENV_VAR_BFF_HOST}/api/v1/subscriber/${externalStaffReference}`, {
  body: JSON.stringify(subscriptions),
  method: 'PATCH',
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
})
  .then(checkStatus)

export default {
  get: externalStaffReference => getSubscription(externalStaffReference),
  update: (externalStaffReference, subscriptions) =>
    updateSubscription(externalStaffReference, subscriptions)
}
