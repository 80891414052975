export const isLoadingAuthentication = state => state.authentication.loading

export const isUserProvidingBadCredentials = state => state.authentication.badCredentials

export const isUserAuthenticated = state => state.authentication.authenticated

export const getSelectableStaffs = state => state.authentication.selectableStaffs

export const isFailureLoggingIn = state => state.authentication.failure

export const hasExpiredSession = state => state.authentication.expiredSession

export const hasLockedAccount = state => state.authentication.lockedAccount

export const getFMPLink = state => state.authentication.sso.fmpLink

export const isLoadingFmpLink = state => state.authentication.sso.loading

export const getExtStaffRef = state =>
  (state.authentication.user ? state.authentication.user.extStaffRef : null)

export const getExtCustRef = state =>
  (state.authentication.user ? state.authentication.user.extCustRef : null)

export const isInRestrictedMode = state => state.authentication.user.permissions.restricted

export const shouldShowLogin = state => state.authentication.showLoginPrompt
