import React from 'react'
import PropTypes from 'prop-types'
import style from './Spinner.scss'

const Spinner = ({ color = null, extraClass = null }) => (
  <div className={`${style.container} ${extraClass}`}>
    <div className={style.spinner} style={color ? { borderTopColor: color } : {}} />
  </div>
)

Spinner.propTypes = {
  color: PropTypes.string,
  extraClass: PropTypes.string
}

Spinner.defaultProps = {
  color: null,
  extraClass: null
}

export default Spinner
