import subscriptionApi from '../../api/userSubscription'
import * as types from '../actionTypes'

function fetchSubscriptionRequest () {
  return {
    type: types.FETCH_SUBSCRIPTION_REQUEST
  }
}

function fetchSubscriptionSuccess (subscription) {
  return {
    type: types.FETCH_SUBSCRIPTION_SUCCESS,
    subscription
  }
}

function fetchSubscriptionFailure (error) {
  return {
    type: types.FETCH_SUBSCRIPTION_FAILURE,
    error: error.message
  }
}

function updateSubscriptionRequest (subscription) {
  return {
    type: types.UPDATE_SUBSCRIPTION_REQUEST,
    subscription
  }
}

function updateSubscriptionSuccess (subscription) {
  return {
    type: types.UPDATE_SUBSCRIPTION_SUCCESS,
    subscription
  }
}

function updateSubscriptionFailure (error, subscription) {
  return {
    type: types.UPDATE_SUBSCRIPTION_FAILURE,
    error,
    subscription
  }
}

const getSubscription = externalStaffReference => (dispatch) => {
  dispatch(fetchSubscriptionRequest())

  return subscriptionApi.get(externalStaffReference)
    .then((subscription) => {
      dispatch(fetchSubscriptionSuccess(subscription))
    })
    .catch((error) => {
      dispatch(fetchSubscriptionFailure(error))
    })
}

const updateSubscription = (externalStaffReference, subscription) => (dispatch) => {
  dispatch(updateSubscriptionRequest(subscription))

  return subscriptionApi.update(externalStaffReference, subscription)
    .then((sub) => {
      dispatch(updateSubscriptionSuccess(sub))
    })
    .catch((error) => {
      dispatch(updateSubscriptionFailure(error, subscription))
    })
}

export default {
  getSubscription,
  updateSubscription
}
