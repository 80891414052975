import userApi from '../../api/user'
import * as types from '../actionTypes'
import { setMopinionLanguage } from '../../util/mopinion'

function fetchUserRequest () {
  return {
    type: types.FETCH_USER_REQUEST
  }
}

function fetchUserSuccess (user) {
  return {
    type: types.FETCH_USER_SUCCESS,
    user
  }
}

function fetchUserFailure (error) {
  return {
    type: types.FETCH_USER_FAILURE,
    error: error.message
  }
}

function updateProfileRequest (profile) {
  return {
    type: types.UPDATE_PROFILE_REQUEST,
    profile
  }
}

function updateProfileSuccess (profile) {
  return {
    type: types.UPDATE_PROFILE_SUCCESS,
    profile
  }
}

function updateProfileFailure (error) {
  return {
    type: types.UPDATE_PROFILE_FAILURE,
    error
  }
}

const getUser = () => (dispatch) => {
  dispatch(fetchUserRequest())

  return userApi.get()
    .then((user) => {
      dispatch(fetchUserSuccess(user))
      setMopinionLanguage(user.language)
    })
    .catch((error) => {
      dispatch(fetchUserFailure(error))
    })
}

const updateLoggedInUserProfile = profile => (dispatch) => {
  dispatch(updateProfileRequest(profile))

  return userApi.updateLoggedInUserProfile(profile)
    .then((p) => {
      dispatch(updateProfileSuccess(p))
      setMopinionLanguage(p.language)
    })
    .catch((error) => {
      dispatch(updateProfileFailure(error))
    })
}

export default {
  getUser,
  updateLoggedInUserProfile
}
