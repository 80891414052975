import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReportsSchema from '../../util/ReportsSchema/ReportsSchema'
import VehicleDataTableHeaderColumn from './VehicleDataTableHeaderColumn'
import { Order } from './VehicleDataTableSorter'
import { useReportColumns } from '../../util/ReportsSchema/hooks'

const VehicleDataTableHeader = (props) => {
  const [columns, setColumns] = useState([Order.ASC])
  const reportColumns = useReportColumns(props.propulsionType)

  const handleOnHeaderClick = (index) => {
    const newColumns = new Array(3 + reportColumns.length)
    if (!columns[index] || columns[index] === Order.DESC) {
      newColumns[index] = Order.ASC
      props.onHeaderClick(index, Order.ASC)
    } else {
      newColumns[index] = Order.DESC
      props.onHeaderClick(index, Order.DESC)
    }
    setColumns(newColumns)
  }

  const generateColumns = (cols) => (
    <thead>
      <tr>
        <VehicleDataTableHeaderColumn name="Utrustning" onHeaderClick={handleOnHeaderClick} index={0} order={columns[0]} ReactGA={props.ReactGA} />
        { ENV_VAR_APPLICATION !== 'ENVIRONMENTAL' && <VehicleDataTableHeaderColumn name="TH_StartDate" onHeaderClick={handleOnHeaderClick} collapsable="collapsable-md" index={1} order={columns[1]} ReactGA={props.ReactGA} /> }
        { ENV_VAR_APPLICATION !== 'ENVIRONMENTAL' && <VehicleDataTableHeaderColumn name="TH_StopDate" onHeaderClick={handleOnHeaderClick} collapsable="collapsable-md" index={2} order={columns[2]} ReactGA={props.ReactGA} /> }
        {
          cols.map((el, index) => (
            <VehicleDataTableHeaderColumn
              key={el.titleKey + index}
              name={el.titleKey}
              onHeaderClick={handleOnHeaderClick}
              collapsable={el.xsScreen ? '' : 'collapsable-sm'}
              unit={ReportsSchema.generateUnits(props.units, el)}
              index={index + 3}
              order={columns[index + 3]}
              ReactGA={props.ReactGA}
            />
          ))
        }
      </tr>
    </thead>
  )

  return generateColumns(reportColumns)
}

VehicleDataTableHeader.propTypes = {
  units: PropTypes.shape({
    odometer: PropTypes.any,
    drivenDistance: PropTypes.any,
    scaniaDriverSupportScore: PropTypes.any,
    coasting: PropTypes.any,
    runtimeIdle: PropTypes.any,
    timeOverspeeding: PropTypes.any,
    numberOfHarshBrakes: PropTypes.any,
    propulsionConsumption: PropTypes.any,
    cO2: PropTypes.any
  }).isRequired,
  onHeaderClick: PropTypes.func.isRequired,
  propulsionType: PropTypes.string.isRequired
}

export default VehicleDataTableHeader
