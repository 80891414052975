import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle as infoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import i18n from '../util/localization'

const HelpLink = ({ onClick }) => (
  <button type="button" className="btn btn-link btn-info" onClick={onClick}>
    <FontAwesomeIcon icon={infoCircle} /> {i18n.t('Hjälp')}
  </button>
)

HelpLink.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default HelpLink
