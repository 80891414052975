import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { useOnClickOutside } from '../util/useOnClickOutside'
import style from './ResponsiveModalDialog.scss'
import closeMobile from '../assets/closeMobile.svg'

const ResponsiveModalDialog = ({ children, onSubmit, onClose, onClickOutside, title }) => {
  const modalRef = useRef(null)

  useOnClickOutside(modalRef, onClickOutside)

  const handleSubmit = (event) => {
    event.preventDefault()
    onSubmit()
  }

  return (
    <div className="modal modalBox" id="myModal" data-backdrop="true">
      <div className={`${style.verticalAlignmentHelper}`}>
        <div className={`${style.verticalAlignCenter} modal-dialog `}>
          <div className={`${style.modalCenterContent} modal-content`}>
            <form ref={modalRef} onSubmit={handleSubmit}>
              <div className="modal-header">
                <button type="reset" className="close" onClick={onClose} data-dismiss="modal" aria-hidden="true"> <span aria-hidden="true"><img alt="close" height="28px" width="28px" src={closeMobile} /></span></button>
                <h5 className="modal-title">{title}</h5>
              </div>
              <div className="modal-body">
                {children}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  )
}

ResponsiveModalDialog.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  onClickOutside: PropTypes.func,
  title: PropTypes.string
}

ResponsiveModalDialog.defaultProps = {
  onSubmit: (e) => { e.preventDefault() },
  onClose: (e) => { e.preventDefault() },
  title: ''
}

export default ResponsiveModalDialog
