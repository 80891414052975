import Severity from './severity'

const Warnings = {
  USER_ACCOUNT_IS_LOCKED: 'AnvändarkontotÄrLåst'
}

Object.freeze(Warnings)

export default Warnings

export const LOCKED_ACCOUNT = {
  id: 1,
  type: Severity.WARNING,
  text: Warnings.USER_ACCOUNT_IS_LOCKED
}
