import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  getReportData, isLoadingReport, hasLoadingReportfailure, hasAccessToReport,
  isEmptyReport, getPreviousPeriod
} from '../state/report/selectors'
import servicePlanningSelector from '../state/servicePlaning/selectors'
import userSelectors from '../state/user/selectors'
import { isFailureLoadingPdf } from '../state/pdf/selectors'
import { isInRestrictedMode, shouldShowLogin } from '../state/authentication/selectors'
import VehicleDataTable from '../components/VehicleDataTable/VehicleDataTable'
import ReportHeaderContainer from './ReportHeaderContainer'
import Spinner from '../components/Spinner'
import EmptyReport from '../components/EmptyReport'
import style from './ReportContainer.scss'
import { PDF_ERROR, GENERAL_ERROR } from '../util/errors'
import AlertContainer from './AlertContainer'
import KPICards from '../components/KPICards'
import QueryReport from '../components/data/QueryReport'
import Actionbar from '../components/Actionbar'
import Banner from '../components/Banner'
import QueryPlaning from '../components/data/QueryPlaning'
import TourContainer from './TourContainer'
import tourActions from '../state/tour/actions'
import FeatureToggle from '../components/FeatureToggle/FeatureToggle'
import FeatureToggleProvider from '../components/FeatureToggleProvider/FeatureToggleProvider'

const generateAlerts = (props) => {
  const alerts = []
  if (props.pdfFailure && !props.pdfLoading) {
    alerts.push(PDF_ERROR)
  }
  if (props.userFailure && !props.userLoading) {
    alerts.push(GENERAL_ERROR)
  }
  if (props.reportFailure && !props.loading && props.doHaveAccessToReport) {
    alerts.push(GENERAL_ERROR)
  }
  return alerts
}

class ReportContainer extends React.Component {
  constructor (props) {
    super(props)
    const alerts = generateAlerts(props)

    this.state = {
      alerts
    }

    this.onRemoveAlert = this.onRemoveAlert.bind(this)
    this.restartTour = this.restartTour.bind(this)
  }

  onRemoveAlert (id) {
    this.setState({
      alerts: this.state.alerts.filter(a => a.id !== id)
    })
  }

  restartTour () {
    this.props.tourRestarted()
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const alerts = generateAlerts(nextProps)

    this.setState({ alerts })
    if (nextProps.restricted === false && this.props.restricted) {
      this.forceUpdate()
    }
  }

  render () {
    const previousPeriodLink = this.props.previousPeriod
      ? `/${this.props.match.params.type}/${this.props.previousPeriod}/${this.props.match.params.propulsionType}`
      : null

    const updatedResults = this.props.report ? this.props.report.data : {}

    return (
      <div>
        <FeatureToggle feature="VPServicePlanningIntegration">
          <QueryPlaning ReactGA={this.props.ReactGA} />
        </FeatureToggle>
        <FeatureToggleProvider feature="VPYNTProposalsAccess">
          <QueryReport
            periodType={this.props.match.params.type}
            periodStart={decodeURIComponent(this.props.match.params.start)}
            periodEnd={decodeURIComponent(this.props.match.params.end)}
            propulsionType={this.props.match.params.propulsionType}
            units={this.props.units}
            gasUnits={this.props.gasUnits}
          />
        </FeatureToggleProvider>
        <div className="row">
          <div className="col-xs-12">
            <AlertContainer alerts={this.state.alerts} onRemove={this.onRemoveAlert} />
          </div>
        </div>

        <div className="row report-header-band">
          <div className="col-xs-12">

            <header className={`c-page-header ${style.headerColumn}`}>
              <ReportHeaderContainer
                periodType={this.props.match.params.type}
                periodStart={this.props.match.params.start}
                periodEnd={this.props.match.params.end}
                propulsionType={this.props.match.params.propulsionType}
                ReactGA={this.props.ReactGA}
              />
            </header>

          </div>
        </div>
        {(this.props.loading || this.props.userLoading)
          ? (<div className="row report-body-loading-band"><Spinner /></div>)
          : (
            <div className="row report-body-band">
              {((!this.props.emptyReport && ENV_VAR_APPLICATION !== 'ENVIRONMENTAL') || (ENV_VAR_APPLICATION === 'ENVIRONMENTAL' && updatedResults.equipments && updatedResults.equipments.length))
                ? (
                <div className="col-md-12 col-sm-12">
                  { ENV_VAR_APPLICATION !== 'ENVIRONMENTAL' && <TourContainer name="reportPage" servicePlanningFeature={_.isEmpty(this.props.servicePlans) ? 0 : Math.random()} ReactGA={this.props.ReactGA} /> }
                  { ENV_VAR_APPLICATION !== 'ENVIRONMENTAL' && this.props.report.data.summary?.kpi && this.props.report.data.summary?.total && (
                    <div className={`row ${style.kpiRow}`}>
                      <div className="col-xs-12">
                        <KPICards
                          propulsionType={this.props.match.params.propulsionType}
                          dataSource={this.props.report.data.summary}
                          ReactGA={this.props.ReactGA}
                        />
                      </div>
                    </div>
                  )}
                  <VehicleDataTable
                    data={updatedResults}
                    previousPeriod={previousPeriodLink}
                    restricted={this.props.restricted}
                    servicePlans={this.props.servicePlans}
                    userUnit={this.props.units}
                    propulsionType={this.props.match.params.propulsionType}
                    ReactGA={this.props.ReactGA}
                  />
                  <Actionbar restartTour={this.restartTour} />
                  <FeatureToggleProvider feature="VPUKRealtimeTrackingBanner">
                    <Banner ReactGA={this.props.ReactGA} />
                  </FeatureToggleProvider>
                </div>
                  )
                : (
                <EmptyReport />
                  )}
            </div>
            )}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  servicePlans: servicePlanningSelector.getPlans(state),

  report: getReportData(
    state,
    ownProps.match.params.type,
    ownProps.match.params.start,
    ownProps.match.params.propulsionType
  ),
  loading: isLoadingReport(
    state,
    ownProps.match.params.type,
    ownProps.match.params.start,
    ownProps.match.params.propulsionType
  ),
  reportFailure: hasLoadingReportfailure(
    state,
    ownProps.match.params.type,
    ownProps.match.params.start,
    ownProps.match.params.propulsionType
  ),
  emptyReport: isEmptyReport(
    state,
    ownProps.match.params.type,
    ownProps.match.params.start,
    ownProps.match.params.propulsionType
  ),
  pdfFailure: isFailureLoadingPdf(state),
  units: userSelectors.getPropulsionConsumptionUnit(state),
  gasUnits: userSelectors.getGasConsumptionUnit(state),
  customerName: userSelectors.getCustomerName(state),
  userLoading: userSelectors.isLoadingUser(state),
  userFailure: userSelectors.isFailureLoadingUser(state),
  restricted: isInRestrictedMode(state),
  showLogin: shouldShowLogin(state),
  doHaveAccessToReport: hasAccessToReport(
    state,
    ownProps.match.params.type,
    ownProps.match.params.start
  ),
  previousPeriod: getPreviousPeriod(
    state,
    ownProps.match.params.type,
    ownProps.match.params.start
  )
})

ReportContainer.propTypes = {
  tourRestarted: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  userLoading: PropTypes.bool,
  servicePlans: PropTypes.shape({
    maintenanceactivity: PropTypes.arrayOf(PropTypes.shape({})),
    serviceactivity: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  report: PropTypes.shape({
    data: PropTypes.shape({
      summary: PropTypes.shape({
        kpi: PropTypes.shape({}),
        total: PropTypes.shape({}),
        average: PropTypes.shape({})
      }),
      equipments: PropTypes.arrayOf(PropTypes.shape({})),
      units: PropTypes.shape({}),
      gasUnits: PropTypes.shape({})
    }),
    emissionRecords: PropTypes.array
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      type: PropTypes.string.isRequired,
      start: PropTypes.string.isRequired,
      end: PropTypes.string,
      propulsionType: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  units: PropTypes.string,
  gasUnits: PropTypes.string,
  restricted: PropTypes.bool.isRequired,
  emptyReport: PropTypes.bool.isRequired,
  previousPeriod: PropTypes.string,
  ReactGA: PropTypes.shape().isRequired
}

ReportContainer.defaultProps = {
  servicePlans: {

  },
  report: {
    emissionRecords: null
  },
  userLoading: false,
  units: undefined,
  gasUnits: undefined,
  previousPeriod: null
}

const mapDispatchToProps = dispatch => ({
  tourRestarted () {
    dispatch(tourActions.tourRestarted())
  },
  tourNeedToUpdate () {
    dispatch(tourActions.tourNeedToUpdate())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportContainer)
