import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight as chevron } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { ServicePlaningWrenchIcon } from './ServicePlanningIcon'

const VehicleDetailsButton = ({ name, notUsedVehicle, onClick, status }) => (
  <button
    type="button"
    onClick={onClick}
    className={`${notUsedVehicle ? 'disabled' : ''} btn btn-info btn-link mr-4`}
  >{name}
    <FontAwesomeIcon icon={chevron} />
    <ServicePlaningWrenchIcon status={status} />
  </button>
)

VehicleDetailsButton.propTypes = {
  name: PropTypes.string.isRequired,
  status: PropTypes.string,
  notUsedVehicle: PropTypes.bool,
  onClick: PropTypes.func.isRequired
}

VehicleDetailsButton.defaultProps = {
  notUsedVehicle: false,
  status: null
}

export default VehicleDetailsButton
