import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import i18n from '../../util/localization'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import style from './ReportPicker.scss'

const now = moment().toDate()
const twoYearsAgo = moment().subtract(2, 'years').startOf('year').format('YYYY-MM-DD')

const initialStateRangeFormValues = (startDate, endDate) => {
  const formattedStartDate = moment(startDate.replace('Z', '')).format(
    'YYYY-MM-DD'
  )
  const formattedEndDate = endDate
    ? moment(endDate.replace('Z', '')).format(
      'YYYY-MM-DD'
    )
    : moment(now).format('YYYY-MM-DD')

  return {
    startDate: formattedStartDate,
    endDate: formattedEndDate,
    startTime: moment(startDate.replace('Z', '')).format('HH:mm'),
    endTime: endDate ? moment(endDate.replace('Z', '')).format('HH:mm') : moment(now).format('HH:mm')
  }
}

const makeDateTime = (date, time) => moment(`${date} ${time}`).format('YYYY-MM-DD HH:mm:ss')

const CustomRangePicker = ({
  periodStart,
  periodEnd,
  handleDateChange,
  propulsionType,
  toggleDropdown
}) => {
  const dateRangeSchema = Yup.object().shape({
    startDate: Yup.date()
      .required(i18n.t('EttStartdatumKrävs'))
      .min(twoYearsAgo, i18n.t('AngeEttVärdeMellan_0_Och_1_').replace('{1}', twoYearsAgo).replace('{2}', i18n.t('Idag').toLowerCase()))
      .max(moment(now).format('YYYY-MM-DD'), i18n.t('DatumetKanInteInfallaIFramtiden')),
    endDate: Yup.date()
      .required(i18n.t('EttSlutdatumKrävs'))
      .min(Yup.ref('startDate'), i18n.t('SlutdatumMåsteVaraPå_'))
      .max(moment(now).format('YYYY-MM-DD'), i18n.t('DatumetKanInteInfallaIFramtiden')),
    startTime: Yup.string(),
    endTime: Yup.string().test(
      'to-after-from',
      i18n.t('SluttidMåsteVaraSenareÄnStarttid'),
      function (value) {
        const end = moment(this.parent.endDate).add(
          moment.duration(value)
        )
        const start = moment(this.parent.startDate).add(
          moment.duration(this.parent.startTime)
        )

        return end.isAfter(start)
      }
    )
  })

  return (
    <Formik
      initialValues={initialStateRangeFormValues(periodStart, periodEnd)}
      enableReinitialize
      validationSchema={dateRangeSchema}
      validateOnMount
    >
      {({ values, isValid, isSubmitting, errors }) => (
        <>
          <div
            className={`${style.dateTimeContainer}`}
            onBlur={() =>
              handleDateChange({
                startDate: makeDateTime(values.startDate, values.startTime)
              })
            }
          >
            <h5>{i18n.t('AngeStartdatum')}:</h5>
            <div>
              <Field
                type="date"
                name="startDate"
                min={twoYearsAgo}
                max={moment(now).format('YYYY-MM-DD')}
                className={`form-control ${style.dateInput}`}
              />
              <div className={style.dateError}>{errors.startDate}</div>
            </div>
            <div>
              <Field
                type="time"
                name="startTime"
                className={`form-control ${style.timeInput}`}
              />
              <div className={style.timeError}>{errors.startTime}</div>
            </div>
          </div>
          <div
            className={style.dateTimeContainer}
            onBlur={() =>
              handleDateChange({
                endDate: makeDateTime(values.endDate, values.endTime)
              })
            }
          >
            <h5>{i18n.t('AngeSlutdatum')}:</h5>
            <div>
              <Field
                type="date"
                name="endDate"
                min={values.startDate}
                max={moment(now).format('YYYY-MM-DD')}
                className={`form-control ${style.dateInput}`}
              />
              <div className={style.dateError}>{errors.endDate}</div>
            </div>
            <div>
              <Field
                type="time"
                name="endTime"
                min={values.startTime}
                className={`form-control ${style.timeInput}`}
              />
              <div className={style.timeError}>{errors.endTime}</div>
            </div>
          </div>
          <div
                  className={`row ${style['report-picker-row']} ${style['report-picker-submit-row']}`}
                >
                  <div className="col-xs-12">
                    <Link
                      to={`/range/${makeDateTime(values.startDate, values.startTime).replace(
                              ' ',
                              'T'
                            )}Z/${makeDateTime(values.endDate, values.endTime).replace(' ', 'T')}Z/${
                              propulsionType
                            }`
                      }
                      onClick={
                        !isValid
                          ? (e) => {
                              e.preventDefault()
                            }
                          : toggleDropdown
                      }
                    >
                      <button
                        className={`btn btn-success btn-lg btn-block visible-xs-block visible-sm-block ${isSubmitting || !isValid ? 'disabled' : ''}`}
                      >
                        {i18n.t('Visa')}
                      </button>
                      <button
                        className={`btn btn-success pull-right dropdown-toggle hidden-sm hidden-xs ${isSubmitting || !isValid ? 'disabled' : ''}`}
                      >
                        {i18n.t('Visa')}
                      </button>
                    </Link>
                  </div>
                </div>
        </>
      )}
    </Formik>
  )
}

CustomRangePicker.propTypes = {
  handleDateChange: PropTypes.func.isRequired,
  periodStart: PropTypes.string.isRequired,
  periodEnd: PropTypes.string.isRequired,
  propulsionType: PropTypes.string.isRequired,
  toggleDropdown: PropTypes.func.isRequired
}

export default CustomRangePicker
