import * as types from '../actionTypes'

const initialState = {
  plans: {},
  loading: false,
  failed: false,
  loaded: false,
  tryNumber: 0
}

const servicePlans = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SERVICE_PLANING_REQUEST: {
      return Object.assign({}, state, {
        plans: {},
        loading: true,
        failed: false,
        loaded: false
      })
    }
    case types.FETCH_SERVICE_PLANING_SUCCESS: {
      return Object.assign({}, state, {
        plans: action.result,
        loading: false,
        failed: false,
        loaded: true
      })
    }
    case types.FETCH_SERVICE_PLANING_FAILURE: {
      return Object.assign({}, state, {
        plans: {},
        loading: false,
        failed: true,
        loaded: false,
        tryNumber: state.tryNumber + 1
      })
    }

    case types.LOGOUT_SUCCESS:
      return initialState
    case types.LOGIN_SUCCESS:
      return initialState
    default:
      return state
  }
}

export default servicePlans
