import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import angleDown from '../../assets/angleDown.svg'
import style from './ReportPicker.scss'
import i18n from '../../util/localization'
import ReportsSchema from '../../util/ReportsSchema/ReportsSchema'
import userSelectors from '../../state/user/selectors'

const getPeriodTypeKey = (periodType) => {
  switch (periodType) {
    case ('week'): return 'Vecka'
    case ('month'): return 'Månad'
    case ('year'): return 'År'
    case ('range'): return 'Eget'
    default: return 'Vecka'
  }
}

const createPeriod = (periodType, periodStart, periodEnd) => {
  switch (periodType) {
    case ('week'): {
      const from = moment(periodStart)
      return moment(from).format('L')
    }
    case ('month'): {
      const from = moment(periodStart)
      return from.format('YYYY MMM')
    }
    case ('year'): {
      const from = moment(periodStart)
      return from.format('YYYY')
    }
    case ('range'): {
      const from = moment(periodStart)
      const until = moment(periodEnd)
      return moment(from).format('L') + ' - ' + moment(until).format('L')
    }
    default: {
      const from = moment(periodStart)
      return moment(from).format('L')
    }
  }
}

const ReportPickerButton = ({
  periodType,
  periodStart,
  periodEnd,
  onClick,
  propulsionType,
  disabled
}) => {
  const propulsionTypeText = ReportsSchema.get(propulsionType).pickerText

  return (
    <button
      id="reportPickerButton"
      className={`btn btn-default btn-lg ${style['picker-dropdown']} ${disabled ? 'disabled' : ''}`}
      onClick={onClick}
    >
      <span className={style['report-picker-header-label']}>
        <h5>
          {createPeriod(periodType, periodStart, periodEnd)}
          <small>{i18n.t(getPeriodTypeKey(periodType))}</small>
        </h5>
      </span>
      <img className={style['picker-dropdown-button-icon']} src={angleDown} alt="" />
      <span className="badge label-sm">{propulsionTypeText}</span>
    </button>
  )
}

ReportPickerButton.propTypes = {
  periodStart: PropTypes.string.isRequired,
  periodEnd: PropTypes.string,
  periodType: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  propulsionType: PropTypes.string.isRequired,
  isUserLoaded: PropTypes.bool.isRequired
}

ReportPickerButton.defaultProps = {
  onClick: () => {}
}

const mapStateToProps = state => ({
  isUserLoaded: userSelectors.hasLoadedUser(state)
})

export default connect(mapStateToProps)(ReportPickerButton)
