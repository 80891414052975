/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import truck from '../../assets/Truck_in_Circle_with_badge.svg'

import GAEvent from '../GAEvent'
import i18n from '../../util/localization'
import Style from './YNTPopup.scss'

const handleClick = (yntId: string = '', ReactGA: any) => {
  ReactGA.event({
    category: 'YNT',
    action: 'Click',
    label: `${yntId}`,
    value: 0
  })
}

const yntMessagePopover = ({ yntVehicleDetails, ReactGA, ...props }: any) => {
  return (
    <Popover
      className={`scania-popover ${Style.YNTpopoverContainer}`}
      id={'yntOffer' + yntVehicleDetails?.yntId?.replace('-', '')}
      {...props}
    >
      <GAEvent
        category="YNT"
        action="Open"
        label={yntVehicleDetails?.yntId}
        value={0}
        ReactGA={ReactGA}
      />
      <div className={Style.infoContainer}>
        {
        i18n.t('WeHaveAProposal')
        }
        <span>
          <a
            onClick={() => handleClick(yntVehicleDetails?.yntId, ReactGA)} href={yntVehicleDetails?.yntUrl || '#'}
            target='_blank'>
              {
                i18n.t('GoToYourNextTruck')
              }
          </a>
        </span>
      </div>
    </Popover>
  )
}

const YNTPopup = ({ details, ReactGA }: any) => {
  return (
    <div>
      <OverlayTrigger
        trigger="click"
        placement="right"
        overlay={yntMessagePopover({
          yntVehicleDetails: details.yntDetail,
          ReactGA
        })}>
          <img className={Style.truckIcon} src={truck} alt="YNT" />
      </OverlayTrigger>
    </div>
  )
}

export default YNTPopup
