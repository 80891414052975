// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LaunchModal_launch-modal-Yxije.modal-dialog{width:100% !important}.LaunchModal_launch-modal-Yxije.modal-dialog .modal-header{background-color:#f6f9fa;border-bottom:0px;text-align:right}.LaunchModal_launch-modal-Yxije.modal-dialog .modal-header .close{opacity:1}.LaunchModal_launch-modal-Yxije.modal-dialog .modal-header .close span{color:black}.LaunchModal_launch-modal-Yxije.modal-dialog .modal-footer{border-top:0px;margin-bottom:8px;text-align:center}.LaunchModal_launch-modal-Yxije.modal-dialog .modal-body{padding-top:0px}\n", "",{"version":3,"sources":["webpack://./src/components/LaunchModal.scss"],"names":[],"mappings":"AAGA,6CAEQ,qBAAsB,CAF9B,2DAIY,wBALgB,CAMhB,iBAAkB,CAClB,gBAAiB,CAN7B,kEAQgB,SAAU,CAR1B,uEAUoB,WAAY,CAVhC,2DAeY,cAAe,CACf,iBAAkB,CAClB,iBAAkB,CAjB9B,yDAoBY,eAAgB","sourcesContent":["@import \"../style/fonts\";\n@import \"../style/colors\";\n$modal-header-color: #f6f9fa;\n.launch-modal {\n    &:global(.modal-dialog) {\n        width: 100% !important;\n        :global(.modal-header) {\n            background-color: $modal-header-color;\n            border-bottom: 0px;\n            text-align: right;\n            :global(.close) {\n                opacity: 1;\n                span {\n                    color: black;\n                }\n            }\n        }\n        :global(.modal-footer) {\n            border-top: 0px;\n            margin-bottom: 8px;\n            text-align: center;\n        }\n        :global(.modal-body) {\n            padding-top: 0px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"launch-modal": "LaunchModal_launch-modal-Yxije"
};
export default ___CSS_LOADER_EXPORT___;
