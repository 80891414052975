import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import Spinner from './Spinner'

function getPath (rest) {
  if (rest.location.state) {
    return rest.location.state.from.pathname
  }
  return '/'
}

const UnAuthenticatedRoute = ({
  authed, resolved, component: Component, restricted, ReactGA, ...rest
}) => {
  if (!resolved) {
    return <Route {...rest} render={() => <Spinner />} />
  }
  if (authed) {
    return (
      <Route
        {...rest}
        render={props => (
          <Redirect
            to={{ pathname: getPath(rest), state: { from: props.location } }}
          />
        )}
      />
    )
  }
  return (
    <Route
      {...rest}
      render={props => <Component authed={authed} resolved={resolved} ReactGA={ReactGA} {...props} />}
    />
  )
}

UnAuthenticatedRoute.propTypes = {
  authed: PropTypes.bool.isRequired,
  resolved: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
  location: PropTypes.shape(),
  restricted: PropTypes.bool.isRequired,
  ReactGA: PropTypes.shape().isRequired
}

UnAuthenticatedRoute.defaultProps = {
  location: {}
}

export default UnAuthenticatedRoute
