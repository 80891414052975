/* eslint-disable max-len */
import _ from 'lodash'
import moment from 'moment'
import XLSX from 'xlsx'
import i18n from '../localization'
import ReportsSchema from '../ReportsSchema/ReportsSchema'
import { unitToTranslationKey } from '../unitTranslationKeys'
import { createPeriod } from './reportExportUtils'
import { stripBraces } from '../dataManipulation'

const decimalCount = (stringParamValue: string) => stringParamValue?.split('.')[1]?.length

const formatValue = (param) =>
  param?.value === null || param?.value === undefined
    ? ''
    : isNaN(param?.value)
      ? param?.value
      : (Number.isInteger(Number(param.value)) || (!Number.isInteger(Number(param.value)) && decimalCount(String(param.value)) === 1))
          ? Number(param.value)
          : Number(Number(param.value).toFixed(2))

const extractValues = (obj, propulsionType, options?) => {
  const result = {}
  ReportsSchema.columnsOf(propulsionType, options).forEach((a) => {
    result[a.name] = formatValue(obj[a.name])
  })
  return result
}

const generateSummaryRow = (vehicleData: ReportData, paramsHeaders: string[], infoHeaders: string[]): object => {
  const summary = {}
  paramsHeaders.forEach((a) => {
    summary[a] = vehicleData.summary.average[a]
  })

  summary[infoHeaders[0]] = i18n.t('TF_FleetAverage')
  summary[infoHeaders[1]] = ''
  summary[infoHeaders[2]] = ''

  return summary
}

const generateDataSource = (vehicleData, propulsionType, options) => {
  const namesMap = {
    name: { title: 'Utrustning', unit: '' },
    periodStart: { title: 'TH_StartDate', unit: '' },
    periodStop: { title: 'TH_StopDate', unit: '' }
  }
  ReportsSchema.columnsOf(propulsionType, options).forEach(({ titleKey, name, params }) => {
    namesMap[name] = {
      title: titleKey,
      unit: vehicleData.units[name],
      params
    }
  })

  let result = vehicleData.equipments.map(a => ({
    ...a.info,
    ...extractValues({ ...a.parameters }, propulsionType, options)
  }))

  // Filter columns
  const blackList = ['externalEquipmentReference', 'scaniaDriverSupportScore2', 'emissionSpecDetails', 'chassisNumber', 'yntDetail']

  result = result.map((row) => {
    const rowCopy = { ...row }
    blackList.forEach((c) => {
      delete rowCopy[c]
    })
    rowCopy.periodStart = rowCopy.periodStart ? moment(rowCopy.periodStart).toDate() : ''
    rowCopy.periodStop = rowCopy.periodStop ? moment(rowCopy.periodStop).toDate() : ''
    return rowCopy
  })

  const paramsHeader = ReportsSchema.columnsOf(propulsionType, options).map(x => x.name)
  const infoHeaders = Object.keys(vehicleData.equipments[0].info).filter(x => !['chassisNumber', 'externalEquipmentReference', 'emissionSpecDetails', 'yntDetail'].includes(x))

  if (ENV_VAR_APPLICATION === 'MONITORING') {
    result = [generateSummaryRow(vehicleData, paramsHeader, infoHeaders), ...result]
  }

  const header = [
    ...infoHeaders,
    ...paramsHeader
  ]

  const headerTitles = [
    ...(infoHeaders.map(a => i18n.t(namesMap[a]?.title))),
    ...(paramsHeader.map((a) => {
      let translatedName = i18n.t(namesMap[a]?.title)
      _.forEach(namesMap[a].params, (value, index) => {
        translatedName = translatedName.replace(`{${index}}`, i18n.t(value))
      })
      return namesMap[a].unit ? `${translatedName} (${stripBraces(i18n.t(unitToTranslationKey(namesMap[a].unit)))})` : translatedName
    }))
  ]

  return { result, header, headerTitles }
}

const createExcelDataFromReport = (
  vehicleData: ReportData,
  customerName: string,
  periodType: PeriodType,
  periodStart: string,
  periodEnd: string,
  propulsionType: PropulsionType,
  options: ReportsSchemaOptions = {},
  XLSXService = XLSX
) => {
  const Heading = [
    [customerName],
    [createPeriod(periodType, periodStart, periodEnd)]
  ]

  const data = generateDataSource(vehicleData, propulsionType, options)

  Heading.push(data.headerTitles)

  const ws = XLSXService.utils.aoa_to_sheet(Heading)

  XLSXService.utils.sheet_add_json(ws, data.result, {
    header: data.header,
    skipHeader: true,
    origin: 3,
    cellDates: true
  })

  const wb = XLSXService.utils.book_new()

  XLSXService.utils.book_append_sheet(wb, ws, 'Report')

  const wbout = XLSXService.write(wb, { bookType: 'xlsx', type: 'array' })
  return wbout
}

export default createExcelDataFromReport
