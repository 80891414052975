import _ from 'lodash'
import * as types from '../actionTypes'

const initialState = {
  customerName: '',
  firstName: '',
  externalStaffReference: '',
  externalCustomerReference: '',
  lastName: '',
  startOfWeek: undefined,
  profile: {
    language: '',
    vehicleIdentifier: undefined,
    customerCountry: '',
    propulsionConsumptionUnit: undefined,
    gasConsumptionUnit: undefined,
    saving: false,
    failure: false
  },
  loading: false,
  failure: false
}

const defaultStartOfWeek = 'Monday'

const hashCode = (s) => {
  let h = 0; const l = s.length; let i = 0
  // eslint-disable-next-line no-bitwise, no-plusplus
  if (l > 0) { while (i < l) h = (h << 5) - h + s.charCodeAt(i++) | 0 }
  return h
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_USER_REQUEST:
      return Object.assign({}, state, {
        loading: true
      })
    case types.FETCH_USER_SUCCESS:
    {
      if (_.includes(['ES', 'FR', 'PT', 'IE', 'GB'], action.user.customerCountry)) {
        localStorage.setItem('x-customer-info',
          JSON.stringify({
            usageDate: new Date().toLocaleDateString(),
            hashCode: hashCode(action.user.customerName + action.user.email),
            country: action.user.customerCountry,
            email: action.user.email,
            scania: action.user.email.includes('@scania'),
            name: action.user.customerName
          }))
      }
      return Object.assign({}, state, {
        customerName: action.user.customerName,
        email: action.user.email,
        externalStaffReference: action.user.externalStaffReference,
        externalCustomerReference: action.user.externalCustomerReference,
        firstName: action.user.firstName,
        lastName: action.user.lastName,
        startOfWeek: action.user.startOfWeek || defaultStartOfWeek,
        profile: Object.assign({}, state.profile, {
          language: action.user.language,
          vehicleIdentifier: action.user.vehicleIdentifier,
          propulsionConsumptionUnit: action.user.propulsionConsumptionUnit,
          gasConsumptionUnit: action.user.gasConsumptionUnit,
          customerCountry: action.user.customerCountry

        }),
        loading: false,
        failure: false
      })
    }
    case types.FETCH_USER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        failure: true
      })
    case types.UPDATE_PROFILE_REQUEST:
      return Object.assign({}, state, {
        profile: Object.assign({}, state.profile, {
          saving: true,
          failure: false,
          propulsionConsumptionUnit: action.profile.propulsionConsumptionUnit ||
          state.profile.propulsionConsumptionUnit,
          gasConsumptionUnit: action.profile.gasConsumptionUnit ||
          state.profile.gasConsumptionUnit,
          language: action.profile.language || state.profile.language,
          vehicleIdentifier: action.profile.vehicleIdentifier || state.profile.vehicleIdentifier
        })
      })
    case types.UPDATE_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        profile: Object.assign({}, state.profile, {
          saving: false,
          failure: false
        })
      })
    case types.UPDATE_PROFILE_FAILURE:
      return Object.assign({}, state, {
        profile: Object.assign({}, state.profile, {
          saving: false,
          failure: true
        })
      })
    case types.LOGOUT_SUCCESS:
      return initialState
    case types.LOGIN_SUCCESS:
      return initialState
    default:
      return state
  }
}

export default user
