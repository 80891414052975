// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YNTPopup_YNTpopoverContainer-VBKyn{background-color:#37414F;color:white;font-size:12px;line-height:20px;font-weight:400;width:220px;border-radius:10px !important}.YNTPopup_YNTpopoverContainer-VBKyn a{color:white;text-decoration:underline;margin-left:4px}.popover.right .arrow{display:none !important}.YNTPopup_truckIcon-FFcGM{width:25px;filter:invert(25%) sepia(90%) saturate(1247%) hue-rotate(195deg) brightness(92%) contrast(89%);cursor:pointer;margin-top:-5px}\n", "",{"version":3,"sources":["webpack://./src/components/VehicleDataTable/YNTPopup.scss"],"names":[],"mappings":"AAAA,oCACE,wBAAyB,CACzB,WAAY,CACZ,cAAe,CACf,gBAAiB,CACjB,eAAgB,CAChB,WAAY,CACZ,6BAA8B,CAPhC,sCAUI,WAAY,CACZ,yBAA0B,CAC1B,eAAgB,CACjB,sBAMG,uBAAwB,CACzB,0BAKH,UAAW,CACX,8FAA+F,CAC/F,cAAe,CACf,eAAgB","sourcesContent":[".YNTpopoverContainer {\n  background-color: #37414F;\n  color: white;\n  font-size: 12px;\n  line-height: 20px;\n  font-weight: 400;\n  width: 220px;\n  border-radius: 10px !important;\n\n  a {\n    color: white;\n    text-decoration: underline;\n    margin-left: 4px;\n  }\n}\n\n:global(.popover) {\n  &:global(.right) {\n    :global(.arrow) {\n      display: none !important;\n    }\n  }\n}\n\n.truckIcon {\n  width: 25px;\n  filter: invert(25%) sepia(90%) saturate(1247%) hue-rotate(195deg) brightness(92%) contrast(89%);\n  cursor: pointer;\n  margin-top: -5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"YNTpopoverContainer": "YNTPopup_YNTpopoverContainer-VBKyn",
	"truckIcon": "YNTPopup_truckIcon-FFcGM"
};
export default ___CSS_LOADER_EXPORT___;
