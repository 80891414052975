// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VehicleDataTableHeaderColumnValue_headerName-BYNQd{font-family:\"Scania Sans Semi Condensed Bold\";font-weight:400;font-size:12px;text-transform:none;overflow:hidden;text-overflow:ellipsis;white-space:pre-line}.VehicleDataTableHeaderColumnValue_headerUnit-hpOgE{font-family:\"Scania Sans Semi Condensed\";font-weight:400;font-size:12px;text-transform:none;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}\n", "",{"version":3,"sources":["webpack://./src/components/VehicleDataTable/VehicleDataTableHeaderColumnValue.scss","webpack://./src/style/_fonts.scss"],"names":[],"mappings":"AAGA,oDACI,6CCD0D,CDE1D,eAAgB,CAChB,cAAe,CACf,mBAAoB,CACpB,eAAgB,CAChB,sBAAuB,CACvB,oBAAqB,CACxB,oDAGG,wCCZgD,CDahD,eAAgB,CAChB,cAAe,CACf,mBAAoB,CACpB,eAAgB,CAChB,sBAAuB,CACvB,kBAAmB","sourcesContent":["@import \"../../style/fonts\";\n@import \"../../style/sizes\";\n\n.headerName {\n    font-family: $scania-sans-semi-cond-bold;\n    font-weight: 400;\n    font-size: 12px;\n    text-transform: none;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: pre-line;\n}\n\n.headerUnit {\n    font-family: $scania-sans-semi-cond;\n    font-weight: 400;\n    font-size: 12px;\n    text-transform: none;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}\n\n/*\n@media all and (max-width: $small-desktop-width) {\n    .headerName {\n        max-width: 110px;\n    }\n    .headerUnit {\n        max-width: 110px;\n    }\n}\n\n@media all and (max-width: $mobile-width) {\n    .headerName {\n        max-width: 70px;\n    }\n    .headerUnit {\n        max-width: 70px;\n    }\n}*/","$scania-sans-cond: \"Scania Sans Condensed\";\n$scania-sans-cond-bold: \"Scania Sans Condensed Bold\";\n$scania-sans-semi-cond: \"Scania Sans Semi Condensed\";\n$scania-sans-semi-cond-bold: \"Scania Sans Semi Condensed Bold\";\n$scania-sans-headline: \"Scania sans Headline\""],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerName": "VehicleDataTableHeaderColumnValue_headerName-BYNQd",
	"headerUnit": "VehicleDataTableHeaderColumnValue_headerUnit-hpOgE"
};
export default ___CSS_LOADER_EXPORT___;
