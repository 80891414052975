const isLoadingUser = state => state.user.loading
const isFailureLoadingUser = state => state.user.failure
const hasLoadedUser = state => (state.user.customerName || '').length > 0
const getCustomerName = state => (state.user.customerName || '')
const isSavingProfile = state => state.user.profile.saving
const getPropulsionConsumptionUnit = state => state.user.profile.propulsionConsumptionUnit
const getGasConsumptionUnit = state => state.user.profile.gasConsumptionUnit
const getLanguage = state => (state.user.profile || {}).language
const getCustomerCountry = state => (state.user.profile || {}).customerCountry
const getVehicleIdentifier = state => state.user.profile.vehicleIdentifier
const getEmail = state => state.user.email
const getStartOfWeek = state => state.user.startOfWeek
const hasFailedSaving = state => state.user.profile.failure
const getUserName = state => (state.user.lastName ? `${state.user.firstName} ${state.user.lastName}` : state.user.firstName)
const getExternalStaffReference = state => state.user.externalStaffReference
const getProfileUnits = state => state.user.profile

export default {
  isLoadingUser,
  isFailureLoadingUser,
  hasLoadedUser,
  getCustomerName,
  isSavingProfile,
  getPropulsionConsumptionUnit,
  getGasConsumptionUnit,
  getLanguage,
  getCustomerCountry,
  getVehicleIdentifier,
  getEmail,
  getStartOfWeek,
  hasFailedSaving,
  getUserName,
  getExternalStaffReference,
  getProfileUnits
}
