// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ExportDownloadModal_modal-LACF9 h3{margin-bottom:20px}.ExportDownloadModal_modal-LACF9.modal-dialog{margin:25% auto}.ExportDownloadModal_modal-LACF9 .btn-link{color:#94999d}\n", "",{"version":3,"sources":["webpack://./src/components/Export/ExportDownloadModal.scss","webpack://./src/style/_colors.scss"],"names":[],"mappings":"AAGA,oCAEQ,kBAAmB,CAF3B,8CAMQ,eAAgB,CANxB,2CAUQ,aCZkB","sourcesContent":["@import \"../../style/colors\";\n@import \"../../style/fonts\";\n\n.modal {\n    h3 {\n        margin-bottom: 20px;\n    }\n\n    &:global(.modal-dialog) {\n        margin: 25% auto;\n    }\n\n    :global(.btn-link) {\n        color: $light-text-color;\n    }\n}","$background-color: #eeeff0;\n$light-text-color: #94999d;\n$light-border-color: #dadada;\n$dark-text-color: #242424;\n$blue-text-color: #041e42;\n$white-color: white;\n$light-blue-color: #f2f7f9;\n$primary-color: #033a6a;\n$success-color: #44a878;\n$warning-color: #f6a98e;\n$danger-color: #cc1040;\n$info-color: #4a8ba4;\n$user-icon: #0F3263;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "ExportDownloadModal_modal-LACF9"
};
export default ___CSS_LOADER_EXPORT___;
