import moment from 'moment'
import i18next from '../../util/localization'
import * as types from '../actionTypes'

function loadedLanguage (language) {
  return {
    type: types.LANGUAGE_LOADED,
    language
  }
}

function loadingLanguage () {
  return {
    type: types.LANGUAGE_LOADING
  }
}

export const initilize = () => (dispatch) => {
  dispatch(loadingLanguage())
  i18next.on('initialized', () => {
    dispatch(loadedLanguage(i18next.language))
    moment.locale(i18next.language)
  })

  // After adding the loading component that waits for keycloak this is needed - index.js
  if (i18next.isInitialized) {
    dispatch(loadedLanguage(i18next.language))
    moment.locale(i18next.language)
  }
}

export const change = lang => (dispatch) => {
  i18next.changeLanguage(lang, () => {
    moment.locale(i18next.language)
    dispatch(loadedLanguage(lang))
  })
}
