import i18next from 'i18next'
import XHR from 'i18next-xhr-backend'
import LngDetector from 'i18next-browser-languagedetector'

i18next
  .use(XHR)
  .use(LngDetector)
  .init({
    backend: {
      loadPath: '/localization/{{lng}}.json'
    },
    load: 'currentOnly',
    fallbackLng: ['en-GB']
  }, (err) => {
    /* eslint-disable */
    if (err) return console.log(err);
    return
    /* eslint-enable */
  })
export default i18next
