import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle as info } from '@fortawesome/free-solid-svg-icons/faInfoCircle'

import i18n from '../util/localization'

const EmptyReport = () => (
  <div className="page-header text-center" style={{ height: '600px' }}>
    <FontAwesomeIcon
      size="4x"
      icon={info}
    />
    <h2 className="text-center" style={{ fontSize: '3.4rem' }}>{i18n.t('IngenDataAttVisa')}</h2>
    <h3 className="text-center"><small style={{ fontSize: '3rem' }}>{i18n.t('VäljEnAnnanPeriod_')}</small>
    </h3>
  </div>

)

export default EmptyReport
