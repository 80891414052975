// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VehicleNameColumn_wrench-oTWDf{margin-left:10px}.VehicleNameColumn_title-gGphE{margin-bottom:20px}.VehicleNameColumn_column-qhwDo{display:inline-table;cursor:pointer;border:1px solid transparent;white-space:nowrap}.VehicleNameColumn_column-qhwDo:hover{border-bottom:1px dotted #94999d}\n", "",{"version":3,"sources":["webpack://./src/components/VehicleDataTable/VehicleNameColumn.scss","webpack://./src/style/_colors.scss"],"names":[],"mappings":"AACA,gCACE,gBAAiB,CAClB,+BAEC,kBAAmB,CACpB,gCAGC,oBAAqB,CACrB,cAAe,CACf,4BAA6B,CAC7B,kBAAmB,CAJrB,sCAMI,gCCbsB","sourcesContent":["@import \"../../style/colors\";\n.wrench{\n  margin-left: 10px;\n}\n.title{\n  margin-bottom: 20px;\n}\n\n.column {\n  display: inline-table;\n  cursor: pointer;\n  border: 1px solid transparent;\n  white-space: nowrap;\n  &:hover {\n    border-bottom: 1px dotted $light-text-color;\n  }\n}\n","$background-color: #eeeff0;\n$light-text-color: #94999d;\n$light-border-color: #dadada;\n$dark-text-color: #242424;\n$blue-text-color: #041e42;\n$white-color: white;\n$light-blue-color: #f2f7f9;\n$primary-color: #033a6a;\n$success-color: #44a878;\n$warning-color: #f6a98e;\n$danger-color: #cc1040;\n$info-color: #4a8ba4;\n$user-icon: #0F3263;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrench": "VehicleNameColumn_wrench-oTWDf",
	"title": "VehicleNameColumn_title-gGphE",
	"column": "VehicleNameColumn_column-qhwDo"
};
export default ___CSS_LOADER_EXPORT___;
