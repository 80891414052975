// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ForgottenPasswordPopup_forgottenPasswordPanel-dDvfT{text-align:center;padding-left:40px;padding-right:40px}.ForgottenPasswordPopup_forgottenPasswordPanel-dDvfT h2,.ForgottenPasswordPopup_forgottenPasswordPanel-dDvfT div{text-align:center;margin-bottom:20px}.ForgottenPasswordPopup_forgottenPasswordPanel-dDvfT div{margin-top:20px}\n", "",{"version":3,"sources":["webpack://./src/components/ForgottenPasswordPopup.scss"],"names":[],"mappings":"AAEA,qDACI,iBAAkB,CAClB,iBAAkB,CAClB,kBAAmB,CAHvB,iHAKQ,iBAAkB,CAClB,kBAAmB,CAN3B,yDASQ,eAAgB","sourcesContent":["@import \"../style/sizes\";\n\n.forgottenPasswordPanel {\n    text-align: center;\n    padding-left: 40px;\n    padding-right: 40px;\n    h2, div{\n        text-align: center;\n        margin-bottom: 20px;\n    }\n    div{\n        margin-top: 20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"forgottenPasswordPanel": "ForgottenPasswordPopup_forgottenPasswordPanel-dDvfT"
};
export default ___CSS_LOADER_EXPORT___;
