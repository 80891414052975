import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight as arrow } from '@fortawesome/free-solid-svg-icons/faArrowCircleRight'
import PropTypes from 'prop-types'
import React from 'react'
import Style from './ServicePlanningTable.scss'
import i18n from '../../util/localization'
import { StatusLabel } from './ServicePlanningIcon'
import FmpOrMyScaniaLink from '../FmpOrMyScaniaLink'

const renderForDesktopAndMobile =
  (vehicleName, servicePlans) => ([
    <div key="desktopDiv" className="service-planning-table hidden-xs hidden-sm">
      <h4 key="spheader" className="secret_text">{i18n.t('Serviceplanering_0_').replace('{1}', vehicleName)}</h4>
      <table key="sptable" className={Style.spTable}>
        <thead>
          <tr className={Style.header}>
            <td>{i18n.t('Serviceaktivitet')}</td>
            <td style={{ width: 130 }}>{i18n.t('PlaneratDatum')}</td>
            <td style={{ width: 100, textAlign: 'center' }}>{i18n.t('Status')}</td>
          </tr>
        </thead>
        <tbody>
          {servicePlans.items.map(a =>
            (
              <tr key={a.key}>
                <td>{a.header}</td>
                <td>{a.date}</td>
                <td><StatusLabel status={a.status} /></td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>,
    <div key="mobileDiv" className="visible-xs-block visible-sm-block">
      <h4 key="spheader" className="secret_text">{vehicleName}</h4>
      <h4 key="spsubheader">{i18n.t('Serviceplanering')}</h4>
      <hr />
      {servicePlans.items.map(a =>
        (
          <div key={a.key}>
            <div>
              <div className={Style.headerName}>{i18n.t('Serviceaktivitet')} : </div>
              <div className={Style.headerUnit}>{a.header}</div>
              <div className={Style.headerName}>{i18n.t('PlaneratDatum')} : </div>
              <div className={Style.headerUnit}>{a.date} <StatusLabel status={a.status} /></div>
            </div>
            <hr />
          </div>
        ))}
    </div>
  ])

const ServicePlanningTable = ({ vehicleName, servicePlans, externalEquipmentReference, ReactGA }) => {
  if (servicePlans.items.length === 0) {
    return null
  }
  // TODO this call to ReactGA.event is flawed. No control over how often it is triggered.
  ReactGA.event({
    category: 'click',
    action: 'open service-planing popup',
    label: servicePlans.status
  })
  const baseUrl = ENV_VAR_MY_SCANIA_ADMINISTRATION_BASE_URL
  const linkElement =
    <a
      key="splink"
      role="button"
      href={`${baseUrl}/${ENV_VAR_EQUIPMENT_PATH}/${externalEquipmentReference}`}
      target="_blank"
      className={Style.linkToSP} rel="noreferrer"
    >
      <FontAwesomeIcon icon={arrow} /> {i18n.t('Serviceplanering')}
    </a>
  return (
    <div>
      {renderForDesktopAndMobile(vehicleName, servicePlans)}
      <FmpOrMyScaniaLink
        fmp={linkElement}
        myScania={linkElement}
        ReactGA={ReactGA}
      />
    </div>
  )
}

ServicePlanningTable.propTypes = {
  externalEquipmentReference: PropTypes.string,
  vehicleName: PropTypes.string,
  servicePlans: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    status: PropTypes.string
  }).isRequired,
  ReactGA: PropTypes.shape().isRequired
}

ServicePlanningTable.defaultProps = {
  vehicleName: '-',
  externalEquipmentReference: ''
}

export default ServicePlanningTable
