import decode from './decoder'

export default {
  isValid: (token) => {
    if (!token || token === null) {
      return false
    }
    try {
      if (decode(token).exp < Date.now() / 1000) {
        return false
      }
    } catch (error) {
      return false
    }
    return true
  }
}
