import * as types from '../actionTypes'

function tourLoaded () {
  return {
    type: types.TOUR_LOADED
  }
}

function tourFinished () {
  return {
    type: types.TOUR_FINISHED
  }
}

function tourRestarted () {
  return {
    type: types.TOUR_RESTARTED
  }
}

function tourStarted () {
  return {
    type: types.TOUR_STARTED
  }
}

function tourNeedToUpdate () {
  return {
    type: types.TOUR_NEEDS_TO_UPDATE
  }
}

export default {
  tourLoaded,
  tourFinished,
  tourRestarted,
  tourStarted,
  tourNeedToUpdate
}
