import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLazyGetFmpSSOLinkToTargetQuery } from '../api/SSO'

const FmpOrMyScaniaLink = ({ myScania, ReactGA }) => {
  const [result] = useLazyGetFmpSSOLinkToTargetQuery()
  const content = myScania

  useEffect(() => {
    const target = result.originalArgs?.target
    if (result.isSuccess) {
      ReactGA.outboundLink({ label: target || 'FMP with SSO' }, () => { window.location.href = result.data })
    }
    if (result.isError) {
      ReactGA.outboundLink({ label: target || 'FMP with failed SSO' }, () => { window.location.href = target })
    }
  }, [result])

  const handleClick = ev => {
    ev.preventDefault()
    ReactGA.outboundLink({ label: ev.target.href || 'My Scania' }, () => { window.location.href = ev.target.href })
  }

  const getLink = (linkElement) => {
    const childNodes = [linkElement.props.children]
    const href = linkElement.props.href || ENV_VAR_MY_SCANIA_LINK
    return React.cloneElement(linkElement, { href, onClick: handleClick }, childNodes)
  }

  return content.type === 'a'
    ? getLink(content)
    : React.Children.map(content.props.children, child => child.type === 'a' ? getLink(child) : child)
}

FmpOrMyScaniaLink.propTypes = {
  myScania: PropTypes.element.isRequired,
  ReactGA: PropTypes.shape().isRequired
}

export default FmpOrMyScaniaLink
