import React from 'react'
import PropTypes from 'prop-types'
import styles from './VehicleDataTableSummary.scss'
import i18n from '../../util/localization'
import VehicleDataColumn from './VehicleDataColumn'
import { useReportColumns } from '../../util/ReportsSchema/hooks'
import { converttoTime } from '../../util/dataManipulation'

const DataTableSummary = (periodAverage, columns, ReactGA) => (
  <thead className={`${`${styles['summary-header']}`}`}>
    <tr>
      <th>{i18n.t('TF_FleetAverage')}</th>
      <th colSpan="2" className="collapsable-md" />
      {
            columns.map(el => (
              <th key={el.name} className={el.xsScreen ? '' : 'collapsable-sm'}>
                <VehicleDataColumn
                  periodValue={el.showAsTime
                    ? converttoTime(periodAverage[el.name])
                    : periodAverage[el.name]}
                  ReactGA={ReactGA}
                />
              </th>
            ))
          }
    </tr>
  </thead>
)

const VehicleDataTableSummary = ({ periodAverage, propulsionType, ReactGA }) => {
  const reportColumns = useReportColumns(propulsionType)
  return DataTableSummary(periodAverage, reportColumns, ReactGA)
}

VehicleDataTableSummary.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  periodAverage: PropTypes.object.isRequired,
  ReactGA: PropTypes.shape().isRequired
}

export default VehicleDataTableSummary
