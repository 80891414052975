import _ from 'lodash'
import i18n from '../localization'
import ReportsSchema from '../ReportsSchema/ReportsSchema'
import { unitToTranslationKey } from '../unitTranslationKeys'
import { converttoTime, stripBraces } from '../dataManipulation'
import { createPeriod } from './reportExportUtils'

const valueToSignedString = (value): string => {
  if (value !== null) {
    if (value > 0.0) {
      return `+${value.toLocaleString(i18n.language)}`
    }
    return value.toLocaleString(i18n.language)
  }
  return '-'
}

const valueAndTrend = (param) => {
  const value = !param || param.value === null ? '-' : param.value.toLocaleString(i18n.language)
  if (!param || param.delta === null || param.delta === undefined) {
    return {
      value,
      trend: ''
    }
  }
  const direction = param.delta > 0 ? 'up' : 'down'
  const meaning = param.isGood ? 'positive' : 'negative'
  const trend = `${direction} ${meaning}`
  return {
    value,
    trend
  }
}

const valueAndTrendForTime = (param) => {
  const value = param.value === null ? '-' : param.value
  if (param.delta === null || param.delta === undefined) {
    return {
      value: converttoTime(value),
      trend: ''
    }
  }
  const direction = param.delta > 0 ? 'up' : 'down'
  const meaning = param.isGood ? 'positive' : 'negative'
  const trend = `${direction} ${meaning}`
  return {
    value: converttoTime(value),
    trend
  }
}

const KpiValueFunction = (data, kpi) => {
  switch (kpi.valueFunc) {
    case 'valueToSignedString':
      return valueToSignedString(_.get(data.summary, kpi.name).value)
    case 'toLocaleString':
      return _.get(data.summary, kpi.name).value.toLocaleString(i18n.language)
    default:
      break
  }
  return ''
}

const generateSummary = (vehicleData, columns): string[] => columns.map(col => {
  const value = _.get(vehicleData.summary.average, col.name)
  if (!value) { return '-' }
  if (col.showAsTime) {
    return converttoTime(value)
  }
  return value.toLocaleString(i18n.language)
})

const generateKpis = (vehicleData, schema: ReportsSchemaPropulsionType): object[] => [
  schema.kpi.map(k => ({
    explanatoryText: k.text,
    value: KpiValueFunction(vehicleData, k),
    unit: stripBraces(i18n.t(unitToTranslationKey(_.get(vehicleData.summary, k.name).unit)))
  })),
  _.filter(schema.kpi, k => k.sub).map(k => ({
    explanatoryText: k.sub.text,
    value: KpiValueFunction(vehicleData, k.sub),
    unit: stripBraces(i18n.t(unitToTranslationKey(_.get(vehicleData.summary, k.sub.name).unit)))
  }))
]

const generateHeader = (vehicleData: ReportData, columns): string[] => columns.map(col => {
  const unit = ReportsSchema.generateUnits(vehicleData.units, col)
  const unitToDisplay = stripBraces(i18n.t(unit))
  let translatedName = i18n.t(col.titleKey)
  _.forEach(col.params, (value, index) => {
    translatedName = translatedName.replace(`{${index}}`, i18n.t(value))
  })
  return translatedName + (unitToDisplay?.length ? ' (' + unitToDisplay + ')' : '')
})

const generateReport = (vehicleData: ReportData, customerName: string, periodType: PeriodType, schema: ReportsSchemaPropulsionType, reportColumns: any[], periodStart: string, periodEnd: string): object => {
  const equipment = vehicleData.equipments.map(item => [
    { value: item.info.name, trend: '' },
    ...reportColumns.map((col) => {
      if (col.showAsTime) {
        return valueAndTrendForTime(_.get(item.parameters, col.name))
      }
      return valueAndTrend(_.get(item.parameters, col.name))
    })
  ])

  let result: object = {
    company: customerName,
    title: ENV_VAR_APPLICATION === 'MONITORING' ? i18n.t('Uppföljningsrapport') : i18n.t('Miljörapport'),
    subTitle: createPeriod(periodType, periodStart, periodEnd),
    headers: [
      i18n.t('Utrustning'),
      ...generateHeader(vehicleData, reportColumns)
    ],
    equipment
  }
  if (ENV_VAR_APPLICATION === 'MONITORING') {
    result = {
      ...result,
      kpiHeaders: schema.kpi.map(a => a.header),
      summary: [
        i18n.t('TF_FleetAverage'),
        ...generateSummary(vehicleData, reportColumns)
      ],
      kpi: generateKpis(vehicleData, schema)
    }
  }

  return result
}

export const createReport: CreatePdfReport = (vehicleData: ReportData, customerName,
  periodType, periodStart, periodEnd, schema, columns) => {
  const result = generateReport(
    vehicleData,
    customerName,
    periodType,
    schema,
    columns,
    periodStart,
    periodEnd
  )
  return result
}
