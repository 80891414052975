/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import StickyHeader from '../../util/stickyHeader'
import lightTableStyles from '../../style/lightTable.scss'
import VehicleDataTableHeader from './VehicleDataTableHeader'
import VehicleDataTableSummary from './VehicleDataTableSummary'
import VehicleDataTableRow from './VehicleDataTableRow'
import Style from './VehicleDataTable.scss'
import { sortByValue } from './VehicleDataTableSorter'
import { useReportColumns } from '../../util/ReportsSchema/hooks'
import Delayed from '../Delayed'
import { sortVehicleDataTable } from '../../state/report/actions'
import { getVehicleDataTableOrder } from '../../state/report/selectors'
import i18n from '../../util/localization'
import truck from '../../assets/Truck_in_Circle_with_badge.svg'
import info from '../../assets/info.svg'
import { useIsFeatureEnabled } from '../FeatureToggle/hooks'

const reportNumberOfEquipments = (numberOfEquipments, ReactGA) => {
  ReactGA.event({
    category: 'equipments',
    action: 'numberOfEquipments',
    value: numberOfEquipments,
    nonInteraction: true
  })
}

const VehicleDataTable = React.memo(function VehicleDataTable (props) {
  const reportColumns = useReportColumns(props.propulsionType)
  const dispatch = useDispatch()
  const { order, orderColumnIndex } = useSelector(getVehicleDataTableOrder)
  const tableRef = useRef(null)
  const [firstYntOfferIndex, setfirstYntOfferIndex] = useState(-1)
  const YNTPopupToggle = useIsFeatureEnabled('VPYNTProposalsAccess')

  const updateFocusedElement = (rowIndex) => {
    setfirstYntOfferIndex(rowIndex)
  }

  const scrollToOffer = () => {
    document.getElementById('rowVehicle' + firstYntOfferIndex)?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  const equipments = sortByValue(props.data.equipments, orderColumnIndex, order, reportColumns)
  const hasYntOffer = (element) => element.info?.yntDetail?.yntId

  useEffect(() => {
    const stickyHeader = StickyHeader(tableRef.current, { headerHeight: 64 })
    return () => stickyHeader.clean()
  }, [tableRef])

  useEffect(() => {
    const currentYntOfferIndex = equipments.findIndex(hasYntOffer)
    if (currentYntOfferIndex !== undefined) {
      updateFocusedElement(currentYntOfferIndex)
    }
    reportNumberOfEquipments(equipments.length, props.ReactGA)
  }, [equipments])

  return (
    <div className={`${lightTableStyles.responsiveTable}`}>
      { firstYntOfferIndex !== -1 && YNTPopupToggle &&
        <div className={Style.bannerContainer}>
          <div className={Style.bannerSubcontainer}>
            <div className={`${Style.linkedBanner} sdds-banner`} onClick={scrollToOffer}>
                <span className="sdds-banner-prefix">
                  <img className={Style.infoIcon} src={info} alt="YNT info" />
                </span>
                <div className="sdds-banner-body">
                  <div className={`${Style.bannerHeader} sdds-banner-header`}>{i18n.t('TimeToBuy')}</div>
                  <div className={`${Style.bannerSubheader} sdds-banner-subheader`}>
                    {i18n.t('ClickTheTruckIcon').replace(' {1}', '')}
                    <img className={Style.truckIcon} src={truck} alt="YNT" />
                  </div>
                </div>
            </div><br/>
          </div>
        </div>
      }
      <table ref={tableRef} className={`${lightTableStyles.lightTable} table table-striped`}>
        <VehicleDataTableHeader
          propulsionType={props.propulsionType}
          units={props.data.units}
          onHeaderClick={(columnIndex, order) => dispatch(sortVehicleDataTable(columnIndex, order))}
          ReactGA={props.ReactGA}
        />
        {props.data.summary &&
          <VehicleDataTableSummary
            propulsionType={props.propulsionType}
            periodAverage={props.data.summary.average}
            ReactGA={props.ReactGA}
          />
        }
        <tbody>
          { equipments.slice(0, 12).map((item, index) => <VehicleDataTableRow
                reportColumns={reportColumns}
                item={item}
                propulsionType={props.propulsionType}
                servicePlans={props.servicePlans}
                key={item.info.externalEquipmentReference}
                previousPeriod={props.previousPeriod}
                units={props.data.units}
                userUnit={props.userUnit}
                ReactGA={props.ReactGA}
                componentId={'rowVehicle' + index}
              />
          )}
          <Delayed waitBeforeShow={0}>
              { equipments.slice(12).map((item, index) => <Delayed key={item.info.externalEquipmentReference} waitBeforeShow={0}>
                  <VehicleDataTableRow
                    reportColumns={reportColumns}
                    item={item}
                    propulsionType={props.propulsionType}
                    servicePlans={props.servicePlans}
                    previousPeriod={props.previousPeriod}
                    units={props.data.units}
                    userUnit={props.userUnit}
                    ReactGA={props.ReactGA}
                    componentId={'rowVehicle' + (index + 12)}
                    />
                </Delayed>
              )}
            </Delayed>
        </tbody>
      </table>
    </div>
  )
})

VehicleDataTable.propTypes = {
  servicePlans: PropTypes.shape({
    maintenanceactivity: PropTypes.arrayOf(PropTypes.shape({})),
    serviceactivity: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  data: PropTypes.shape({
    summary: PropTypes.shape({
      average: PropTypes.shape({})
    }),
    equipments: PropTypes.arrayOf(PropTypes.shape({})),
    units: PropTypes.shape({})
  }).isRequired,
  previousPeriod: PropTypes.string,
  userUnit: PropTypes.string.isRequired,
  propulsionType: PropTypes.string.isRequired,
  ReactGA: PropTypes.shape().isRequired
}

VehicleDataTable.defaultProps = {
  previousPeriod: '/'
}

export default VehicleDataTable
