export const getSpecificCookie = (cookieName: string) => {
  return document.cookie?.replace(/; /gi, ';').split(';')?.find(row => row.startsWith(cookieName + '='))?.split('=')[1] || null
}

export const DUMMY_GA_PACKAGE = {
  event: () => {},
  outboundLink: (x, callback) => { callback() },
  set: () => {},
  pageview: () => {},
  initialize: () => {}
}
