import * as types from '../actionTypes'

const initialState = {
  authorized: true
}

const authorized = (state = initialState, action) => {
  const { error } = action
  if (error && error.message === 'Forbidden' && state.authorized) {
    return Object.assign({}, state, {
      authorized: false
    })
  }
  if (action.type === types.ACCEPT_FORBIDDEN) {
    return Object.assign({}, state, {
      authorized: true
    })
  }
  if (action.type === types.LOGIN_SUCCESS) {
    return Object.assign({}, state, {
      authorized: true
    })
  }
  return state
}

export default authorized
