import Keycloak from 'keycloak-js'

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = new Keycloak(
  {
    url: ENV_VAR_KC_ISSUER,
    realm: ENV_VAR_KC_REALM,
    clientId: ENV_VAR_APPLICATION === 'MONITORING' ? ENV_VAR_KC_CLIENT_ID_MONITORING : ENV_VAR_KC_CLIENT_ID_ENVIRONMENTAL
  }
)

export default keycloak
