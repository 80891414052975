import * as types from '../actionTypes'

const initialState = {
  isMonitoringReportRecipient: undefined,
  isServicePlanningReportRecipient: undefined,
  loading: false,
  failedLoading: false,
  saving: false,
  failedSaving: false
}

const subscription = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SUBSCRIPTION_REQUEST:
      return Object.assign({}, state, {
        loading: true
      })
    case types.FETCH_SUBSCRIPTION_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        failedLoading: false,
        isMonitoringReportRecipient: action.subscription.isMonitoringReportRecipient,
        isServicePlanningReportRecipient: action.subscription.isServicePlanningReportRecipient
      })
    case types.FETCH_SUBSCRIPTION_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        failedLoading: true
      })
    case types.UPDATE_SUBSCRIPTION_REQUEST:
      return Object.assign({}, state, {
        saving: true
      }, action.subscription)
    case types.UPDATE_SUBSCRIPTION_SUCCESS:
      return Object.assign({}, state, {
        saving: false,
        failedSaving: false
      })
    case types.UPDATE_SUBSCRIPTION_FAILURE: {
      const reversed = Object.keys(action.subscription).reduce((acc, key) => {
        acc[key] = !action.subscription[key]; return acc
      }, {})
      return Object.assign({}, state, {
        saving: false,
        failedSaving: true
      }, reversed)
    }
    case types.LOGOUT_SUCCESS:
      return initialState
    case types.LOGIN_SUCCESS:
      return initialState
    default:
      return state
  }
}

export default subscription
