import React from 'react'
import PropTypes from 'prop-types'
import style from './Banner.scss'
import i18n from '../util/localization'
import bannerDark from '../assets/bannerDark.png'
import ukBanner from '../assets/Positioning_Banner.png'
// import frenchBanner from '../assets/Scania-Display_Campagne_Tachy-970x250-20221108-2.gif'
import scaniaSymbol from '../assets/scaniaSymbol.svg'

const bannerMessage = [
  {
    language: 'sv-SE',
    content: 'Vill du optimera din drifttid och produktivitet?',
    button: 'Klicka här',
    target: 'https://www.scania.com/se/sv/home/services/data-driven-services/fleet-management.html'
  },
  {
    language: 'pl-PL',
    content: 'Chcesz zmaksymalizować czas pracy i wydajność?',
    button: 'Kliknij tutaj',
    target: 'https://www.scania.com/pl/pl/home/services/data-driven-services/fleet-management.html'
  }/* ,
  {
    language: 'fr-FR',
    content: '',
    button: '',
    target: 'https://www.scania.com/fr/fr/home/services/data-driven-services/scania-driver-evaluation.html'
  } */
]

const sendAnalyticsEvent = (props) => {
  props.ReactGA.event({
    category: 'banner',
    action: 'click',
    label: i18n.language
  })
}

class Banner extends React.Component {
  constructor (props) {
    super(props)
    this.content = this.content.bind(this)
    this.html = this.html.bind(this)
    this.navigate = this.navigate.bind(this)
    this.isFrance = (i18n.language === 'fr-FR')
    this.isUKBannerEnabled = props?.features?.VPUKRealtimeTrackingBanner || false
  }

  navigate (target) {
    sendAnalyticsEvent(this.props)
    window.open(target)
  }

  /* eslint class-methods-use-this: "off" */
  html (message, cssClassName, responsiveClass) {
    // const banner = this.isUKBannerEnabled ? ukBanner : this.isFrance ? frenchBanner : bannerDark
    const banner = this.isUKBannerEnabled ? ukBanner : bannerDark
    const backgroundSize = (this.isFrance || this.isUKBannerEnabled) ? 'contain' : 'cover'
    return (
      <div
        key={message.language + responsiveClass}
        className={`${cssClassName} ${responsiveClass}`}
        role="button"
        tabIndex="0"
        onClick={() => this.navigate(message.target)}
        onKeyDown={() => this.navigate(message.target)}
        style={{ backgroundImage: `url(${banner})`, backgroundSize }}
      >{
        (!this.isFrance && !this.isUKBannerEnabled) && <>
          <h1 className={style.title}>{message.content}</h1>
          <h1 className={style.link}>{message.button}</h1>
          <img src={scaniaSymbol} alt="" className={style.logo} />
        </>
      }
      </div>
    )
  }

  content (message) {
    const cont = [this.html(message, style.mobileContainer, (this.isFrance || this.isUKBannerEnabled) ? '' : 'hidden-md hidden-lg')]
    if (!this.isFrance && !this.isUKBannerEnabled) {
      cont.push(this.html(message, style.desktopContainer, 'hidden-sm hidden-xs'))
    }
    return cont
  }

  render () {
    let message = []
    if (this.isUKBannerEnabled) {
      message.push({
        language: 'en-GB',
        content: '',
        target: 'https://www.scania.com/uk/en/home/services/connected-services/control-package/fleet-position.html'
      })
    } else {
      message = bannerMessage.filter(a => a.language === i18n.language)
    }

    return message.length ? this.content(message[0]) : null
  }
}

Banner.propTypes = {
  features: PropTypes.shape({ VPUKRealtimeTrackingBanner: PropTypes.bool }),
  ReactGA: PropTypes.any
}

export default Banner
