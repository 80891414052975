import React from 'react'
import PropTypes from 'prop-types'

const VehicleDetailsDot = ({ left, right }) => (
  <span>
    <span className={`dot-tab ${left ? 'dot-tab-active' : ''}`} />
    <span className={`dot-tab ${right ? 'dot-tab-active' : ''}`} />
  </span>
)

VehicleDetailsDot.propTypes = {
  left: PropTypes.bool,
  right: PropTypes.bool
}

VehicleDetailsDot.defaultProps = {
  left: true,
  right: false
}

export default VehicleDetailsDot
