/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
import _ from 'lodash'
import moment from 'moment'
import i18n from '../../util/localization'

const automaticHeaderFilter = (activities, userUnit) => {
  let header = ''
  if (!_.isEmpty(activities)) {
    const activitiesRow = _.isArray(activities) ? activities[0] : activities
    const details = activitiesRow.serviceActivityDetails
    if (details && !_.isEmpty(details) && details[0].type === 'Automatic') {
      const data = details[0].data
      let mile = data
      let km = data
      if (Number(data)) {
        mile = (parseInt(data, 10) * 0.000621371).toFixed(0)
        km = (parseInt(data, 10) / 1000).toFixed(0)
      }
      if (userUnit === 'milesperprop') {
        header = i18n.t('_0_MilesService').replace('{1}', mile)
      } else {
        header = i18n.t('_0_KmService').replace('{1}', km)
      }
    } else {
      header = activitiesRow.header
    }
  }
  return header
}

const bookingHeaderFilter = (item) => {
  if ((!_.isEmpty(item.bookingServiceActivity) || !_.isEmpty(item.bookingMacActivity)) && _.isEmpty(item.bookingDefects)) {
    return i18n.t('Underhåll')
  }
  if ((_.isEmpty(item.bookingServiceActivity) && _.isEmpty(item.bookingMacActivity)) && !_.isEmpty(item.bookingDefects)) {
    return i18n.t('Reparation')
  }
  if ((!_.isEmpty(item.bookingServiceActivity) || !_.isEmpty(item.bookingMacActivity)) && !_.isEmpty(item.bookingDefects)) {
    return i18n.t('ReparationOchUnderhåll')
  }
  return i18n.t('ReparationOchUnderhåll')
}

const filterServicePlaningData = (servicePlans, externalEquipmentReference, userUnit) => {
  let maintenanceList = []
  let serviceList = []
  let bookedList = []

  let excludeListFromRender = []

  if (!_.isEmpty(servicePlans.maintenanceActivity)) {
    maintenanceList = servicePlans.maintenanceActivity
      .filter(a => a.externalEquipmentReference === externalEquipmentReference)
      .filter(a => a.dueStatus !== 'Booked')
      .filter(a => a.bookingStatus !== 'Booked')
  }

  if (!_.isEmpty(servicePlans.serviceActivity)) {
    serviceList = servicePlans.serviceActivity
      .filter(a => a.externalEquipmentReference === externalEquipmentReference)
      .filter(a => a.dueStatus !== 'Booked')
      .filter(a => a.bookingStatus !== 'Booked')
  }

  if (!_.isEmpty(servicePlans.booking)) {
    bookedList = servicePlans.booking
      .filter(a => a.booking.externalEquipmentReference === externalEquipmentReference)

    excludeListFromRender = _.flattenDeep([
      ...servicePlans.booking.map(a => a.bookingMacActivity.map(b => b.itemKey)),
      ...servicePlans.booking.map(a => a.bookingServiceActivity.map(b => b.externalItemReference))])
  }

  let status = (bookedList.filter(a => a.booking.bookingStatus === 'Late').length > 0 ||
  maintenanceList.filter(a => a.dueStatus === 'Late').length > 0 ||
      serviceList.filter(a => a.dueStatus === 'Late').length > 0)
    ? 'Late'
    : ''
  if (status === '') {
    status = (bookedList.filter(a => a.booking.bookingStatus === 'Due').length > 0 ||
    maintenanceList.filter(a => a.dueStatus === 'Due').length > 0 ||
        serviceList.filter(a => a.dueStatus === 'Due').length > 0)
      ? 'Due'
      : ''
  }
  if (status === '') {
    status = (bookedList.filter(a => a.booking.bookingStatus === 'Booked').length > 0 ||
    maintenanceList.filter(a => a.dueStatus === 'Booked').length > 0 ||
        serviceList.filter(a => a.dueStatus === 'Booked').length > 0)
      ? 'Booked'
      : ''
  }

  const maintenanceItems = maintenanceList.filter(a => excludeListFromRender.indexOf(a.itemKey) < 0).map(item => (
    {
      key: item.itemKey,
      header: item.isFlexible ? i18n.t('FlexibeltUnderhåll') : item.header,
      date: moment(item.plannedDate).format('L'),
      status: item.dueStatus
    }))

  const serviceItems = serviceList.filter(a => excludeListFromRender.indexOf(a.serviceActivityReference) < 0).map((item) => {
    const header = automaticHeaderFilter(item, userUnit)
    return (
      {
        key: item.serviceActivityReference,
        header: !_.isEmpty(header) ? header : i18n.t('EjDefinierad'),
        date: moment(item.timePlanned).format('L'),
        status: item.dueStatus
      })
  })

  const bookedItems = bookedList.map(item => (
    {
      key: item.booking.externalReference,
      header: bookingHeaderFilter(item),
      date: `${moment(item.booking.timeBooked).format('L')} ${moment(item.booking.timeBooked).format('LT')}`,
      status: item.booking.bookingStatus
    }))

  const items = [...maintenanceItems, ...serviceItems, ...bookedItems]
  // items = _.orderBy(items, ['status'], ['desc']);

  return {
    status,
    items
  }
}

export default filterServicePlaningData
