import { useKeycloak } from '@react-keycloak/web'
import PropTypes from 'prop-types'

const LoginSSOContainer = ({ authed, resolved }) => {
  const { initialized, keycloak } = useKeycloak()

  if (!initialized) {
    return null
  }

  if (keycloak && !keycloak.authenticated && resolved && !authed) {
    keycloak.login({ redirectUri: window.location.origin + window.location.pathname })
  }

  return null
}

LoginSSOContainer.propTypes = {
  authed: PropTypes.bool.isRequired,
  resolved: PropTypes.bool.isRequired
}

export default LoginSSOContainer
