import * as types from '../actionTypes'

const initialState = {
  loading: false,
  resolved: false,
  authenticated: false,
  user: {
    extStaffRef: undefined,
    extCustRef: undefined,
    permissions: {
      restricted: false,
      user: [],
      functional: undefined
    }
  },
  selectableStaffs: [],
  failure: false,
  lockedAccount: false,
  badCredentials: false,
  expiredSession: false,
  breakOutOfRestricted: false
}

const resolvedInitialState = Object.assign({}, initialState, {
  resolved: true
})

const authentication = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        resolved: true,
        lockedAccount: false,
        badCredentials: false,
        failure: false,
        selectableStaffs: []
      })
    case types.LOGIN_SUCCESS:
      return Object.assign({}, state, {
        resolved: true,
        loading: false,
        authenticated: true,
        user: action.user,
        breakOutOfRestricted: false,
        lockedAccount: false,
        failure: false,
        expiredSession: false
      })
    case types.LOGIN_FAILURE:
      return Object.assign({}, resolvedInitialState, {
        failure: true,
        loading: false
      })
    case types.LOGIN_LOCKED_ACCOUNT:
      return Object.assign({}, state, {
        failure: false,
        lockedAccount: true,
        loading: false,
        selectableStaffs: []
      })
    case types.LOGIN_BAD_CREDENTIALS:
      return Object.assign({}, state, {
        failure: false,
        badCredentials: true,
        loading: false
      })
    case types.BAD_CREDENTIALS_RESOLVED:
      return Object.assign({}, state, {
        badCredentials: false
      })
    case types.LOGOUT_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        badCredentials: false,
        failure: false
      })
    case types.LOGOUT_SUCCESS:
      return Object.assign({}, resolvedInitialState, {
        authenticated: false
      })
    case types.UNAUTHORIZED:
      return resolvedInitialState
    case types.FOUND_EXISTING_AUTHENTICATION:
      return Object.assign({}, state, {
        loading: false,
        authenticated: true,
        resolved: true,
        user: action.user
      })
    case types.NO_EXISTING_AUTHENTICATION:
      return Object.assign({}, resolvedInitialState, {
        loading: false,
        authenticated: false
      })
    case types.LOGIN_SELECT_STAFF:
      return Object.assign({}, state, {
        resolved: true,
        failure: false,
        badCredentials: false,
        selectableStaffs: action.staffs
      })
    case types.LOGIN_CANCEL_SELECT_STAFF:
      return Object.assign({}, state, {
        failure: false,
        badCredentials: false,
        loading: false,
        selectableStaffs: []
      })
    case types.BREAK_OUT_OF_RESTRICTED:
      return Object.assign({}, state, {
        breakOutOfRestricted: true
      })
    case types.CANCEL_BREAK_OUT_OF_RESTRICTED:
      return Object.assign({}, state, {
        breakOutOfRestricted: false
      })
    case types.EXPIRED_EXISTING_AUTHENTICATION:
      return Object.assign({}, resolvedInitialState, {
        authenticated: false,
        expiredSession: true
      })
    default: {
      const { error } = action
      if (error) {
        if (error.message && error.message.toLowerCase() === 'forbidden') {
          if (state.user.permissions.restricted) {
            return Object.assign({}, state, {
              breakOutOfRestricted: true
            })
          }
        }
        if (error.message && error.message.toLowerCase() === 'unauthorized') {
          if (state.user.permissions.restricted) {
            return Object.assign({}, resolvedInitialState, {
              authenticated: false,
              expiredSession: true
            })
          }
          return Object.assign({}, resolvedInitialState, {
            authenticated: false
          })
        }
        if (error.error && error.error.response) {
          if (error.error.response.error === 'invalid_grant') {
            return resolvedInitialState
          }
        } else if (error.response && error.response.error === 'invalid_grant') {
          return resolvedInitialState
        }
      }
      return state
    }
  }
}

export default authentication
