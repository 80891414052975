import React from 'react'
import PropTypes from 'prop-types'
import i18n from '../util/localization'
import style from './LoginForm.scss'
import ForgottenPasswordPopup from './ForgottenPasswordPopup'
import { CloseToast } from '../util/toaster'

class loginForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = { isForgottenPasswordPopupOpen: false, showPassword: false }
    this.ToggleForgottenPasswordPopup = this.ToggleForgottenPasswordPopup.bind(this)
    this.PasswordToggleTextIcon = this.PasswordToggleTextIcon.bind(this)
    this.TogglePasswordVisibility = this.TogglePasswordVisibility.bind(this)
  }

  PasswordToggleTextIcon () {
    if (!this.state.showPassword) {
      return <span>Show <i className="fas fa-eye" /></span>
    }
    return <span>Hide <i className="fas fa-eye-slash" /></span>
  }

  TogglePasswordVisibility () {
    this.setState({ showPassword: !this.state.showPassword })
  }

  ToggleForgottenPasswordPopup () {
    this.setState({ isForgottenPasswordPopupOpen: !this.state.isForgottenPasswordPopupOpen })
  }

  render () {
    CloseToast('serviceplanning-loader')
    const { onSubmit, onChange, isLoading, username, password, error } = this.props
    const passtype = this.state.showPassword ? 'text' : 'password'
    return (
      <React.Fragment>
        <form id="login" className={style['login-form']} onSubmit={onSubmit}>
          <div className="row">
            <div className="col-sm-12">
              <div className={`form-group${error ? ' has-error-deprecated' : ''}`}>
                <label htmlFor="usernameInput" className="control-label">{i18n.t('AnvändarkontoPåFleetManagementPortal')}</label>
                <input
                  id="usernameInput"
                  type="text"
                  name="username"
                  required
                  className="form-control input-lg"
                  value={username}
                  onChange={onChange}
                  disabled={isLoading}
                  placeholder={i18n.t('Användarnamn_E_Postadress')}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className={`form-group${error ? ' has-error-deprecated' : ''}`}>
                <label htmlFor="passwordInput" className="control-label">{i18n.t('Lösenord')}</label>
                <a onClick={this.TogglePasswordVisibility} href="#" className="showhidepassword">{this.PasswordToggleTextIcon()}</a>
                <input
                  id="passwordInput"
                  type={passtype}
                  name="password"
                  required
                  className="form-control input-lg"
                  value={password}
                  onChange={onChange}
                  disabled={isLoading}
                  placeholder={i18n.t('Lösenord')}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 text-center">
              <br />
              <button
                type="submit"
                disabled={isLoading}
                className="btn btn-success btn-block"
              >{i18n.t('LoggaIn')}
              </button>
              <br />
              <a href="#" onClick={this.ToggleForgottenPasswordPopup}>
                <b>
                  {i18n.t('GlömtLösenord_')}
                </b>
              </a>
            </div>
          </div>
        </form>
        {this.state.isForgottenPasswordPopupOpen &&
        <ForgottenPasswordPopup closeBox={this.ToggleForgottenPasswordPopup} ReactGA={this.props.ReactGA} />}
      </React.Fragment>
    )
  }
}

loginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  username: PropTypes.string,
  password: PropTypes.string,
  ReactGA: PropTypes.shape().isRequired
}

loginForm.defaultProps = {
  username: '',
  password: ''
}

export default loginForm
