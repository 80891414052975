import PropTypes from 'prop-types'
import React from 'react'
import ResponsiveModalDialog from './ResponsiveModalDialog'
import style from './ForgottenPasswordPopup.scss'
import GAEvent from './GAEvent'
import i18n from '../util/localization'

class ForgottenPasswordPopup extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
    this.handleClickClose = this.handleClickClose.bind(this)
  }

  handleClickClose () {
    this.props.closeBox()
  }

  render () {
    return (
      <ResponsiveModalDialog onClose={this.handleClickClose} onClickOutside={this.handleClickClose} title="">
        <GAEvent category="click" action="user on Forgotten Password" ReactGA={this.props.ReactGA} />
        <div className={style.forgottenPasswordPanel}>
          <h2>{i18n.t('GlömtLösenord_')}</h2>
          {i18n.t('UppföljningsrapportenÄrEnDelAvFleetManagementPortal_DuKommerOmdirigerasTill')}
          <div>
            <a href={ENV_VAR_RECOVER_REDIRECT} className="btn btn-primary">
              <b>
                {i18n.t('Fortsätt')}
              </b>
            </a>
          </div>
        </div>
      </ResponsiveModalDialog>
    )
  }
}

ForgottenPasswordPopup.propTypes = {
  closeBox: PropTypes.func.isRequired,
  ReactGA: PropTypes.shape().isRequired
}

export default ForgottenPasswordPopup
