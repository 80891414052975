// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CycledEnergyPopup_subheader-pTgaz{font-size:5rem}.CycledEnergyPopup_individualDetail-Gx7G9{padding-right:30px;padding-top:15px;font-size:3.6rem}.CycledEnergyPopup_specDetailsContainer-upjSS{display:flex;flex-wrap:wrap}.CycledEnergyPopup_specModalIcon-X35FO{margin-top:-0.225em}.CycledEnergyPopup_popupSpinnerContainer-BqGI2{width:80px}.CycledEnergyPopup_popupIcon-cYrh0{cursor:pointer;color:#4A8BA4;margin-left:5px}\n", "",{"version":3,"sources":["webpack://./src/components/VehicleDataTable/CycledEnergyPopup.scss"],"names":[],"mappings":"AAGA,mCACI,cAAe,CAChB,0CAGC,kBAAmB,CACnB,gBAAiB,CACjB,gBAAiB,CAClB,8CAGC,YAAa,CACb,cAAe,CAChB,uCAGC,mBAAoB,CACrB,+CAGC,UACF,CAAC,mCAGC,cAAe,CACf,aAAc,CACd,eAAgB","sourcesContent":["@import \"../../style/colors\";\n@import \"../../style/sizes\";\n\n.subheader {\n    font-size: 5rem;\n  }\n  \n  .individualDetail {\n    padding-right: 30px;\n    padding-top: 15px;\n    font-size: 3.6rem;\n  }\n  \n  .specDetailsContainer {\n    display: flex;\n    flex-wrap: wrap;\n  }\n  \n  .specModalIcon {\n    margin-top: -0.225em;\n  }\n\n  .popupSpinnerContainer {\n    width: 80px\n  }\n\n  .popupIcon {\n    cursor: pointer;\n    color: #4A8BA4;\n    margin-left: 5px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subheader": "CycledEnergyPopup_subheader-pTgaz",
	"individualDetail": "CycledEnergyPopup_individualDetail-Gx7G9",
	"specDetailsContainer": "CycledEnergyPopup_specDetailsContainer-upjSS",
	"specModalIcon": "CycledEnergyPopup_specModalIcon-X35FO",
	"popupSpinnerContainer": "CycledEnergyPopup_popupSpinnerContainer-BqGI2",
	"popupIcon": "CycledEnergyPopup_popupIcon-cYrh0"
};
export default ___CSS_LOADER_EXPORT___;
