import React from 'react'
import PropTypes from 'prop-types'
import styles from './Alert.scss'
import i18n from '../util/localization'

const Alert = ({
  type, id, title, text, remove, onClick
}) =>
  (
    <div className={`alert text-center alert-${type} ${styles.alertBox}`}>
      <button
        type="button"
        className="close"
        onClick={() => remove(id)}
      >&times;
      </button>
      {(onClick)
        ? (<a href="/" onClick={(e) => { e.preventDefault(); onClick(e) }}><strong>{i18n.t(title)}</strong> {i18n.t(text)}</a>)
        : (<p className="text-center"><strong>{i18n.t(title)}</strong> {i18n.t(text)}</p>)}
    </div>
  )

Alert.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  text: PropTypes.string,
  remove: PropTypes.func.isRequired,
  onClick: PropTypes.func
}

Alert.defaultProps = {
  onClick: null,
  id: undefined,
  title: '',
  text: ''
}

export default Alert
