import { LOGIN_SELECT_STAFF, LOGIN_CANCEL_SELECT_STAFF, BREAK_OUT_OF_RESTRICTED, CANCEL_BREAK_OUT_OF_RESTRICTED } from '../state/actionTypes'
// eslint-disable-next-line
const analyticsEventReporter = props => next => (action) => {
  switch (action.type) {
    case LOGIN_SELECT_STAFF:
      props.ReactGA.event({
        category: 'login',
        action: 'user needs to select staff'
      })
      break

    case LOGIN_CANCEL_SELECT_STAFF:
      props.ReactGA.event({
        category: 'login',
        action: 'user cancel staff selection'
      })
      break

    case BREAK_OUT_OF_RESTRICTED:
      props.ReactGA.event({
        category: 'login',
        action: 'break out of restricted'
      })
      break

    case CANCEL_BREAK_OUT_OF_RESTRICTED:
      props.ReactGA.event({
        category: 'login',
        action: 'cancel break out of restricted'
      })
      break
    default:
  }

  return next(action)
}

export default analyticsEventReporter
