import api from '../../api/launchInfoCache'
import * as types from '../actionTypes'

function launchInformationLoaded (seen) {
  return {
    type: types.LAUNCH_INFORMATION_UPDATED,
    seen
  }
}

export const load = () => (dispatch) => {
  dispatch(launchInformationLoaded(api.load()))
}

export const setSeen = () => (dispatch) => {
  api.setSeen()
  dispatch(launchInformationLoaded(true))
}
