import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import subscriptionActions from '../../state/userSubscription/actions'
import userSubscriptionSelector from '../../state/userSubscription/selectors'

export class QuerySubscriptionComp extends React.Component {
  constructor (props) {
    super(props)
    this.request = this.request.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.request(this.props)
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    if (this.props.extStaffRef !== newProps.extStaffRef) {
      this.request(newProps)
    }
  }

  request (props) {
    if (!props.loading && props.extStaffRef) {
      this.props.getSubscription(props.extStaffRef)
    }
  }

  render () {
    return null
  }
}

QuerySubscriptionComp.propTypes = {
  getSubscription: PropTypes.func.isRequired,
  extStaffRef: PropTypes.string
}

QuerySubscriptionComp.defaultProps = {
  extStaffRef: null
}

const mapStateToProps = state => ({
  loading: userSubscriptionSelector.isLoadingSubscription(state)
})

const mapDispatchToProps = dispatch => ({
  getSubscription (externalStaffReference) {
    dispatch(subscriptionActions.getSubscription(externalStaffReference))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(QuerySubscriptionComp)
