import React, { Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import 'jquery'
import 'bootstrap'
import './App.scss'
import MainContainer from '../containers/MainContainer'
import AuthenticatedRoute from './AuthenticatedRoute'
import AlertContainer from '../containers/AlertContainer'
import GAnalytics from './GAnalytics'
import AuthenticationResolver from './AuthenticationResolver'
import { cancelBreakOutOfRestricted, breakOutOfRestricted } from '../state/authentication/actions'
import { initilize, change } from '../state/language/actions'
import userSelectors from '../state/user/selectors'
import { getExtStaffRef } from '../state/authentication/selectors'
import UnAuthenticatedRoute from './UnAuthenticatedRoute'
import UnauthorizedContainer from '../containers/UnauthorizedContainer'
import Spinner from './Spinner'
import { RECIPIENT_INFO } from '../util/infos'
import QuerySubscription from './data/QuerySubscription'
import userSubscriptionSelector from '../state/userSubscription/selectors'
import subscriptionActions from '../state/userSubscription/actions'
import LoginSSOContainer from '../containers/LoginSSOContainer'
import Login from '../containers/LoginContainer'
import Header from './Header'

class App extends React.Component {
  constructor (props) {
    super(props)
    this.onRemove = this.onRemove.bind(this)
    this.enableMonitoringReportRecipient = this.enableMonitoringReportRecipient.bind(this)
  }

  componentDidMount () {
    this.props.corporateUi()
  }

  onRemove (id) {
    if (id === RECIPIENT_INFO.id) {
      // eslint-disable-next-line camelcase
      this.setState({ removeRecipientInfo: true })
    }
  }

  UNSAFE_componentWillMount () {
    if (!this.props.loadingLanguage) {
      this.props.loadLanguage()
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.language !== this.props.language) {
      this.props.changeLanguage(nextProps.language)
    }
  }

  enableMonitoringReportRecipient () {
    this.props.reactGA.event({
      category: 'click',
      action: 'enable monitoring recipient banner'
    })
    this.props.updateSubscription(this.props.exStaffRef, { isMonitoringReportRecipient: true })
  }

  render () {
    const alertsArray = []

    if (ENV_VAR_APPLICATION === 'MONITORING' && this.props.isMonitoringReportRecipient === false && !this.state?.removeRecipientInfo) {
      RECIPIENT_INFO.onClick = this.enableMonitoringReportRecipient
      alertsArray.push(RECIPIENT_INFO)
    }

    return (
      <>
        <sdds-theme></sdds-theme>
        <scope-theme></scope-theme>
        <QuerySubscription extStaffRef={this.props.exStaffRef} />
        <Router>
          <Suspense fallback=''>
            <Route
              path="/"
              render={props => (
                <GAnalytics
                  ReactGA={this.props.reactGA}
                  user={this.props.auth.user ? this.props.auth.user : null}
                  {...props}
                />
              )}
            />
            <Route
              path="/"
              render={props => (
                <AuthenticationResolver
                  {...props}
                />
              )}
            />
            {this.props.loadingLanguage && (!ENV_VAR_MY_SCANIA || !this.props.auth.resolved)
              ? (<Spinner extraClass='mains' />)
              : (
                <>
                  {(!ENV_VAR_MY_SCANIA || this.props.auth.authenticated) &&
                    (
                      <div>
                        <Header language={this.props.language} />
                        <AlertContainer alerts={alertsArray} onRemove={this.onRemove} />
                      </div>
                    )
                  }
                  <Switch>
                    <Route
                      exact
                      path="/forbidden"
                      component={UnauthorizedContainer}
                    />
                    <UnAuthenticatedRoute
                      path="/login"
                      component={ENV_VAR_MY_SCANIA ? LoginSSOContainer : Login}
                      authed={this.props.auth.authenticated}
                      resolved={this.props.auth.resolved}
                      restricted={this.props.auth.user.permissions.restricted}
                      ReactGA={this.props.reactGA}
                    />
                    <AuthenticatedRoute
                      authed={this.props.auth.authenticated}
                      resolved={this.props.auth.resolved}
                      path="/"
                      restricted={this.props.auth.user.permissions.restricted}
                      authorized={this.props.authorized}
                      component={MainContainer}
                      ReactGA={this.props.reactGA}
                    />
                    <Redirect to="/" />
                  </Switch>
                </>
                )}
          </Suspense>
        </Router>
      </>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  cancelBreakOutOfRestricted () {
    dispatch(cancelBreakOutOfRestricted())
  },
  breakOutOfRestricted () {
    dispatch(breakOutOfRestricted())
  },
  loadLanguage () {
    dispatch(initilize())
  },
  changeLanguage (lang) {
    dispatch(change(lang))
  },
  updateSubscription (exStaffRef, subscription) {
    dispatch(subscriptionActions.updateSubscription(exStaffRef, subscription))
  }
})

App.propTypes = {
  auth: PropTypes.shape({
    authenticated: PropTypes.bool.isRequired,
    resolved: PropTypes.bool.isRequired,
    breakOutOfRestricted: PropTypes.bool.isRequired,
    user: PropTypes.shape({
      permissions: PropTypes.shape({
        restricted: PropTypes.bool
      }),
      extStaffRef: PropTypes.string,
      extCustRef: PropTypes.string
    })
  }).isRequired,
  exStaffRef: PropTypes.string,
  corporateUi: PropTypes.func.isRequired,
  language: PropTypes.string,
  cancelBreakOutOfRestricted: PropTypes.func.isRequired,
  breakOutOfRestricted: PropTypes.func.isRequired,
  changeLanguage: PropTypes.func.isRequired,
  loadLanguage: PropTypes.func.isRequired,
  loadingLanguage: PropTypes.bool.isRequired,
  authorized: PropTypes.bool.isRequired,
  isMonitoringReportRecipient: PropTypes.bool,
  updateSubscription: PropTypes.func.isRequired,
  reactGA: PropTypes.shape({
    event: PropTypes.func.isRequired
  }).isRequired
}

App.defaultProps = {
  language: null,
  isMonitoringReportRecipient: undefined,
  exStaffRef: undefined
}

const mapStateToProps = state => ({
  auth: state.authentication,
  exStaffRef: getExtStaffRef(state),
  language: userSelectors.getLanguage(state),
  loadingLanguage: state.language.loading,
  loadedLanguage: state.language.locale,
  authorized: state.authorization.authorized,
  isMonitoringReportRecipient: userSubscriptionSelector.isMonitoringReportRecipient(state)
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
