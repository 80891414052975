export const getFuelConsumptionTranslationKey = (unit) => {
  switch (unit) {
    case 'l/100km': return 'L_100Km'
    case 'km/l': return 'Km_L'
    case 'mpg': return 'Mpg'
    case 'kg/100km': return 'kg/100km'
    case 'km/kg': return 'Km_Kg'
    case 'miles/kg': return 'Miles_Kg'
    case 'Nm3/100km': return 'Nm3/100km'
    case 'km/Nm3': return 'km/Nm3'
    case 'miles/Nm3': return 'miles/Nm3'
    case 'litres/h': return 'Liter_H'
    case 'litres': return 'UNIT_litres'
    case 'gallons': return 'UNIT_gal'
    case 'kg': return 'Kg'
    case 'Nm3': return 'Nm3'
    default: return unit
  }
}

// the function below is used in the excel and pdf files to display units, so needs to cover all translation keys
export const unitToTranslationKey = (unit) => {
  switch (unit) {
    case 'minutes': return 'H_M'
    case 'litres': return 'UNIT_litres'
    case 'kg': return 'Kg'
    case 'Nm3': return 'Nm3'
    case 'gallons': return 'UNIT_gal'
    case 'gPerKm': return 'UNIT_g_per_km'
    case 'gPerH': return 'g/h'
    case 'tonne': return 'UNIT_tonne'
    case '%': return '%'
    case 'km': return 'UNIT_km'
    case 'mile': return 'UNIT_mi'
    case 'kwh/100km': return 'KWh_100Km'
    case 'per100km': return '__100Km'
    case 'per100miles': return '__100Miles'
    case 'l/100km': return 'L_100Km'
    case 'km/l': return 'Km_L'
    case 'mpg': return 'Mpg'
    case 'kg/100km': return 'kg/100km'
    case 'km/kg': return 'Km_Kg'
    case 'miles/kg': return 'Miles_Kg'
    case 'Nm3/100km': return 'Nm3/100km'
    case 'km/Nm3': return 'km/Nm3'
    case 'miles/Nm3': return 'miles/Nm3'
    case 'litres/h': return 'Liter_H'
    case 'kwh': return 'KWh'
    case 'km/kwh': return 'Km_KWh'
    case 'miles/kwh': return 'Miles_KWh'
    case 'kWh/100 miles': return 'KWh_100Miles'
    default: return unit
  }
}

export const getDistanceTranslationKey = (unit) => {
  switch (unit) {
    case 'km': return 'UNIT_km'
    case 'mile': return 'UNIT_mi'
    default: return null
  }
}

export const getRuntimeTranslationKey = (unit) => {
  switch (unit) {
    case 'minutes': return 'H_M'
    default: return null
  }
}

export const getEnergyTranslationKey = (unit) => {
  switch (unit) {
    case 'kwh/100km': return 'KWh_100Km'
    case 'km/kwh': return 'Km_KWh'
    case 'miles/kwh': return 'Miles_KWh'
    case 'kWh/100 miles': return 'KWh_100Miles'
    default: return 'KWh'
  }
}

export const getPerDistanceTranslationKey = (unit) => {
  switch (unit) {
    case 'per100km': return '__100Km'
    case 'per100miles': return '__100Miles'
    default: return null
  }
}

export const getWeightTranslationKey = (unit) => {
  switch (unit) {
    case 'tonne': return 'UNIT_tonne'
    case 'kg': return 'Kg'
    default: return null
  }
}

export const getGasConsumptionType = (gasUnit) => {
  switch (gasUnit) {
    case 'Kg': return 'gasInMass'
    case 'Nm3': return 'gasInVolume'
    default: return null
  }
}
