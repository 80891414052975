/*eslint-disable*/
(function (global, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module unless amdModuleId is set
    define([], () => (global.lrStickyHeader = factory(global)));
  } else if (typeof exports === 'object') {
    // Node. Does not work with strict CommonJS, but
    // only CommonJS-like environments that support module.exports,
    // like Node.
    module.exports = factory(global);
  } else {
    global.lrStickyHeader = factory(global);
  }
}(window, (window) => {
  function getOffset(element, property) {
    let offset = element[property];
    let parent = element;
    while ((parent = parent.offsetParent) !== null) {
      offset += parent[property];
    }
    return offset;
  }

  const sticky = {

    setWidth: function setWidth() {
      const firstRow = this.tbody.getElementsByTagName('TR')[0];
      const trh = this.thead.getElementsByTagName('TR')[0];
      let firstTds;
      let firstThs;

      function setCellWidth(cell) {
        cell.style.width = `${cell.offsetWidth}px`;
      }

      if (firstRow && trh) {
        firstTds = firstRow.getElementsByTagName('TD');
        firstThs = trh.getElementsByTagName('TH');

        [].forEach.call(firstTds, setCellWidth);
        [].forEach.call(firstThs, setCellWidth);
      }
    },
    eventListener: function eventListener() {
      const offsetTop = getOffset(this.thead, 'offsetTop') - parseInt(this.headerHeight);
      const offsetLeft = getOffset(this.thead, 'offsetLeft');
      const parentOffsetTop = this.parentIsWindow ? 0 : getOffset(this.parent, 'offsetTop');
      const parentScrollTop = this.parentIsWindow ? parent.pageYOffset : this.parent.scrollTop;
      const classes = this.thead.className.split(' ');

      // For cross-browser compatibility and IE, use window.pageYOffset instead of window.scrollY
      this.windowOffsetY = this.parentIsWindow ? 0 : window.pageYOffset;
      const styleTop = `${parseInt(this.headerHeight) + parentOffsetTop - parseInt(this.windowOffsetY)}px`;

      if (this.stick !== true && (offsetTop - (parentOffsetTop + parentScrollTop) < -50) &&
          (offsetTop + this.tbody.offsetHeight - (parentOffsetTop + parentScrollTop) > 50) && this.thead.style.top !== styleTop) {
        this.stick = true;        
        setTimeout(() => {
          this.treshold = offsetTop;
          this.setWidth();
          this.thead.style.left = `${offsetLeft}px`;
          this.thead.style.top = styleTop;
          classes.push('sticky-header');
          this.thead.style.position = 'fixed';
          this.thead.className = classes.join(' ');
          this.element.style.marginTop =styleTop;          
        }, 300);
      }

      if (this.stick === true && !this.parentIsWindow && this.windowOffsetY !== window.pageYOffset && this.thead.style.top !== styleTop) {
        this.thead.style.top = styleTop;
      }

      if (this.stick === true) {
        if (this.parentIsWindow && ((this.treshold - parentScrollTop) > 0 || parentScrollTop <= 0)) {
          this.stick = false;
          setTimeout(() => {
            this.thead.style.position = 'relative'; // IE does not support 'initial'
            this.thead.style.top = '';
            this.thead.style.left = '';
            this.element.style.marginTop = '';
            classes.splice(classes.indexOf('sticky-header'), 1);
            this.thead.className = (classes).join(' ');            
          }, 300);
        }
      }
    },
  };

  return function lrStickyHeader(tableElement, options) {
    if (tableElement === null) {
        return {
            clean: () => {}
        };
    }

    let headerHeight = 0;
    if (options && options.headerHeight) { headerHeight = parseInt(options.headerHeight); }
    let parent = window;
    if (options && options.parent) {
      parent = options.parent;
    }

    let thead;
    let tbody;

    if (tableElement.tagName !== 'TABLE') {
      throw new Error('lrStickyHeader only works on table element');
    }
    tbody = tableElement.getElementsByTagName('TBODY');
    thead = tableElement.getElementsByTagName('THEAD');

    if (!thead.length) {
      throw new Error('could not find the thead group element');
    }

    if (!tbody.length) {
      throw new Error('could not find the tbody group element');
    }
    
    thead = thead[0];
    tbody = tbody[0];


    const stickyTable = Object.create(sticky, {
      element: { value: tableElement },
      parent: {
        get() {
          return parent;
        },
      },
      parentIsWindow: { value: parent === window },
      headerHeight: {
        get() {
          return headerHeight;
        },
      },
      thead: {
        get() {
          return thead;
        },
      },
      tbody: {
        get() {
          return tbody;
        },
      },
    });

    const listener = stickyTable.eventListener.bind(stickyTable);
    parent.addEventListener('scroll', listener);
    if (parent !== window) {
      window.addEventListener('scroll', listener);
    }
    stickyTable.clean = function clean() {
      parent.removeEventListener('scroll', listener);
      if (parent !== window) {
        window.removeEventListener('scroll', listener);
      }
    };

    return stickyTable;
  };
}));

