import PropTypes from 'prop-types'
import { useIsFeatureEnabled } from './hooks'

const FeatureToggle = ({
  children,
  feature
}) => {
  const isFeatureEnabled = useIsFeatureEnabled(feature)
  if (children && isFeatureEnabled) {
    return children
  }
  return null
}

FeatureToggle.propTypes = {
  children: PropTypes.element,
  feature: PropTypes.string.isRequired
}
FeatureToggle.defaultProps = {
  children: null
}

export default FeatureToggle
