import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { uuidv4 } from '../util/apiUtil'
import authentication from './authentication'

export const featureToggleApi = createApi({
  reducerPath: 'featureToggleApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ENV_VAR_BFF_HOST,
    prepareHeaders: async (headers) => {
      const accessToken = await authentication.getValidAccessToken()
      if (accessToken) {
        headers.set('authorization', `Bearer ${accessToken}`)
      }
      headers.set('X-Correlation-ID', uuidv4())
      headers.set('X-Client', ENV_VAR_X_CLIENT)
      headers.set('content-type', 'application/json')
      return headers
    }
  }),
  endpoints: (builder) => ({
    getEnabledFeaturesForReferences: builder.query({
      query: (references: Object) => ({
        url: '/api/v1/settings/featuretoggle',
        method: 'post',
        body: references
      })
    })
  })
})

export const { useGetEnabledFeaturesForReferencesQuery } = featureToggleApi
