/* eslint-disable max-len */
import moment from 'moment'

export const Order = {
  ASC: 'asc',
  DESC: 'desc'
}
Object.freeze(Order)

export const Columns = {
  NAME: 0,
  PERIOD_START: 1,
  PERIOD_STOP: 2
}
Object.freeze(Columns)

function compareDates (date1, date2, order) {
  if (order === Order.ASC) {
    if (moment(date2).isBefore(moment(date1))) {
      return 1
    } else if (moment(date2).isAfter(moment(date1))) {
      return -1
    }
    return 0
  }
  if (moment(date1).isBefore(moment(date2))) {
    return 1
  } else if (moment(date1).isAfter(moment(date2))) {
    return -1
  }
  return 0
}

function compareStrings (string1, string2, order) {
  const x = string1.toLowerCase()
  const y = string2.toLowerCase()
  if (order === Order.ASC) {
    if (x < y) { return -1 }
    if (x > y) { return 1 }
    return 0
  }
  if (x > y) { return -1 }
  if (x < y) { return 1 }
  return 0
}

function compareNumbers (num1, num2, order) {
  let x = Number.parseFloat(num1)
  let y = Number.parseFloat(num2)
  if (Number.isNaN(x)) {
    x = -1
  }
  if (Number.isNaN(y)) {
    y = -1
  }
  if (order === Order.ASC) {
    if (x < y) { return -1 }
    if (x > y) { return 1 }
    return 0
  }
  if (x > y) { return -1 }
  if (x < y) { return 1 }
  return 0
}

/**
 *
 * @param {*} list - list to sort. Will *not* be mutated.
 * @param {*} valueIndex - index of the column to sort on
 * @param {string} order - Sort in ascending or decending order.
 * @param {array} columns - array of report columns
 * @returns the sorted list.
 */
export const sortByValue = (list: Equipment[], valueIndex: number, order: 'asc' | 'desc', columns: any[]) => {
  const listCopy = [...list]
  switch (valueIndex) {
    case Columns.NAME:
      return listCopy.sort((item1, item2) =>
        compareStrings(item1.info.name, item2.info.name, order))
    case Columns.PERIOD_START:
      return listCopy.sort((item1, item2) =>
        compareDates(item1.info.periodStart, item2.info.periodStart, order))
    case Columns.PERIOD_STOP:
      return listCopy.sort((item1, item2) =>
        compareDates(item1.info.periodStop, item2.info.periodStop, order))
    default: {
      return listCopy.sort((item1, item2) =>
        compareNumbers(item1?.parameters[columns[valueIndex - 3]?.name]?.value, item2?.parameters[columns[valueIndex - 3]?.name]?.value, order))
    }
  }
}
