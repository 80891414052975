// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LoginForm_login-form-ZO909 .showhidepassword{float:right;font-family:'Scania Sans Semi Condensed';font-weight:bold;font-size:1.2rem;color:#4a8ba4;text-decoration:none}.LoginForm_login-form-ZO909 a:hover{color:#4a8ba4}.LoginForm_login-form-ZO909 a b{color:#4a8ba4}\n", "",{"version":3,"sources":["webpack://./src/components/LoginForm.scss","webpack://./src/style/_colors.scss"],"names":[],"mappings":"AACA,8CAEQ,WAAY,CACZ,wCAAyC,CACzC,gBAAiB,CACjB,gBAAiB,CACjB,aAAc,CACd,oBAAqB,CAP7B,oCAWY,aCDQ,CDVpB,gCAcY,aCJQ","sourcesContent":["@import \"../style/colors\";\n.login-form {\n    :global(.showhidepassword){\n        float: right;\n        font-family: 'Scania Sans Semi Condensed';\n        font-weight: bold;\n        font-size: 1.2rem;\n        color: #4a8ba4;\n        text-decoration: none;\n    }\n    :global(a) {\n        &:hover {\n            color: $info-color;\n        }\n        :global(b) {\n            color: $info-color;\n        }\n    }\n}","$background-color: #eeeff0;\n$light-text-color: #94999d;\n$light-border-color: #dadada;\n$dark-text-color: #242424;\n$blue-text-color: #041e42;\n$white-color: white;\n$light-blue-color: #f2f7f9;\n$primary-color: #033a6a;\n$success-color: #44a878;\n$warning-color: #f6a98e;\n$danger-color: #cc1040;\n$info-color: #4a8ba4;\n$user-icon: #0F3263;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login-form": "LoginForm_login-form-ZO909"
};
export default ___CSS_LOADER_EXPORT___;
