// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ExportMenu_exportButton-dFxpF{border-radius:3px !important}@media all and (max-width: 767px){.ExportMenu_exportButton-dFxpF{display:block;width:100%}}@media all and (max-width: 767px){.ExportMenu_exportButtonGroup-NNJGS{display:block;width:100%}}@media all and (max-width: 767px){.ExportMenu_exportButtonGroup-NNJGS .dropdown-menu{width:100%;text-align:center}}@media all and (max-width: 767px){.ExportMenu_exportButtonGroup-NNJGS .dropdown-menu a{padding:7px}}\n", "",{"version":3,"sources":["webpack://./src/components/Export/ExportMenu.scss"],"names":[],"mappings":"AAEA,+BACI,4BAA6B,CAC7B,kCAFJ,+BAGQ,aAAc,CACd,UAAW,CAGlB,CAGG,kCADJ,oCAEQ,aAAc,CACd,UAAW,CAelB,CAXO,kCAPR,mDAQY,UAAW,CACX,iBAAkB,CAQzB,CAJO,kCAbZ,qDAcgB,WAAY,CAEnB","sourcesContent":["@import \"../../style/sizes\";\n\n.exportButton {\n    border-radius: 3px !important;\n    @media all and (max-width: $mobile-width) {\n        display: block;\n        width: 100%;\n     \n    }\n}\n\n.exportButtonGroup {\n    @media all and (max-width: $mobile-width) {\n        display: block;\n        width: 100%;\n    }\n\n    :global(.dropdown-menu) {\n        @media all and (max-width: $mobile-width) {\n            width: 100%;\n            text-align: center;\n        }\n\n        a {\n            @media all and (max-width: $mobile-width) {\n                padding: 7px;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"exportButton": "ExportMenu_exportButton-dFxpF",
	"exportButtonGroup": "ExportMenu_exportButtonGroup-NNJGS"
};
export default ___CSS_LOADER_EXPORT___;
