// eslint-disable-next-line
const crashReporter = props => next => (action) => {
  const { error, type } = action
  let errorToLog
  if (error) {
    if (error.header) {
      errorToLog = error.error
    } else if (error.response) {
      const { utcTimestamp, ...withoutTimestamp } = error.response
      errorToLog = withoutTimestamp
    } else {
      errorToLog = error
    }
    props.ReactGA.exception({
      description: `${type}: ${JSON.stringify(errorToLog)}`,
      fatal: true
    })
  }
  return next(action)
}

export default crashReporter
