// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Actionbar_actionbar-W6LuJ{text-align:right}@media all and (max-width: 991px){.Actionbar_actionbar-W6LuJ{text-align:center}}\n", "",{"version":3,"sources":["webpack://./src/components/Actionbar.scss"],"names":[],"mappings":"AAIA,2BACI,gBAAiB,CAEjB,kCAHJ,2BAIQ,iBAAkB,CAEzB","sourcesContent":["@import \"../style/fonts\";\n@import \"../style/colors\";\n@import \"../style/sizes\";\n\n.actionbar {\n    text-align: right;\n\n    @media all and (max-width: $tablet-width) {\n        text-align: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionbar": "Actionbar_actionbar-W6LuJ"
};
export default ___CSS_LOADER_EXPORT___;
