import React from 'react'
import PropTypes from 'prop-types'
import './PropulsionTypePicker.scss'
import ReportsSchema from '../util/ReportsSchema/ReportsSchema'

const reportClick = (propulsionType, ReactGA) => {
  ReactGA.event({
    category: 'click',
    action: 'user changed propulsionType',
    label: propulsionType
  })
}

const flatten = (obj, model, reports) => {
  reports.forEach((item) => {
    if (obj[item]) model[item] += obj[item]
  })

  if (Array.isArray(obj)) {
    obj.forEach((value) => {
      flatten(value, model, reports)
    })
  }
  if (Object.isObject(obj)) {
    Object.keys(obj).forEach((value) => {
      flatten(obj[value], model, reports)
    })
  }
}

class PropulsionTypePicker extends React.Component {
  handlePropulsionClick (propulsionType) {
    reportClick(propulsionType.toLowerCase(), this.props.ReactGA)
    this.props.callback(propulsionType)
  }

  filterOutEmptyPropulsionType (buttons) {
    const model = { }
    buttons.forEach((a) => {
      model[a.toLowerCase()] = 0
    })
    if (this.props.reports) flatten(this.props.reports, model, buttons)
    return model
  }

  renderPropulsionTypeButtonV2 (reports, propulsionType) {
    // eslint-disable-next-line react/prop-types
    const PropulsionTypeListItem = ({ item }) => {
      const buttonCSS = 'badge badge-pill badge-primary'
      return (
        <li className={propulsionType === item ? `active ${buttonCSS}` : buttonCSS}>
          <button onClick={e => this.handlePropulsionClick(item, e)}>
            {ReportsSchema.get(item).pickerText}
          </button>
        </li>
      )
    }

    const model = this.filterOutEmptyPropulsionType(reports)
    return (
      <ul className="nav nav-pills propulsion-type-buttons">
        {
          reports.map(item => model[item] > 0 && <PropulsionTypeListItem key={item} item={item} />)
        }
      </ul>
    )
  }

  render () {
    const reports = ReportsSchema.list()
    return this.renderPropulsionTypeButtonV2(reports, this.props.propulsionType)
  }
}

PropulsionTypePicker.propTypes = {
  propulsionType: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  reports: PropTypes.shape({
    week: PropTypes.shape({}),
    month: PropTypes.shape({}),
    year: PropTypes.shape({})
  }).isRequired,
  ReactGA: PropTypes.shape().isRequired
}

export default PropulsionTypePicker
