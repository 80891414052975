import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import FileSaver from 'file-saver'
import { getReportData, hasLoadedReport, getVehicleDataTableOrder } from '../state/report/selectors'
import userSelectors from '../state/user/selectors'
import { getPdf, pdfHasBeenDownloaded } from '../state/pdf/actions'
import { isLoadingPdf, isFailureLoadingPdf, getDownloadedPdf } from '../state/pdf/selectors'
import { createReport } from '../util/reportExport/pdfInputCreator'
import createExcelDataFromReport from '../util/reportExport/excelInputCreator'
import ExportMenu from '../components/Export/ExportMenu'
import ExportDownloadModal from '../components/Export/ExportDownloadModal'
import { createFilename } from '../util/reportExport/reportExportUtils'
import { useIsFeatureEnabled } from '../components/FeatureToggle/hooks'
import { sortByValue } from '../components/VehicleDataTable/VehicleDataTableSorter'
import { useReportModel, useReportColumns } from '../util/ReportsSchema/hooks'

const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

interface ExportContainerProps {
  report: Report,
  loadedReport: boolean,
  customerName: string,
  periodType: PeriodType,
  periodStart: string,
  periodEnd: string,
  getPdf: Function,
  pdfHasBeenDownloaded: Function,
  pdfLoading: boolean,
  pdf: string,
  propulsionType: PropulsionType,
  order: VehicleDataTableSortOrder,
  ReactGA: any
}

const ExportContainer = ({ pdf: base64pdf, pdfHasBeenDownloaded, ...props }: ExportContainerProps) => {
  const [blob, setBlob] = useState(null)
  const [filename, setFilename] = useState(null)
  const showUnstableHybridData = useIsFeatureEnabled('VPHybridEnergyConsumption')
  const shouldHideAdBlueData = useIsFeatureEnabled('VPHideAdBlueConsumption')
  const schema = useReportModel(props.propulsionType)
  const columns = useReportColumns(props.propulsionType)

  const getSortedVehicleData = (): ReportData => ({
    ...props.report.data,
    equipments: sortByValue(props.report.data.equipments, props.order.orderColumnIndex, props.order.order, columns)
  })

  const onHideModal = () => {
    setBlob(null)
    setFilename(null)
  }

  const generatePdf = () => {
    props.ReactGA.event({
      category: 'export',
      action: 'pdf requested'
    })
    props.getPdf(createReport(
      getSortedVehicleData(),
      props.customerName,
      props.periodType,
      props.periodStart,
      props.periodEnd,
      schema,
      columns
    ))
  }

  const downloadBlob = (blob, extension) => {
    const newFilename = createFilename(
      props.periodType,
      props.periodStart,
      props.periodEnd,
      extension
    )
    if (isSafari()) {
      setBlob(blob)
      setFilename(newFilename)
    } else {
      FileSaver.saveAs(blob, newFilename)
    }
  }

  const generateExcel = () => {
    props.ReactGA.event({
      category: 'export',
      action: 'excel requested'
    })
    const csvData = createExcelDataFromReport(
      getSortedVehicleData(),
      props.customerName,
      props.periodType,
      props.periodStart,
      props.periodEnd,
      props.propulsionType,
      { showUnstableHybridData, hideAdBlueData: shouldHideAdBlueData }
    )

    const newBlob = new Blob([csvData], { type: 'application/octet-stream' })

    downloadBlob(newBlob, '.xlsx')
  }

  const onExport = (fileType) => {
    if (fileType === 'PDF') {
      generatePdf()
    } else {
      generateExcel()
    }
  }

  useEffect(() => {
    const saveBlob = async (dataUrl) => {
      const response = await fetch(dataUrl)
      const blob = await response.blob()
      const pdfBlob = new Blob([blob], { type: 'application/pdf' })
      downloadBlob(pdfBlob, '.pdf')
      pdfHasBeenDownloaded()
    }

    if (base64pdf) {
      saveBlob(base64pdf)
    }
  }, [base64pdf, pdfHasBeenDownloaded, downloadBlob])

  return (
    <div>
      <ExportMenu
        loading={props.pdfLoading}
        dataAvailable={props.loadedReport}
        onExport={onExport}
      />
      <ExportDownloadModal
        onHide={onHideModal}
        show={!!blob}
        filename={filename}
        onSaveBlob={() => FileSaver.saveAs(blob, filename)}
      />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  report: getReportData(
    state,
    ownProps.periodType,
    ownProps.periodStart,
    ownProps.propulsionType
  ),
  loadedReport: hasLoadedReport(
    state,
    ownProps.periodType,
    ownProps.periodStart,
    ownProps.propulsionType
  ),
  customerName: userSelectors.getCustomerName(state),
  pdf: getDownloadedPdf(state),
  pdfLoading: isLoadingPdf(state),
  pdfFailure: isFailureLoadingPdf(state),
  order: getVehicleDataTableOrder(state),
  ReactGA: ownProps.ReactGA
})

const mapDispatchToProps = dispatch => ({
  getPdf (report) {
    dispatch(getPdf(report))
  },
  pdfHasBeenDownloaded () {
    dispatch(pdfHasBeenDownloaded())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ExportContainer)
