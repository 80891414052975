import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import Spinner from './Spinner'

const AuthenticatedRoute = ({
  authed, resolved, component: Component, restricted, authorized, ReactGA, ...rest
}) => {
  if (!resolved) {
    return <Route {...rest} render={() => <Spinner />} />
  }
  if (authed && (authorized || restricted)) {
    return <Route {...rest} render={props => <Component ReactGA={ReactGA} {...props} />} />
  }
  if ((authed && !authorized)) {
    return <Route {...rest} render={() => <Redirect to="/forbidden" />} />
  }
  return <Route {...rest} render={() => <Redirect to={{ pathname: '/login' }} />} />
}

AuthenticatedRoute.propTypes = {
  authed: PropTypes.bool.isRequired,
  resolved: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
  location: PropTypes.shape(),
  restricted: PropTypes.bool.isRequired,
  authorized: PropTypes.bool.isRequired,
  ReactGA: PropTypes.shape().isRequired
}

AuthenticatedRoute.defaultProps = {
  location: {}
}

export default AuthenticatedRoute
