// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TrendArrow_arrow-vzIJs.TrendArrow_green-qYLty{color:#44a878;margin-right:5px}.TrendArrow_arrow-vzIJs.TrendArrow_red-vPcvL{color:#cc1040;margin-right:5px}\n", "",{"version":3,"sources":["webpack://./src/components/VehicleDataTable/TrendArrow.scss","webpack://./src/style/_colors.scss"],"names":[],"mappings":"AACA,+CAEI,aCKmB,CDJnB,gBAAiB,CAHrB,6CAMI,aCGkB,CDFlB,gBAAiB","sourcesContent":["@import \"../../style/colors\";\n.arrow {\n  &.green {\n    color: $success-color;\n    margin-right: 5px;\n  }\n  &.red {\n    color: $danger-color;\n    margin-right: 5px;\n  }\n}","$background-color: #eeeff0;\n$light-text-color: #94999d;\n$light-border-color: #dadada;\n$dark-text-color: #242424;\n$blue-text-color: #041e42;\n$white-color: white;\n$light-blue-color: #f2f7f9;\n$primary-color: #033a6a;\n$success-color: #44a878;\n$warning-color: #f6a98e;\n$danger-color: #cc1040;\n$info-color: #4a8ba4;\n$user-icon: #0F3263;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"arrow": "TrendArrow_arrow-vzIJs",
	"green": "TrendArrow_green-qYLty",
	"red": "TrendArrow_red-vPcvL"
};
export default ___CSS_LOADER_EXPORT___;
