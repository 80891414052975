import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { login, badCredentialsResolved, cancelStaffSelection } from '../state/authentication/actions'
import { getSelectableStaffs, isFailureLoggingIn, isLoadingAuthentication, isUserProvidingBadCredentials, isUserAuthenticated, hasExpiredSession, hasLockedAccount } from '../state/authentication/selectors'
import LoginForm from '../components/LoginForm'
import StaffList from '../components/StaffList'
import AlertContainer from './AlertContainer'
import { LOGIN_ERROR, GENERAL_ERROR } from '../util/errors'
import { EXPIRED_SESSION_INFO } from '../util/infos'
import { LOCKED_ACCOUNT } from '../util/warnings'
import i18n from '../util/localization'
import background1 from '../assets/loginbackground/1.png'
import background2 from '../assets/loginbackground/2.png'
import background3 from '../assets/loginbackground/3.png'
import background4 from '../assets/loginbackground/4.png'
import background5 from '../assets/loginbackground/5.png'

class LoginContainer extends React.Component {
  constructor (props) {
    super(props)
    const alerts = []

    const backs = [background1, background2, background3, background4, background5]
    const background = backs[Math.floor(Math.random() * backs.length)]

    if (this.props.expiredSession) {
      alerts.push(EXPIRED_SESSION_INFO)
    }

    if (this.props.lockedAccount) {
      alerts.push(LOCKED_ACCOUNT)
    }

    this.state = {
      background,
      creds: {
        username: '',
        password: ''
      },
      error: false,
      alerts
    }
    this.processForm = this.processForm.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.clear = this.clear.bind(this)
    this.staffSelected = this.staffSelected.bind(this)
    this.cancelStaffSelection = this.cancelStaffSelection.bind(this)
    this.onRemoveAlert = this.onRemoveAlert.bind(this)
  }

  onRemoveAlert (id) {
    this.setState({
      alerts: this.state.alerts.filter(a => a.id !== id)
    })
  }

  getCssClass () {
    if (this.props.runsInModal) {
      return 'col-md-12'
    }
    return 'col-md-4 col-lg-4  no-float'
  }

  staffSelected (event, staff) {
    event.preventDefault()

    const credsUpdate = this.state.creds
    if (staff.userName) {
      credsUpdate.username = staff.userName
    }
    if (staff.externalStaffReference) {
      credsUpdate.externalStaffReference = staff.externalStaffReference
    }

    this.setState({ creds: credsUpdate })
    this.props.login(this.state.creds)
  }

  cancelStaffSelection (event) {
    event.preventDefault()
    this.setState({ creds: { ...this.state.creds, password: '' } })
    this.props.cancelStaffSelect()
  }

  processForm (event) {
    event.preventDefault()
    this.props.badCredentialsResolved()
    this.props.login(this.state.creds)
  }

  handleChange (event) {
    const credsUpdate = this.state.creds
    credsUpdate[event.target.name] = event.target.value
    this.setState({ creds: credsUpdate, error: false })
  }

  clear (event) {
    event.preventDefault()
    this.setState({ creds: { username: '', password: '' } })
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const alerts = []

    if (nextProps.badCredentials) {
      alerts.push(LOGIN_ERROR)
    }

    if (nextProps.loginFailure) {
      alerts.push(GENERAL_ERROR)
    }

    if (nextProps.expiredSession) {
      alerts.push(EXPIRED_SESSION_INFO)
    }

    if (nextProps.lockedAccount) {
      alerts.push(LOCKED_ACCOUNT)
    }

    this.setState({
      alerts,
      creds: { ...this.state.creds, password: alerts.length > 0 ? '' : this.state.creds.password, externalStaffReference: undefined },
      error: alerts.length > 0
    })
  }

  render () {
    // eslint-disable-next-line global-require
    require('./LoginContainer.scss')
    return (
      <div style={{ height: '100%', position: 'fixed', width: '100%' }}>
        <div className="mainlogincontainer container-fluid" style={{ height: '100%' }}>
          <div className="row">
            <div className="col-md-3 col-lg-3  no-float col-sm-12 col-xs-12">
              <div className="panel">
                <div className="panel-body padding-fix">
                  <div className="text-center">
                    <br />
                    <h3 className="login-header">{i18n.t('Uppföljningsrapport')}</h3>
                  </div>
                  <AlertContainer alerts={this.state.alerts} onRemove={this.onRemoveAlert} />
                  <br />
                  {this.props.selectableStaffs.length === 0 && (
                  <LoginForm
                    onSubmit={this.processForm}
                    onChange={(this.handleChange)}
                    isLoading={this.props.loading}
                    username={this.state.creds.username}
                    password={this.state.creds.password}
                    error={this.state.error}
                    ReactGA={this.props.ReactGA}
                  />
                  )}
                  {this.props.selectableStaffs.length > 0 && (
                  <StaffList
                    staffs={this.props.selectableStaffs}
                    onSelect={this.staffSelected}
                    onCancel={this.cancelStaffSelection}
                  />
                  )}
                </div>
              </div>
            </div>
            <div className="bigbanner col-md-9 col-lg-9  hidden-xs hidden-sm no-float" style={{ backgroundImage: `url(${this.state.background})` }} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  loading: isLoadingAuthentication(state),
  badCredentials: isUserProvidingBadCredentials(state),
  authenticated: isUserAuthenticated(state),
  selectableStaffs: getSelectableStaffs(state),
  loginFailure: isFailureLoggingIn(state),
  expiredSession: hasExpiredSession(state),
  lockedAccount: hasLockedAccount(state)
})

const mapDispatchToProps = dispatch => ({
  login: (creds) => {
    dispatch(login(creds))
  },
  cancelStaffSelect: () => {
    dispatch(cancelStaffSelection())
  },
  badCredentialsResolved: () => {
    dispatch(badCredentialsResolved())
  }
})

LoginContainer.propTypes = {
  badCredentialsResolved: PropTypes.func.isRequired,
  badCredentials: PropTypes.bool.isRequired,
  loginFailure: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
  cancelStaffSelect: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  selectableStaffs: PropTypes.arrayOf(PropTypes.shape({})),
  runsInModal: PropTypes.bool,
  expiredSession: PropTypes.bool.isRequired,
  lockedAccount: PropTypes.bool.isRequired,
  ReactGA: PropTypes.shape().isRequired
}

LoginContainer.defaultProps = {
  selectableStaffs: [],
  runsInModal: false
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer)
