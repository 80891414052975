import authFetch from './authFetch'
import { checkStatus, parseJSON } from '../util/apiUtil'

const PROFILE_KEY = 'STAFF_PROFILE'

const loadFromCache = () => {
  try {
    return JSON.parse(localStorage.getItem(PROFILE_KEY))
  } catch (e) {
    return null
  }
}

const setInCache = profile => localStorage.setItem(PROFILE_KEY, JSON.stringify(profile || {}))

const mergeWithCachedProfile = (fromBackend) => {
  const cached = loadFromCache()
  if (cached !== null) {
    return { ...fromBackend, ...cached }
  }
  return fromBackend
}

const resetCache = (profile) => {
  localStorage.removeItem(PROFILE_KEY)
  return profile
}

const userByEmail = accessToken => authFetch(`${ENV_VAR_STAFF_HOST}/v3/staff`, { accessToken })
  .then(checkStatus)
  .then(parseJSON)

const getStaffInfo = () => authFetch(`${ENV_VAR_BFF_HOST}/api/v1/staff`)
  .then(checkStatus)
  .then(parseJSON)
  .then(mergeWithCachedProfile)

const updateLoggedInUserProfile = profile => authFetch(`${ENV_VAR_BFF_HOST}/api/v1/staff`, {
  body: JSON.stringify(profile),
  method: 'PATCH',
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
})
  .then(checkStatus)
  .then(parseJSON)
  .then(resetCache)
  .catch(() => {
    setInCache(profile)
    return profile
  })

export default {
  getUserByEmail: accessToken => userByEmail(accessToken),
  get: () => getStaffInfo(),
  updateLoggedInUserProfile: profile =>
    updateLoggedInUserProfile({
      propulsionConsumptionUnit: profile.propulsionConsumptionUnit,
      gasConsumptionUnit: profile.gasConsumptionUnit,
      language: profile.language,
      vehicleIdentifier: profile.vehicleIdentifier
    })
}
