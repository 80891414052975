import PropTypes from 'prop-types'
import React from 'react'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import GAEvent from '../GAEvent'
import Style from './VehicleNameColumn.scss'
import ServicePlanningTable from './ServicePlanningTable'
import { ServicePlaningWrenchIcon } from './ServicePlanningIcon'
import { Provider as ReduxProvider } from 'react-redux'
import { defaultStore } from '../../state/storeFactory'

const popover = (vehicleName, servicePlans,
  externalEquipmentReference, ReactGA) => (
    <Popover id="delta-popover" className="scania-popover" style={{ maxWidth: 'fit-content', display: 'table' }}>
      <ReduxProvider store={defaultStore}>
        <GAEvent category="click" action="user clicked table value with popover" ReactGA={ReactGA} />
        <ServicePlanningTable
          vehicleName={vehicleName}
          servicePlans={servicePlans}
          externalEquipmentReference={externalEquipmentReference}
          ReactGA={ReactGA}
          />
      </ReduxProvider>
    </Popover>
)

const VehicleNameColumn = ({
  vehicleName, servicePlans,
  externalEquipmentReference,
  ReactGA
}) => (
    <OverlayTrigger trigger="click" placement="right" overlay={popover(vehicleName, servicePlans, externalEquipmentReference, ReactGA)}>
      <div className={`secret_text ${Style.column}`}>
        {vehicleName}
        <ServicePlaningWrenchIcon status={servicePlans.status} />
      </div>
    </OverlayTrigger>
)

VehicleNameColumn.propTypes = {
  externalEquipmentReference: PropTypes.string.isRequired,
  vehicleName: PropTypes.string,
  servicePlans: PropTypes.shape({
    status: PropTypes.any
  }).isRequired,
  ReactGA: PropTypes.shape().isRequired
}

VehicleNameColumn.defaultProps = {
  vehicleName: '-'
}

export default VehicleNameColumn
