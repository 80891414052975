import servicePlaningApi from '../../api/servicePlaning'
import * as types from '../actionTypes'

function fetchServicePlaningRequest (externalCustomerReference) {
  return {
    type: types.FETCH_SERVICE_PLANING_REQUEST,
    externalCustomerReference
  }
}

function fetchServicePlaningSuccess (dataResult) {
  return {
    type: types.FETCH_SERVICE_PLANING_SUCCESS,
    result: dataResult
  }
}

function fetchServicePlaningFailure (error) {
  return {
    type: types.FETCH_SERVICE_PLANING_FAILURE,
    error: error.message
  }
}

const getPlans = externalCustomerReference => (dispatch) => {
  dispatch(fetchServicePlaningRequest(externalCustomerReference))

  return servicePlaningApi
    .get(externalCustomerReference)
    .then((dataResult) => {
      dispatch(fetchServicePlaningSuccess(dataResult))
    })
    .catch((error) => {
      dispatch(fetchServicePlaningFailure(error))
    })
}

export default getPlans
