// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Alert_alertBox-B0Tug{margin:0px}.Alert_alertBox-B0Tug.alert{margin-bottom:0}.Alert_alertBox-B0Tug.alert p{font-size:3.4rem}.Alert_alertBox-B0Tug.alert a{font-size:3.4rem}\n", "",{"version":3,"sources":["webpack://./src/components/Alert.scss"],"names":[],"mappings":"AAAA,sBACI,UAAW,CADf,4BAGQ,eAAgB,CAHxB,8BAMY,gBAAiB,CAN7B,8BAUY,gBAAiB","sourcesContent":[".alertBox {\n    margin: 0px;\n    &:global(.alert) {\n        margin-bottom: 0;\n\n        p {\n            font-size: 3.4rem;\n        }\n\n        a {\n            font-size: 3.4rem;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alertBox": "Alert_alertBox-B0Tug"
};
export default ___CSS_LOADER_EXPORT___;
