import * as types from '../actionTypes'

const initialState = {
  locale: undefined,
  loading: false
}

const language = (state = initialState, action) => {
  switch (action.type) {
    case types.LANGUAGE_LOADED:
      return Object.assign({}, state, {
        locale: action.language,
        loading: false
      })
    case types.LANGUAGE_LOADING:
      return Object.assign({}, state, {
        loading: true
      })
    default:
      return state
  }
}

export default language
