import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import MediaQuery from 'react-responsive'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock as lockIcon } from '@fortawesome/free-solid-svg-icons/faLock'

const renderPeriod = (type, time, narrow) => {
  switch (type) {
    case 'year':
      return moment(time).format('YYYY')
    case 'month':
      return narrow ? moment(time).format('MMM') : moment(time).format('MMMM')
    default:
      return moment(time).format('DD')
  }
}

const reportClick = (periodType, periodStart, ReactGA) => {
  ReactGA.event({
    category: 'click',
    action: 'user clicked period in report picker',
    label: `${periodType}-${periodStart}`
  })
}

const TimePeriodItem = ({
  active, periodType, periodStart, onClick, numberOfVehicles, access, ReactGA
}) => (
  <a
    href="/"
    className={`list-group-item ${active ? 'active' : ''}`}
    onClick={(e) => {
      e.preventDefault()
      e.stopPropagation()
      reportClick(periodType, periodStart, ReactGA)
      onClick(e)
    }}
  >

    <span className="badge">{numberOfVehicles}</span>
    <FontAwesomeIcon style={{ color: '#777' }} className={access ? 'hidden' : ''} icon={lockIcon} />
    <span style={{ marginLeft: '5px' }}>
      <MediaQuery query="(max-width: 767px)">
        {narrow => renderPeriod(periodType, periodStart, narrow)}
      </MediaQuery>
    </span>
  </a>
)

TimePeriodItem.propTypes = {
  active: PropTypes.bool.isRequired,
  access: PropTypes.bool,
  periodType: PropTypes.string.isRequired,
  periodStart: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  numberOfVehicles: PropTypes.number,
  ReactGA: PropTypes.shape().isRequired
}

TimePeriodItem.defaultProps = {
  numberOfVehicles: null,
  access: false
}

export default TimePeriodItem
