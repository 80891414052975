import * as types from '../actionTypes'

const initialState = {
  started: false,
  restarted: false,
  finished: false,
  loaded: false,
  needToUpdate: false
}

const tour = (state = initialState, action) => {
  switch (action.type) {
    case types.TOUR_STARTED: {
      return Object.assign({}, state, {
        started: true,
        finished: false
      })
    }
    case types.TOUR_FINISHED: {
      return Object.assign({}, state, {
        started: false,
        restarted: false,
        finished: true,
        loaded: true,
        needToUpdate: false
      })
    }
    case types.TOUR_LOADED: {
      return Object.assign({}, state, {
        loaded: true
      })
    }
    case types.TOUR_RESTARTED: {
      return Object.assign({}, state, {
        started: false,
        restarted: true,
        finished: false,
        loaded: true
      })
    }
    case types.TOUR_NEEDS_TO_UPDATE: {
      return Object.assign({}, state, {
        started: false,
        restarted: false,
        finished: false,
        loaded: true,
        needToUpdate: true
      })
    }

    case types.LOGOUT_SUCCESS:
      return initialState
    case types.LOGIN_SUCCESS:
      return initialState
    default:
      return state
  }
}

export default tour
