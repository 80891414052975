import React from 'react'
import PropTypes from 'prop-types'

class GAEvent extends React.Component {
  componentDidMount () {
    if (this.props.ReactGA) {
      this.props.ReactGA.event({
        category: this.props.category,
        action: this.props.action,
        label: this.props.label
      })
    }
  }

  render () {
    return null
  }
}

GAEvent.propTypes = {
  category: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  ReactGA: PropTypes.shape().isRequired,
  label: PropTypes.string
}

GAEvent.defaultProps = {
  label: null
}

export default GAEvent
