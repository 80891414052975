import i18n from '../localization'
import * as unitTranslations from '../unitTranslationKeys'

// helper constants to indicate that a column should ...
const envRep = true //    * be present on the environmental report
const xsScreen = true //  * be visible on extra small screens
const showTrend = (ENV_VAR_APPLICATION !== 'ENVIRONMENTAL') // * show a trend arrow

const filterObject = (obj, predicate) =>
  Object.keys(obj)
    .filter(key => predicate(obj[key]))
    .reduce((res, key) => {
      res[key] = obj[key]
      return res
    }, {})

const template = ({ showUnstableHybridData = false, hideAdBlueData = false }) => {
  const template = {
    liquid: {
      pickerText: `${i18n.t('Diesel')}/${i18n.t('Etanol')}`,
      url: '{BFF_HOST}/api/v2/report/{equipmentType}/combustion-engine/{periodType}/{startTimeInURL}?fuelType=Diesel&fuelType=Ethanol&propulsionConsumptionUnit={propulsionConsumptionUnit}&gasConsumptionUnit={gasConsumptionUnit}&getYntOffers={yntOffersToggle}',
      urlEnvironmentalReport: '{BFF_HOST}/api/v2/emission/EnvironmentReport?timeStart={startTimeInURL}&timeStop={endTimeInURL}&consumptionUnit={consumptionUnit}&gasConsumptionUnit={gasConsumptionUnit}&units={systemOfMeasurement}&details=true',
      // @TODO how do we handle kpis in environmental report?
      kpi: [
        {
          header: i18n.t('SUMMARY_FuelTitle'),
          icon: 'fuelGauge',
          text: i18n.t('SUMMARY_TotalChange'),
          name: 'kpi.fuelConsumption.totalChange',
          valueFunc: 'valueToSignedString',
          sub: {
            text: i18n.t('SUMMARY_PercentChange'),
            name: 'kpi.fuelConsumption.percentageChange',
            valueFunc: 'valueToSignedString'
          }
        },
        {
          header: i18n.t('SUMMARY_EmissionTitle'),
          icon: 'babyPlant',
          text: i18n.t('SUMMARY_TotalChange'),
          name: 'kpi.cO2.totalChange',
          valueFunc: 'valueToSignedString',
          sub: {
            text: i18n.t('SUMMARY_AverageChange'),
            name: 'kpi.cO2.averageChange',
            valueFunc: 'valueToSignedString'
          }
        },
        {
          header: i18n.t('TotalKörsträcka'),
          icon: 'road',
          text: i18n.t(''),
          name: 'total.drivenDistance',
          valueFunc: 'toLocaleString'
        },
        {
          header: i18n.t('TotalaUtsläppCO2'),
          icon: 'bigPlant',
          text: i18n.t(''),
          name: 'total.cO2',
          valueFunc: 'toLocaleString'
        }
      ],
      columns: {
        odometer: { xsScreen, titleKey: 'TH_TotalOdometer', order: 3, headerUnitTranslator: 'getDistanceTranslationKey', showTrend },
        drivenDistance: { envRep, xsScreen, titleKey: 'TH_Distance', order: 4, headerUnitTranslator: 'getDistanceTranslationKey', showTrend },
        scaniaDriverSupportScore: { titleKey: 'TH_DSScore', order: 5, headerUnitTranslator: '', showTrend },
        coasting: { titleKey: 'TH_Coasting', order: 6, headerUnitTranslator: '', showTrend },
        runtimeIdle: { titleKey: 'TH_Idling', order: 7, headerUnitTranslator: '', showTrend },
        timeOverspeeding: { titleKey: 'TH_OverSpeeding', order: 8, headerUnitTranslator: '', showTrend },
        numberOfHarshBrakes: { titleKey: 'TH_HarshBraking', order: 9, headerUnitTranslator: 'getPerDistanceTranslationKey', showTrend },
        fuelConsumption: { envRep, xsScreen, titleKey: 'TH_FuelConsumption', order: 10, headerUnitTranslator: 'getFuelConsumptionTranslationKey', showTrend },
        cO2: { envRep, titleKey: 'TH_CO2Total', order: 12, headerUnitTranslator: 'getWeightTranslationKey', showTrend }
      }
    },
    gas: {
      pickerText: i18n.t('Gas'),
      url: '{BFF_HOST}/api/v2/report/{equipmentType}/combustion-engine/{periodType}/{startTimeInURL}?fuelType=Gas&propulsionConsumptionUnit={propulsionConsumptionUnit}&gasConsumptionUnit={gasConsumptionUnit}&getYntOffers={yntOffersToggle}',
      urlEnvironmentalReport: '{BFF_HOST}/api/v2/emission/EnvironmentReport?timeStart={startTimeInURL}&timeStop={endTimeInURL}&consumptionUnit={consumptionUnit}&gasConsumptionUnit={gasConsumptionUnit}&units={systemOfMeasurement}&details=true',
      kpi: [
        {
          header: i18n.t('SUMMARY_FuelTitle'),
          icon: 'fuelGauge',
          text: i18n.t('SUMMARY_TotalChange'),
          name: 'kpi.fuelConsumption.totalChange',
          valueFunc: 'valueToSignedString',
          sub: {
            text: i18n.t('SUMMARY_PercentChange'),
            name: 'kpi.fuelConsumption.percentageChange',
            valueFunc: 'valueToSignedString'
          }
        },
        {
          header: i18n.t('SUMMARY_EmissionTitle'),
          icon: 'babyPlant',
          text: i18n.t('SUMMARY_TotalChange'),
          name: 'kpi.cO2.totalChange',
          valueFunc: 'valueToSignedString',
          sub: {
            text: i18n.t('SUMMARY_AverageChange'),
            name: 'kpi.cO2.averageChange',
            valueFunc: 'valueToSignedString'
          }
        },
        {
          header: i18n.t('TotalKörsträcka'),
          icon: 'road',
          text: i18n.t(''),
          name: 'total.drivenDistance',
          valueFunc: 'toLocaleString'
        },
        {
          header: i18n.t('TotalaUtsläppCO2'),
          icon: 'bigPlant',
          text: i18n.t(''),
          name: 'total.cO2',
          valueFunc: 'toLocaleString'
        }
      ],
      columns: {
        odometer: { xsScreen, titleKey: 'TH_TotalOdometer', order: 3, headerUnitTranslator: 'getDistanceTranslationKey', showTrend },
        drivenDistance: { envRep, xsScreen, titleKey: 'TH_Distance', order: 4, headerUnitTranslator: 'getDistanceTranslationKey', showTrend },
        scaniaDriverSupportScore: { titleKey: 'TH_DSScore', order: 5, headerUnitTranslator: '', showTrend },
        coasting: { titleKey: 'TH_Coasting', order: 6, headerUnitTranslator: '', showTrend },
        runtimeIdle: { titleKey: 'TH_Idling', order: 7, headerUnitTranslator: '', showTrend },
        timeOverspeeding: { titleKey: 'TH_OverSpeeding', order: 8, headerUnitTranslator: '', showTrend },
        numberOfHarshBrakes: { titleKey: 'TH_HarshBraking', order: 9, headerUnitTranslator: 'getPerDistanceTranslationKey', showTrend },
        fuelConsumption: { envRep, xsScreen, titleKey: 'TH_FuelConsumption', order: 10, headerUnitTranslator: 'getFuelConsumptionTranslationKey', showTrend },
        cO2: { envRep, titleKey: 'TH_CO2Total', order: 11, headerUnitTranslator: 'getWeightTranslationKey', showTrend }
      }
    },
    engines: {
      pickerText: i18n.t('Motor'),
      modelKeyName: 'engines',
      url: '{BFF_HOST}/api/v2/report/{equipmentType}/combustion-engine/{periodType}/{startTimeInURL}?fuelType=Diesel&fuelType=Ethanol&propulsionConsumptionUnit={propulsionConsumptionUnit}&gasConsumptionUnit={gasConsumptionUnit}&getYntOffers={yntOffersToggle}',
      urlEnvironmentalReport: '{BFF_HOST}/api/v2/emission/EnvironmentReport?timeStart={startTimeInURL}&timeStop={endTimeInURL}&consumptionUnit={consumptionUnit}&gasConsumptionUnit={gasConsumptionUnit}&units={systemOfMeasurement}&details=true',
      kpi: [
        {
          header: i18n.t('SUMMARY_FuelTitle'),
          icon: 'fuelGauge',
          text: i18n.t('SUMMARY_TotalChange'),
          name: 'kpi.fuelConsumption.totalChange',
          valueFunc: 'valueToSignedString',
          sub: {
            text: i18n.t('SUMMARY_PercentChange'),
            name: 'kpi.fuelConsumption.percentageChange',
            valueFunc: 'valueToSignedString'
          }
        },
        {
          header: i18n.t('SUMMARY_EmissionTitle'),
          icon: 'babyPlant',
          text: i18n.t('SUMMARY_TotalChange'),
          name: 'kpi.cO2.totalChange',
          valueFunc: 'valueToSignedString',
          sub: {
            text: i18n.t('SUMMARY_AverageChange'),
            name: 'kpi.cO2.averageChange',
            valueFunc: 'valueToSignedString'
          }
        },
        {
          header: i18n.t('SammanlagdMotordrifttid'),
          icon: 'time',
          text: i18n.t(''),
          name: 'total.runningTime',
          valueFunc: 'converttoTime'
        },
        {
          header: i18n.t('TotalaUtsläppCO2'),
          icon: 'bigPlant',
          text: i18n.t(''),
          name: 'total.cO2',
          valueFunc: 'toLocaleString'
        }
      ],
      columns: {
        globalRuntime: { titleKey: 'SammanlagdMotortid', order: 3, showAsTime: true, headerUnitTranslator: 'getRuntimeTranslationKey' },
        totalRuntime: { envRep, xsScreen, titleKey: 'Motordrifttid', order: 4, showAsTime: true, headerUnitTranslator: 'getRuntimeTranslationKey' },
        totalFuel: { envRep, xsScreen, titleKey: 'Bränsle', order: 5, headerUnitTranslator: 'getFuelConsumptionTranslationKey' },
        fuelConsumptionDriveIdlePTOPerTimeUnit: { titleKey: 'Bränsle', order: 6, headerUnitTranslator: 'getFuelConsumptionTranslationKey' }
      }
    },
    electric: {
      pickerText: i18n.t('BEV'),
      url: '{BFF_HOST}/api/v2/report/{equipmentType}/{propulsionSystem}/{periodType}/{startTimeInURL}?propulsionConsumptionUnit={propulsionConsumptionUnit}&gasConsumptionUnit={gasConsumptionUnit}&getYntOffers={yntOffersToggle}',
      urlEnvironmentalReport: '{BFF_HOST}/api/v2/emission/EnvironmentReport?timeStart={startTimeInURL}&timeStop={endTimeInURL}&consumptionUnit={consumptionUnit}&gasConsumptionUnit={gasConsumptionUnit}&units={systemOfMeasurement}&details=true',
      kpi: [
        {
          header: i18n.t('FörändradEnergiförbrukning'),
          icon: 'battery',
          text: i18n.t('SUMMARY_TotalChange'),
          name: 'kpi.energyConsumption.totalChange',
          valueFunc: 'valueToSignedString',
          sub: {
            text: i18n.t('SUMMARY_PercentChange'),
            name: 'kpi.energyConsumption.percentageChange',
            valueFunc: 'valueToSignedString'
          }
        },
        {
          header: i18n.t('TotalKörsträcka'),
          icon: 'road',
          text: i18n.t('SUMMRY_TotalChange'),
          name: 'total.drivenDistance',
          valueFunc: 'valueToSignedString'
        },
        {
          header: i18n.t('SammanlagdEnergiförbrukning'),
          icon: 'battery',
          text: i18n.t(''),
          name: 'total.energyConsumption',
          valueFunc: 'toLocaleString'
        }
      ],
      columns: {
        odometer: { xsScreen, titleKey: 'TH_TotalOdometer', order: 3, headerUnitTranslator: 'getDistanceTranslationKey', showTrend },
        drivenDistance: { envRep, xsScreen, titleKey: 'TH_Distance', order: 4, headerUnitTranslator: 'getDistanceTranslationKey', showTrend },
        scaniaDriverSupportScore: { titleKey: 'TH_DSScore', order: 6, headerUnitTranslator: '', showTrend },
        // coasting: { titleKey: 'TH_Coasting', order: 7, headerUnitTranslator: '', showTrend },
        runtimeIdle: { titleKey: 'TH_Idling', order: 8, headerUnitTranslator: '', showTrend },
        // timeOverspeeding: { titleKey: 'TH_OverSpeeding', order: 9, headerUnitTranslator: '', showTrend },
        numberOfHarshBrakes: { titleKey: 'TH_HarshBraking', order: 10, headerUnitTranslator: 'getPerDistanceTranslationKey', showTrend },
        energyConsumption: { envRep, xsScreen, titleKey: 'Energiförbrukning', order: 11, showTrend, headerUnitTranslator: 'getEnergyTranslationKey' }
      }
    },
    hybrid: {
      pickerText: i18n.t('HEV_PHEV'),
      url: '{BFF_HOST}/api/v2/report/{equipmentType}/hybrid/{periodType}/{startTimeInURL}?fuelType=Diesel&propulsionConsumptionUnit={propulsionConsumptionUnit}&gasConsumptionUnit={gasConsumptionUnit}&getYntOffers={yntOffersToggle}',
      urlEnvironmentalReport: '{BFF_HOST}/api/v2/emission/EnvironmentReport?timeStart={startTimeInURL}&timeStop={endTimeInURL}&consumptionUnit={consumptionUnit}&gasConsumptionUnit={gasConsumptionUnit}&units={systemOfMeasurement}&details=true',
      kpi: [
        {
          header: i18n.t('SUMMARY_FuelTitle'),
          icon: 'fuelGauge',
          text: i18n.t('SUMMARY_TotalChange'),
          name: 'kpi.fuelConsumption.totalChange',
          valueFunc: 'valueToSignedString',
          sub: {
            text: i18n.t('SUMMARY_PercentChange'),
            name: 'kpi.fuelConsumption.percentageChange',
            valueFunc: 'valueToSignedString'
          }
        },
        {
          header: i18n.t('SammanlagdKörsträckaEldrift'),
          icon: 'roadbattery',
          text: i18n.t('SUMMARY_TotalChange'),
          name: 'total.drivenDistanceOnBattery',
          valueFunc: 'valueToSignedString'
        },
        {
          header: i18n.t('SammanlagdKörsträckaMedNoll_'),
          icon: 'leaf',
          text: i18n.t('SUMMARY_PercentChange'),
          name: 'kpi.zeroEmissionPercentage.percentageChange',
          valueFunc: 'toLocaleString',
          sub: {
            text: i18n.t('SUMMARY_TotalChange'),
            name: 'kpi.zeroEmissionPercentage.percentagePeriod',
            valueFunc: 'valueToSignedString'
          }
        }
      ],
      columns: {
        odometer: { xsScreen, titleKey: 'TH_TotalOdometer', order: 3, headerUnitTranslator: 'getDistanceTranslationKey', showTrend },
        drivenDistance: { envRep, xsScreen, titleKey: 'TH_Distance', order: 4, headerUnitTranslator: 'getDistanceTranslationKey', showTrend },
        drivenDistanceOnBattery: { envRep, xsScreen, titleKey: 'KörsträckaMedEldrift', order: 5, headerUnitTranslator: 'getDistanceTranslationKey', showTrend },
        scaniaDriverSupportScore: { titleKey: 'TH_DSScore', order: 6, headerUnitTranslator: '', showTrend },
        coasting: { titleKey: 'TH_Coasting', order: 7, headerUnitTranslator: '', showTrend },
        runtimeIdle: { titleKey: 'TH_Idling', order: 8, headerUnitTranslator: '', showTrend },
        timeOverspeeding: { titleKey: 'TH_OverSpeeding', order: 9, headerUnitTranslator: '', showTrend },
        numberOfHarshBrakes: { titleKey: 'TH_HarshBraking', order: 10, headerUnitTranslator: 'getPerDistanceTranslationKey', showTrend },
        fuelConsumption: { envRep, titleKey: 'TH_FuelConsumption', order: 11, headerUnitTranslator: 'getFuelConsumptionTranslationKey', showTrend },
        cO2: { envRep, titleKey: 'TH_CO2Total', order: 13, headerUnitTranslator: 'getWeightTranslationKey', showTrend }
      }
    }
  }

  if (showUnstableHybridData) {
    template.hybrid.columns.energyConsumption = { xsScreen, envRep, titleKey: 'Energiförbrukning', order: 12, headerUnitTranslator: 'getEnergyTranslationKey', showTrend }
    template.hybrid.kpi.unshift(
      {
        header: i18n.t('FörändradEnergiförbrukning'),
        icon: 'battery',
        text: i18n.t('SUMMARY_TotalChange'),
        name: 'kpi.energyConsumption.totalChange',
        valueFunc: 'valueToSignedString',
        sub: {
          text: i18n.t('SUMMARY_PercentChange'),
          name: 'kpi.energyConsumption.percentageChange',
          valueFunc: 'valueToSignedString'
        }
      }
    )
  }

  if (!hideAdBlueData) {
    template.liquid.columns.totalReductantUsed = { xsScreen, envRep, titleKey: 'AdBlue', order: 11, headerUnitTranslator: 'getFuelConsumptionTranslationKey', showTrend }
  }

  if (ENV_VAR_APPLICATION === 'ENVIRONMENTAL') {
    template.engines.columns.cO2 = { envRep, titleKey: 'TH_CO2Total', order: 7, headerUnitTranslator: 'getWeightTranslationKey', showTrend }
    const extraColumnsForNonBevs = {
      co: { envRep, titleKey: 'CO', order: 13, headerUnitTranslator: 'getWeightTranslationKey', showTrend },
      nox: { envRep, titleKey: 'NOx', order: 14, headerUnitTranslator: 'getWeightTranslationKey', showTrend },
      pm: { envRep, titleKey: 'PM', order: 15, headerUnitTranslator: 'getWeightTranslationKey', showTrend },
      hc: { envRep, titleKey: 'HC', order: 16, headerUnitTranslator: 'getWeightTranslationKey', showTrend },
      specification: { envRep, titleKey: 'Utsläppsspecifikation', order: 17, headerUnitTranslator: '', specCol: true }
    }

    ;['liquid', 'gas', 'engines', 'electric', 'hybrid'].forEach(reportType => {
      template[reportType].columns = { ...filterObject(template[reportType].columns, col => col.envRep) }
    })
    ;['liquid', 'gas', 'engines', 'hybrid'].forEach(reportType => {
      template[reportType].columns = { ...template[reportType].columns, ...extraColumnsForNonBevs }
    })
  }

  return template
}

const get = (key, options = {}) => template(options)[key]

const list = (options = {}) => Object.keys(template(options))

const columnsOf = (key, options = {}) => {
  const { columns } = template(options)[key]
  const result = []
  Object.keys(columns).forEach((el) => {
    result.push({ name: el, ...columns[el] })
  })
  result.sort((a, b) => a.order - b.order)
  return result
}

const generateUnits = (units, column) => {
  let result = ''
  switch (column.headerUnitTranslator) {
    case 'getPerDistanceTranslationKey': result = unitTranslations.getPerDistanceTranslationKey(units[column.name]); break
    case 'getFuelConsumptionTranslationKey': result = unitTranslations.getFuelConsumptionTranslationKey(units[column.name]); break
    case 'getWeightTranslationKey': result = unitTranslations.getWeightTranslationKey(units[column.name]); break
    case 'getDistanceTranslationKey': result = unitTranslations.getDistanceTranslationKey(units[column.name]); break
    case 'unitToTranslationKey': result = unitTranslations.unitToTranslationKey(units[column.name]); break
    case 'getEnergyTranslationKey': result = unitTranslations.getEnergyTranslationKey(units[column.name]); break
    case 'getRuntimeTranslationKey': result = unitTranslations.getRuntimeTranslationKey(units[column.name]); break
    default: { result = units[column.name] }
  }
  return result
}

export default { get, list, columnsOf, generateUnits }
