import React from 'react'
import PropTypes from 'prop-types'
import Alert from '../components/Alert'
import LocalizationToggle from '../components/LocalizationToggle'

class AlertContainer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      alerts: this.props.alerts
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.alerts) {
      this.setState({ alerts: nextProps.alerts })
    }
  }

  createAlerts () {
    return this.state.alerts.map(item =>
      (
        <LocalizationToggle key={item.id} localeKey={item.text || item.title}>
          <Alert
            type={item.type}
            title={item.title}
            text={item.text}
            id={item.id}
            onClick={item.onClick}
            remove={() => this.props.onRemove(item.id)}
          />
        </LocalizationToggle>
      ))
  }

  render () {
    return (
      <React.Fragment>
        {this.createAlerts()}
      </React.Fragment>
    )
  }
}

AlertContainer.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.any),
  onRemove: PropTypes.func.isRequired
}

AlertContainer.defaultProps = {
  alerts: []
}

export default AlertContainer
