const isLoadingSubscription = state => state.subscription.loading
const hasFailedLoadingSubscription = state => state.subscription.failedLoading
const hasFailedSavingSubscription = state => state.subscription.failedSaving
const isSavingSubscription = state => state.subscription.saving
const isMonitoringReportRecipient = state => state.subscription.isMonitoringReportRecipient
const isServicePlanningReportRecipient = state =>
  state.subscription.isServicePlanningReportRecipient

export default {
  isLoadingSubscription,
  hasFailedLoadingSubscription,
  hasFailedSavingSubscription,
  isSavingSubscription,
  isMonitoringReportRecipient,
  isServicePlanningReportRecipient
}
