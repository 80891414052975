import authFetch from './authFetch'
import { checkStatus, parseJSON } from '../util/apiUtil'

const fetchPlans = externalCustomerReference =>
  authFetch(`${ENV_VAR_BFF_HOST}/api/v1/serviceplanning/${externalCustomerReference}?DueStatus=6&Status=6&bookingStatus=3`)
    .then(checkStatus)
    .then(parseJSON)

export default {
  get: externalCustomerReference => fetchPlans(externalCustomerReference)

}
