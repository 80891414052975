import { useSelector } from 'react-redux'
import { useGetEnabledFeaturesForReferencesQuery } from '../../api/featureToggle'
import { getReferenceForCountry } from '../../util/countryMapUtils'

const OTHER_LANGUAGE = 'other'

const useIsFeatureEnabled = (feature) => {
  const staff = useSelector(state => state.user?.externalStaffReference)
  const customer = useSelector(state => state.user?.externalCustomerReference)
  const countryCode = useSelector(state => state.user?.profile?.customerCountry)

  const countryRef = getReferenceForCountry(countryCode)
  const skip = !countryRef || !customer || !staff
  const refs = {
    staffRef: staff,
    customerRef: customer
  }
  if (countryRef && (countryRef !== OTHER_LANGUAGE)) {
    refs.countryRef = countryRef
  }
  const { data: enabledFeatures = [] } = useGetEnabledFeaturesForReferencesQuery(refs, { skip })

  return enabledFeatures.includes(feature)
}

export { useIsFeatureEnabled }
