const isTourStarted = state => state.tour.started
const isTourRestarted = state => state.tour.restarted
const isTourFinished = state => state.tour.finished
const isTourLoaded = state => state.tour.loaded
const isTourNeedToUpdate = state => state.tour.needToUpdate

export default {
  isTourStarted,
  isTourRestarted,
  isTourFinished,
  isTourLoaded,
  isTourNeedToUpdate
}
