import React from 'react'
import PropTypes from 'prop-types'
import VehicleDataTableHeaderColumnValue from './VehicleDataTableHeaderColumnValue'

const reportEvent = (ReactGA) => {
  ReactGA.event({
    category: 'click',
    action: 'column sorted'
  })
}

const VehicleDataTableHeaderColumn = ({
  name, unit, collapsable, order, index, onHeaderClick, ReactGA
}) => (
  <th className={collapsable} onClick={() => { onHeaderClick(index); reportEvent(ReactGA) }} style={{ cursor: 'pointer' }}>
    <VehicleDataTableHeaderColumnValue name={name} unit={unit} order={order} />
  </th>
)

VehicleDataTableHeaderColumn.propTypes = {
  name: PropTypes.string.isRequired,
  unit: PropTypes.string,
  collapsable: PropTypes.string,
  order: PropTypes.string,
  index: PropTypes.number,
  onHeaderClick: PropTypes.func,
  ReactGA: PropTypes.shape().isRequired
}

VehicleDataTableHeaderColumn.defaultProps = {
  unit: null,
  collapsable: '',
  order: undefined,
  index: undefined,
  onHeaderClick: () => {}
}

export default VehicleDataTableHeaderColumn
