import React from 'react'
import PropTypes from 'prop-types'
import style from './ResponsiveDropdownMenu.scss'

const ResponsiveDropdownMenu = ({ children, onSubmit }) => (
  <ul className={`dropdown-menu ${style.menu}`}>
    <form onSubmit={onSubmit}>
      <li>
        <div className={`container ${style.container}`}>
          {children}
        </div>
      </li>
    </form>
  </ul>
)

ResponsiveDropdownMenu.propTypes = {
  children: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func
}

ResponsiveDropdownMenu.defaultProps = {
  onSubmit: (e) => { e.preventDefault() }
}

export default ResponsiveDropdownMenu
