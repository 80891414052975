import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { listReports } from '../../state/report/actions'
import { hasLoadingReportsFailure, isLoadingReports, hasLoadedReports } from '../../state/report/selectors'

export class ListReportsComp extends React.Component {
  constructor (props) {
    super(props)
    this.request = this.request.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.request(this.props)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.request(nextProps)
  }

  request (props) {
    if (!props.loading && !props.failure && !props.alreadyLoaded && props.startOfWeek) {
      this.props.listReports(props.startOfWeek)
    }
  }

  render () {
    return null
  }
}

/*eslint-disable */
ListReportsComp.propTypes = {
  listReports: PropTypes.func.isRequired,
  startOfWeek: PropTypes.string,
};

/* eslint-enable */

ListReportsComp.defaultProps = {
  startOfWeek: undefined
}

const mapStateToProps = state => ({
  loading: isLoadingReports(state),
  failure: hasLoadingReportsFailure(state),
  alreadyLoaded: hasLoadedReports(state)
})

const mapDispatchToProps = dispatch => ({
  listReports (startOfWeek) {
    dispatch(listReports(startOfWeek))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ListReportsComp)
