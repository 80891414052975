import React from 'react'
import PropTypes from 'prop-types'
import { useIsFeatureEnabled } from '../FeatureToggle/hooks'

/**
 * This component can be used as a wrapper around class components,
 * where the preferred alternative, to use the useIsFeatureEnabled hook, is not possible.
 * @param {string} feature
 * @returns null
 */
const FeatureToggleProvider = ({ features = {}, feature, children, ...props }) => {
  const isFeatureEnabled = useIsFeatureEnabled(feature)
  const newFeatures = { ...features, [feature]: isFeatureEnabled }

  return React.Children.map(children, child => React.cloneElement(child, { features: newFeatures, ...props }))
}

FeatureToggleProvider.propTypes = {
  feature: PropTypes.string.isRequired
}

export default FeatureToggleProvider
