import React from 'react'
import PropTypes from 'prop-types'
import { getSpecificCookie } from '../util/gaUtil'

const CUSTOMER_DIMENSION = 'dimension1'
const RESTRICTED_DIMENSION = 'dimension2'

const trackPage = (page, props) => {
  props.ReactGA.set({ page })
  props.ReactGA.pageview(page)
}

const trackUser = (user, props) => {
  props.ReactGA.set({
    userId: user.extStaffRef,
    [CUSTOMER_DIMENSION]: user.extCustRef,
    [RESTRICTED_DIMENSION]: user.permissions ? user.permissions.restricted : false
  })
}

class Analytics extends React.Component {
  constructor (props) {
    super(props)
    const cookiesConsentObj = JSON.parse(getSpecificCookie('MyScania.CookieConsent'))
    if (cookiesConsentObj?.performance || !ENV_VAR_MY_SCANIA) {
      this.props.ReactGA.initialize(ENV_VAR_TRACKING_ID, {
        gaOptions: {
          siteSpeedSampleRate: 100
        },
        standardImplementation: true
      })
    }
  }

  componentDidMount () {
    const page = this.props.location.pathname
    const { user } = this.props
    trackUser(user, this.props)
    trackPage(page, this.props)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const currentPage = this.props.location.pathname
    const nextPage = nextProps.location.pathname
    const currentStaff = this.props.user.extStaffRef
    const nextStaff = nextProps.user.extStaffRef
    const currentCustomer = this.props.user.extCustRef
    const nextCustomer = nextProps.user.extCustRef

    const { currentRestricted } = this.props.user.permissions
    const { nextRestricted } = this.props.user.permissions

    if (currentStaff !== nextStaff ||
      currentCustomer !== nextCustomer ||
      currentRestricted !== nextRestricted) {
      trackUser(nextProps.user, this.props)
    }

    if (currentPage !== nextPage) {
      trackPage(nextPage, this.props)
    }
  }

  render () {
    return null
  }
}

Analytics.propTypes = {
  ReactGA: PropTypes.shape().isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
  user: PropTypes.shape()
}

Analytics.defaultProps = {
  user: {
    extCustRef: null,
    extStaffRef: null,
    permissions: {}
  }
}

export default Analytics
