/* eslint-disable no-mixed-operators */
/* eslint-disable no-bitwise */
import authentication from './authentication'
import { uuidv4 } from '../util/apiUtil'

const unauthorizedError = () => new Error('Unauthorized')
const forbiddenError = () => new Error('Forbidden')

const requestWasUnauthorized = response => response.status === 401
const requestWasForbidden = response => response.status === 403

const checkIfUnauthorized = (response) => {
  if (requestWasUnauthorized(response)) {
    throw (unauthorizedError())
  }
  return response
}

const allowedUrls = [
  'https://test.scania.com/fake',
  `${ENV_VAR_BFF_HOST}/api/v1/export/monitoring/pdf`,
  `${ENV_VAR_BFF_HOST}/api/v2/report/`,
  `${ENV_VAR_BFF_HOST}/api/v2/emission/EnvironmentReport?timeStart=`,
  `${ENV_VAR_BFF_HOST}/api/v2/report/list?startOfWeek=`,
  `${ENV_VAR_BFF_HOST}/api/v1/serviceplanning/`,
  `${ENV_VAR_BFF_HOST}/api/v1/subscriber?active=true`,
  `${ENV_VAR_BFF_HOST}/api/v1/subscriber/`,
  `${ENV_VAR_STAFF_HOST}/v3/staff`,
  `${ENV_VAR_BFF_HOST}/api/v1/staff`
]

const isAllowedUrl = (url) => {
  return allowedUrls.some(allowedUrl => url.startsWith(allowedUrl))
}

const fetchWithToken = (url, options = {}, refresh = false) => {
  if (isAllowedUrl(url)) {
    const tokenPromise = options.accessToken ? Promise.resolve(options.accessToken) : authentication.getValidAccessToken(refresh)
    if (options.accessToken) {
      delete options.accessToken
    }

    return tokenPromise
      .then((accessToken) => {
        const headersWithAuthToken = {
          Authorization: `Bearer ${accessToken}`,
          'X-Correlation-ID': uuidv4(),
          ...options.headers,
          'X-Client': ENV_VAR_X_CLIENT
        }
        const optionsWithAuthToken = {
          ...options,
          headers: headersWithAuthToken
        }

        return fetch(url, optionsWithAuthToken)
      })
  } else {
    console.error(`Invalid URL: ${url}`)
    throw new Error(`Invalid URL: ${url}`)
  }
}

const fetchWithFreshToken = (url, options) => fetchWithToken(url, options, true)

export default (url, options) => fetchWithToken(url, options)
  .then((response) => {
    if (requestWasUnauthorized(response)) {
      return fetchWithFreshToken(url, options)
    }
    if (requestWasForbidden(response)) {
      throw (forbiddenError())
    }
    return response
  })
  .then(checkIfUnauthorized)
