import i18n from './localization'

export default (value, unit) => {
  let formatted = '-'
  if (value !== null) {
    if (value > 0.0) {
      formatted = `+${value.toLocaleString(i18n.language)} ${unit}`
    } else {
      formatted = `${value.toLocaleString(i18n.language)} ${unit}`
    }
  }
  return formatted
}
