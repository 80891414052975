import authFetch from './authFetch'
import { checkStatus, uuidv4 } from '../util/apiUtil'

const pdf = report =>
  authFetch(`${ENV_VAR_BFF_HOST}/api/v1/export/monitoring/pdf`, {
    headers: {
      Accept: 'application/pdf',
      'Content-Type': 'application/json',
      'X-Correlation-ID': uuidv4(),
      'X-Client': ENV_VAR_X_CLIENT
    },
    method: 'POST',
    body: JSON.stringify(report)
  })
    .then(checkStatus)
    .then(response => response.blob())

export default {
  get: report => pdf(report)
}
