import PropTypes from 'prop-types'
import React from 'react'
import { isMobile } from 'react-device-detect'
import Style from './VehicleNameColumn.scss'
import wrenchInfo from '../../assets/wrench-blue.svg'
import wrenchDanger from '../../assets/wrench-red.svg'
import wrenchSuccess from '../../assets/wrench-green.svg'
import i18n from '../../util/localization'

export const ServicePlaningWrenchIcon = ({ status }) => {
  if (!status) { return <span /> }
  let src = wrenchSuccess
  if (status === 'Late') { src = wrenchDanger }
  if (status === 'Due') { src = wrenchInfo }
  if (status === 'Booked') { src = wrenchSuccess }
  return (<img src={src} alt="" id="servicePlaningIcon" className={Style.wrench} />)
}
ServicePlaningWrenchIcon.propTypes = {
  status: PropTypes.string.isRequired
}

export const StatusLabel = ({ status }) => {
  const extraStyle = isMobile ? { float: 'right' } : {}
  let css = 'info'
  let label = ''
  if (status === 'Late') { css = 'danger'; label = 'Försenad' }
  if (status === 'Due') { css = 'info'; label = 'Aktuell' }
  if (status === 'Booked') { css = 'success'; label = 'Bokad' }
  if (status === 'Preliminary') { css = 'warning'; label = 'Preliminär' }
  return (<span style={{ width: 100, ...extraStyle, opacity: 1 }} className={`label label-${css} label-sm`}>{i18n.t(label)}</span>)
}
StatusLabel.propTypes = {
  status: PropTypes.string.isRequired
}
