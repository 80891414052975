import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import i18n from 'i18next'
import { connect } from 'react-redux'
import setAcceptForbidden from '../state/authorization/actions'

const UnauthorizedContainer = ({ acceptForbidden }) => {
  acceptForbidden()
  return (
    <div className="row">
      <div className="col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2">
        <div className="panel panel-warning">
          <div className="panel-body text-center">
            <p>{i18n.t('DuVerkarInteHaBehörighetFörAttVisaDenHärSidan_Kontakta')}</p>
            <Link to="/">
              <button type="button" className="btn btn-lg btn-info">
                {i18n.t('TillbakaTillStartsidan')}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>

  )
}

UnauthorizedContainer.propTypes = {
  acceptForbidden: PropTypes.func.isRequired
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  acceptForbidden () {
    dispatch(setAcceptForbidden())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(UnauthorizedContainer)
