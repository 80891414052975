/* eslint-disable react/jsx-filename-extension */
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { defineCustomElements, addTheme } from '@scania/components'
import { theme as scania } from '@scania/theme-light'
import { defineCustomElements as myScaniaChat } from '@myscania/myscania-chat-widget/loader'
import { applyPolyfills, defineCustomElements as scope } from '@myscania/scope/dist/loader'
import App from './components/App'
import Spinner from './components/Spinner'
import ReactGA from 'react-ga'
import { defaultStore } from './state/storeFactory'
import './util/localization'
import { getSpecificCookie, DUMMY_GA_PACKAGE } from './util/gaUtil'
const KeycloakProvider = React.lazy(() => import('./components/KeycloakProvider'))

applyPolyfills().then(() => {
  scope()
})
defineCustomElements()
addTheme(scania)
myScaniaChat()

const store = defaultStore

const cookiesConsentObj = JSON.parse(getSpecificCookie('MyScania.CookieConsent'))

if (ENV_VAR_MY_SCANIA) {
  ReactDOM.render(
    <Suspense fallback={<Spinner />}>
      <KeycloakProvider>
        <ReduxProvider store={store}>
          <App
            corporateUi={() => { }}
            reactGA={cookiesConsentObj?.performance ? ReactGA : DUMMY_GA_PACKAGE}
          />
        </ReduxProvider>
      </KeycloakProvider>
    </Suspense>,
    document.getElementById('root')
  )
} else {
  ReactDOM.render(
    <ReduxProvider store={store}>
      <App
        corporateUi={() => { }}
        reactGA={ReactGA}
      />
    </ReduxProvider>,
    document.getElementById('root')
  )
}

export default store
