export const setMopinionLanguage = (language: string): void => {
  // The following global variable can be used by Mopinion admins to limit the deployment of a form to certain languages:
  if (window) window.MOPINION_USER_LANGUAGE = language

  // srv is an object set by Mopinion.
  // https://mopinion.atlassian.net/wiki/spaces/KB/pages/665288753/How+do+I+programmatically+switch+to+another+translation
  if (language && (typeof srv !== 'undefined')) {
    const languageWithIntentionalExtraQuotes = `"${language}"`
    language = language === 'en-GB' ? 'default' : languageWithIntentionalExtraQuotes

    const formKeys = srv?.ALL_KEYS || []
    formKeys.forEach(formKey => {
      srv.setLanguage(formKey, language)
    })
  }
}
